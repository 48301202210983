import { useContext, useEffect, useState } from 'react';
import { IconWrapper } from '@flexo/general';
import { FooterProps } from '@flexo/molecules';
import { useLocation } from 'react-router-dom';
import styles from './footer.module.scss';
import { useTranslation } from 'react-i18next';
import {
  LoadingContext,
  ThemeContext,
  ModulesContext,
  MetaDataApiContext,
} from '@flexo/providers';
import BarLoader from 'react-spinners/BarLoader';
import { navigateToCommunity, navigateToSite } from '@flexo/atoms';

export function Footer({
  sections,
  showLoadingBar,
  shadow,
  roundCorners,
}: FooterProps) {
  const { Footer__Label, Footer__Label__Active } = styles;
  const location = useLocation();
  const { t } = useTranslation();
  const { loadingStore } = useContext(LoadingContext);
  const { ThemeStore } = useContext(ThemeContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);

  const { modulesStore } = useContext(ModulesContext);

  const activeModule = modulesStore.modules.find(
    (module) =>
      module.visible &&
      (module.name === 'household' || module.name === 'community')
  );
  const isActiveSection = activeModule ? activeModule.name : null;

  // Handle section change
  const handleSectionChange = (section: string) => {
    if (section === 'community') {
      navigateToCommunity(
        metaDataApiStore.selectedEntity?.communityId ||
          (metaDataApiStore.entities as any).communities[0].communityId,
        setMetaDataApiStore,
        setModulesStore
      );
    } else {
      navigateToSite(
        (metaDataApiStore.entities as any).sites[0].siteId,
        setMetaDataApiStore,
        setModulesStore
      );
    }
  };

  return (
    <div
      className={`${styles.Footer} sfPro ${shadow ? styles.shadow : ''} 
      ${roundCorners ? styles.roundCorners : ''}`}
    >
      {/* Display loading bar if necessary */}
      {loadingStore.loading.visible && showLoadingBar && (
        <BarLoader
          color={`${ThemeStore.colors.primary}80`}
          style={{ left: 0, width: '100%', position: 'absolute', top: 0 }}
        />
      )}

      {/* Map through sections and display icons */}
      {sections?.map((section) => (
        <div
          key={section}
          className={`${Footer__Label} ${
            isActiveSection === section ? Footer__Label__Active : ''
          }`}
          onClick={() => handleSectionChange(section)}
        >
          <IconWrapper
            iconName={`${section}${
              isActiveSection === section ? '_active' : ''
            }`}
          />
          <div
            className={isActiveSection === section ? 'color-primary-500' : ''}
          >
            {t(`widgets.footer.sections.${section}`)}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Footer;
