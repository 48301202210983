
export const widgetsData = {
  header: {
    view: 'site',
    sections: {
      ACCOUNT: {
        icon: 'cog_solid',
        label: 'pages.profile.account.title',
        key: 'ACCOUNT',
      },

      FEEDBACK: {
        icon: 'chat_solid',
        label: 'pages.profile.feedback.title',
        key: 'FEEDBACK',
      },
      ABOUT: {
        icon: 'question',
        label: 'pages.profile.about.title',
        key: 'ABOUT',
      },
    },
    ACCOUNT: {
      title: 'pages.profile.account.title',
      subtitle: 'pages.profile.account.subtitle',
      textfields: [
        {
          label: 'pages.profile.account.name',
          value: 'name',
          type: 'text',
          containedLabel: false,
          placeholder: 'pages.profile.account.name_placeholder',
        },

        {
          label: 'pages.profile.account.email',
          value: 'email',
          type: 'text',
          containedLabel: false,
          placeholder: 'pages.profile.account.email_placeholder',
        },
        {
          label: 'pages.profile.account.password',
          value: 'password',
          type: 'password',
          containedLabel: false,
          placeholder: 'pages.profile.account.password_placeholder',
        },
        {
          label: 'pages.profile.account.confirm_password',
          value: 'confirmPassword',
          type: 'password',
          containedLabel: false,
          placeholder: 'pages.profile.account.confirm_password_placeholder',
        },
      ],
      confirmButton: 'pages.profile.account.confirm',
      deleteButton: 'pages.profile.account.delete',
    },
    ABOUT: {
      title: 'pages.profile.about.title',
      subtitle: 'pages.profile.about.subtitle',
      dataProtection: 'pages.profile.about.data_protection',
      terms: 'pages.profile.about.terms',
    },
    FEEDBACK: {
      title: 'pages.profile.feedback.title',
      subtitle: 'pages.profile.feedback.subtitle',
      label_1: 'pages.profile.feedback.label_1',
      label_2: 'pages.profile.feedback.label_2',
      label_3: 'pages.profile.feedback.label_3',
      terms: 'pages.profile.feedback.terms',
      confirmButton: 'pages.profile.feedback.confirmButton',
      buttonDescription: 'pages.profile.feedback.button_description',
      confirmationTitle: 'pages.profile.feedback.confirmation_title',
      confirmationDescription: 'pages.profile.feedback.confirmationDescription',
      thankYou: 'pages.profile.feedback.thankYou',
      doneButton: 'pages.profile.feedback.doneButton',
    },
  },
  headerWeb: {
    members: {
      label: 'members',
      navigation: true,
      calendar: false,
      download: true,
      searchBar: true,
      profile: true
    }
  },
  communityCommunityDetailsParameters: {
    title: 'widgets.communityCommunityDetailsParameters.title',
    fields: {
      communityId: "widgets.communityCommunityDetailsParameters.communityId",
      externalId: "widgets.communityCommunityDetailsParameters.externalId",
      communityCode: "widgets.communityCommunityDetailsParameters.communityCode",
      communityName: "widgets.communityCommunityDetailsParameters.communityName",
      communityType: "widgets.communityCommunityDetailsParameters.communityType",
      startDate: "widgets.communityCommunityDetailsParameters.startDate",
    },
    shownFields: [
      'externalId',
      'communityName',
      'communityType',
      'startDate',
    ]
    
  },

  consumptionStats: {
    title: 'widgets.consumptionStats.title',
    type: 'consumption',
    selfConsumption: {
      label: 'widgets.consumptionStats.self_consumption',
      value: 100,
    },

    grid: {
      db_field: 'no_float_e_site_grid_import',
      label: 'widgets.consumptionStats.from_network',
      value: 100,
    },

    consumptionSiteGraphKeys: ['self_consumption_direct', 'grid_withdrawn'],

    consumptionSiteDataKeys: ['self_consumption_direct', 'grid_withdrawn'],
    consumptionValues: [
      {
        label: 'widgets.consumptionStats.self_consumption',
        totalPercentage: 'no_float_self_consumption_direct',
        progressValues: [
          {
            value: 'no_float_self_consumption_direct',
            colorShade: '',
            colorType: 'self-consumption',
          },
        ],
      },

      {
        label: 'widgets.consumptionStats.from_network',
        totalPercentage: 'no_float_grid_withdrawn',
        progressValues: [
          {
            value: 'no_float_self_consumption_direct',
            color: '#F0F0F0',
          },

          {
            value: 'grid_withdrawn',
            colorShade: '',
            colorType: 'consumption-grid',
          },
        ],
      },
    ],
    consumptionSiteData: [
      {
        key: 'widgets.consumptionStats.self_consumption',
        formattedValue: 'self_consumption_direct',
      },

      {
        key: 'widgets.consumptionStats.from_network',
        formattedValue: 'grid_withdrawn',
      },
    ],
    totalAmount: 'total_consumption', //?????
    colors: ['self-consumption', 'consumption-grid'],
  },
  productionStats: {
    title: 'widgets.productionStats.title',
    type: 'production',
    selfConsumption: {
      label: 'widgets.productionStats.self_consumption',
      value: 100,
    },

    grid: {
      label: 'widgets.productionStats.grid_injection',
      value: 100,
    },
    productionSiteDataKeys: ['grid_injection', 'self_consumption_direct'],
    productionSiteGraphKeys: ['grid_injection', 'self_consumption_direct'],
    productionValues: [
      {
        label: 'widgets.productionStats.self_consumption',
        totalPercentage: 'no_float_self_consumption_direct',
        progressValues: [
          {
            value: 'self_consumption_direct',
            colorShade: '',
            colorType: 'self-consumption',
          },
        ],
      },

      {
        label: 'widgets.productionStats.grid_injection',
        totalPercentage: 'no_float_grid_injection',
        progressValues: [
          {
            value: 'self_consumption_direct',
            color: '#F0F0F0',
          },

          {
            value: 'grid_injection',
            colorShade: '',
            colorType: 'production',
          },
        ],
      },
    ],
    productionSiteData: [
      {
        key: 'widgets.productionStats.self_consumption',
        formattedValue: 'self_consumption_direct',
      },

      {
        key: 'widgets.productionStats.grid_injection',
        formattedValue: 'grid_injection',
      },
    ],
    totalAmount: 'total_production',
    db_field: 'grid_injection',
    colors: ['self-consumption', 'production'],
    gradientBorder: false,
  },
  consumptionStackbar: {
    id: 'consumption',
    type: 'stackBar',
    title: 'widgets.consumptionStackbar.title',
    buttons: [
      'widgets.consumptionStackbar.from_community',
      'widgets.consumptionStackbar.from_network',
    ],

    gradientBorder: false,
    view: 'site',

    colors: [
      { color: 'self-consumption', colorShade: '' },

      { color: 'consumption-grid', colorShade: '' },
    ],

    graphData: [
      Math.floor(Math.random() * 100),
      Math.floor(Math.random() * 100),
    ],
    graphKeys: [
      {
        title: 'widgets.consumptionStackbar.from_community',
        color: 'consumption',
      },
      {
        title: 'widgets.consumptionStackbar.from_network',
        color: 'consumption',
      },
    ],
    db_fields: ['self_consumption_direct', 'grid_withdrawn'],
  },
  savingsStackbar: {
    id: 'savings',
    type: 'stackBar',
    title: 'widgets.savingsStackbar.title',
    gradientBorder: false,
    view: 'site',

    buttons: [
      'widgets.savingsStackbar.income',
      'widgets.savingsStackbar.savings',
    ],
    colors: [
      { color: 'earnings', colorShade: '' },
      { color: 'savings', colorShade: '' },
    ],
    graphData: [
      Math.floor(Math.random() * 100),
      Math.floor(Math.random() * 100),
    ],

    db_fields: ['revenues', 'savings'],
  },
  selfSufficiencyCard: {
    id: 'selfSufficiency',
    title: 'widgets.selfSufficiency.title',
    value: '-.- % ',
    icon: 'self_sufficiency_solid',
    view: 'site',
    db_field_value: 'no_float_self_sufficiency_rate',
    db_field: 'self_consumption_direct',
    details: {
      title: 'widgets.selfSufficiency.details.title',
      db_field_1: 'self_consumption_direct',
      db_field_value: 'no_float_self_sufficiency_rate',
      label_line_1: 'widgets.selfSufficiency.details.label_line_1',
    },
    gradientBorder: false,
  },
  pollutionCard: {
    id: 'pollution',
    title: 'CO2',
    value: 'widgets.pollution',
    icon: 'power_station_solid',
    view: 'site',
    db_field: 'co2_offset_kgs',
    label: 'widgets.pollution.label',
    label_line_2: 'widgets.pollution.label_line_2',
    trees: 'widgets.pollution.trees',
    details: {
      title: 'widgets.pollution.details.title',
      db_field_value: 'co2_offset_kgs',
      label_line_1: {
        label_1: 'widgets.pollution.details.label_line_1.label_1',
        label_2: 'widgets.pollution.details.label_line_1.label_2',
      },
      label_line_2: {
        label_1: 'widgets.pollution.details.label_line_2.label_1',
        label_2: 'widgets.pollution.details.label_line_2.label_2',
      },
    },
    gradientBorder: false,
  },
  productionStackbar: {
    id: 'production',
    title: 'widgets.productionStackbar.title',
    type: 'stackBar',
    gradientBorder: false,
    view: 'site',

    buttons: [
      'widgets.productionStackbar.self_consumption',
      'widgets.productionStackbar.to_network',
    ],
    colors: [
      { color: 'self-consumption', colorShade: '' },
      { color: 'production-grid', colorShade: '' },
    ],

    db_fields: ['self_consumption_direct', 'grid_injection'],
  },
  savingsStackBar: {
    id: 'economics',
    title: 'widgets.savingsStackBar.title',
    type: 'stackBar',
    buttons: [
      'widgets.savingsStackBar.to_community',
      'widgets.savingsStackBar.to_network',
    ],
    gradientBorder: false,
    view: 'site',

    colors: [
      { color: 'economics', colorShade: '' },
      { color: 'economics', colorShade: '60' },
    ],

    db_fields: ['revenues', 'savings'], //FIX ME
  },
  costsCard: {
    id: 'costs',
    title: 'Costi Stimati',
    value: '-.-',
    icon: 'bill_no_currency_outline',
    view: 'site',
    db_field: '', //FIX ME
    gradientBorder: false,
    details: {
      title: 'widgets.selfSufficiency.details.title',
      db_field_value: 'self_sufficiency_rate',
      label_line_1: 'widgets.selfSufficiency.details.label_line_1',
      label_line_2: {
        label_1: 'widgets.selfSufficiency.details.label_line_2.label_1',
        label_2: 'widgets.selfSufficiency.details.label_line_2.label_2',
      },
      db_field_1: 'self_sufficiency_rate',
      db_field_2: 'self_consumption',
    },
  },
  savingsCard: {
    id: 'savings',
    title: 'widgets.savingsCard.title',
    value: '-.-',
    icon: 'economic_benefits_solid',
    view: 'site',
    db_field_value: 'total_benefits',
    label: 'widgets.savingsCard.label',
    label_line_2: 'widgets.savingsCard.label_line_2',
    db_field: 'revenues',
    db_field_2: 'total_savings',
    gradientBorder: false,
    details: {
      title: 'widgets.savingsCard.details.title',
      db_field_value: 'total_benefits',
      label: 'widgets.savingsCard.details.label',
      revenues: 'widgets.savingsCard.details.revenues',
      revenues_db_field: 'total_savings',
      values: [
        {
          label: 'widgets.savingsCard.details.value_label_1',
          db_field: 'savings',
        },
        {
          label: 'widgets.savingsCard.details.value_label_2',
          db_field: 'savings_optimization',
        },
      ],
      earnings_line_1: 'widgets.savingsCard.details.earnings_line_1',
      earnings_value: 'revenues',
      earnings_line_2: 'widgets.savingsCard.details.earnings_line_2',
      arbitrary_savings_db_field: 'tariff_current', //FIX ME find in available sites
    },
  },

  sharedEnergyCard: {
    id: 'sharedEnergy',
    title: 'Energia condivisa',
    value: 'widgets.sharedEnergy.label',
    icon: 'shared_energy',
    view: 'site',
    db_field: 'shared_withdrawn',
    db_field_2: 'shared_injection',
    db_field_value: 'shared_total',
    details: {
      title: 'widgets.sharedEnergy.details.title',
      db_field_value: 'shared_total',
      label_line_1: 'widgets.sharedEnergy.details.label_line_1',
      label_line_2: 'widgets.sharedEnergy.details.label_line_2',
      db_field_1: 'shared_withdrawn',
      db_field_2: 'shared_injection',
    },
    gradientBorder: false,
  },
  batteryCombinedGraph: {
    id: 'battery',
    title: 'widgets.batteryCombinedGraph.title',
    type: 'batteryGraph',
    buttons: [
      'widgets.batteryCombinedGraph.battery_discharge',
      'widgets.batteryCombinedGraph.battery_charge',
      'soc',
    ],
    // colors: { primary: 'battery', secondary: 'battery' },
    colors: [
      { color: 'battery', colorShade: '80' },
      { color: 'battery', colorShade: '50' },
      { color: 'battery', colorShade: '' },
    ],
    graphKeys: [
      {
        title: 'widgets.batteryCombinedGraph.battery_discharge',
        color: 'battery',
      },
      {
        title: 'widgets.batteryCombinedGraph.battery_charge',
        color: 'battery',
      },
      {
        title: 'widgets.batteryCombinedGraph.soc',
        color: 'battery',
      },
    ],
    db_fields: ['battery_discharge', 'battery_charge', 'soc'],
    gradientBorder: false,
  },
  areaChart: {
    id: 'areaChartSelfConsumption',
    title: 'widgets.areaChart-self-consumption.title',
    type: 'areaChart',
    gradientBorder: false,
    view: 'site',

    buttons: [
      'widgets.areaChart-self-consumption.from_network',
      'widgets.areaChart-self-consumption.to_network',
      'widgets.areaChart-self-consumption.self_consumption',
    ], //FIX ME

    colors: [
      { color: 'self-consumption', colorShade: '' },

      { color: 'production', colorShade: '' },
      { color: 'consumption', colorShade: '' },
    ],

    db_fields: ['consumption', 'production', 'self_consumption'],
  },
};

export const widgetsCommunityData = {
  header: { view: 'community' },
  footer: {
    sections: ['household', 'community'],
    shadow: true,
    roundCorners: true,
    bgColor: '#ffffff',
  },
  communityStats: {
    gradientBorder: false,

    consumption: {
      totalAmount: '',
      totalAmounttype: '',
      colors: [
        { color: 'consumption', shade: '' },
        { color: 'consumption', shade: '60' },
      ],
      keys: '',
      title: '',
      percentage: 0,
      db_field_total: 'total',
      db_field_percentage: 'no_float_site',
      db_field_value: 'community_grid_withdrawn',
    },
    production: {
      totalAmount: '',
      totalAmounttype: '',
      colors: [
        { color: 'production', shade: '' },
        { color: 'production', shade: '60' },
      ],
      keys: '',
      title: '',
      percentage: 0,
      db_field_total: 'total',
      db_field_percentage: 'no_float_site',
      db_field_value: 'community_grid_injection',
    },
    shared: {
      totalAmount: '',
      totalAmounttype: '',
      colors: [
        { color: 'shared', shade: '' },
        { color: 'shared', shade: '60' },
      ],
      keys: '',
      title: '',
      percentage: 0,
      db_field_total: 'total',
      db_field_percentage: 'no_float_site',
      db_field_value: 'community_shared_total',
    },
  },
  selfSufficiencyCard: {
    id: 'selfSufficiency',
    title: 'widgets.selfSufficiency.title',
    value: '-.- % ',
    icon: 'economic_benefits_solid',
    view: 'community',
    db_field_value: 'self_sufficiency_rate',
    label: 'widgets.selfSufficiencyCommunity.label',
    details: {
      title: 'widgets.selfSufficiencyCommunity.details.title',
      db_field_value: 'self_sufficiency_rate',
      label_line_1: 'widgets.selfSufficiencyCommunity.details.label_line_1',
    },
    gradientBorder: false,
  },
  pollutionCard: {
    id: 'pollution',
    title: 'CO2',
    value: 'widgets.pollution',
    icon: 'power_station_solid',
    view: 'community',
    db_field_value: 'co2_offset_kgs',
    db_field_trees: 'co2_offset_trees',
    details: {
      title: 'widgets.pollution.details.title',
      db_field_value: 'co2_offset_kgs',
      label_line_1: {
        label_1: 'widgets.pollution.details.label_line_1.label_1',
        label_2: 'widgets.pollution.details.label_line_1.label_2',
      },
      label_line_2: {
        label_1: 'widgets.pollution.details.label_line_2.label_1',
        label_2: 'widgets.pollution.details.label_line_2.label_2',
      },
    },
    gradientBorder: false,
  },

  savingsCard: {
    id: 'savingsCard',
    title: 'widgets.savingsCommunity.title',
    value: '-.-',
    icon: 'earnings',
    view: 'community',
    db_field_value: 'revenues',
    label: 'widgets.savingsCommunity.label',
    gradientBorder: false,

    details: {
      title: 'widgets.savingsCommunity.details.title',
      db_field_value: 'revenues',
      label: 'widgets.savingsCommunity.details.label_line_1',
      values: [
        {
          label: 'widgets.savingsCommunity.details.value_label_1',
          db_field: 'public_incentive',
        },
        {
          label: 'widgets.savingsCommunity.details.value_label_2',
          db_field: 'charge_reduction',
        },
      ],
      db_field_1: 'total_benefits',
      db_field_2: 'self_consumption',
    },
  },
  // savings: {
  //   id: 'savings',
  //   title: 'widgets.areaChart.title',
  //   value: '-.-',
  //   icon: 'saving_no_currency_outline',
  //   view: 'community',
  // },
  savingsStackbarCommunity: {
    id: 'savingsStackbarCommunity',
    type: 'stackBar',
    title: 'widgets.savingsStackbarCommunity.title',
    gradientBorder: false,
    view: 'community',

    buttons: ['widgets.savingsStackbarCommunity.revenues'],
    colors: [{ color: 'economics', colorShade: '' }],

    db_fields: ['revenues'],
  },
  areaChart: {
    id: 'areaChart',
    title: 'widgets.areaChart.title',
    type: 'areaChart',
    gradientBorder: false,
    view: 'community',

    buttons: [
      'widgets.areaChart.from_network',
      'widgets.areaChart.to_network',
      'widgets.areaChart.shared',
    ],
    colors: [
      { color: 'self-consumption', colorShade: '' },
      { color: 'battery', colorShade: '' },
      { color: 'production', colorShade: '' },
    ],
    db_fields: ['grid_withdrawn', 'grid_injection', 'shared_total'],
  },
};
