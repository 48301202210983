
import React, { useContext, useEffect, useState, } from 'react';

import styles from './header-web.module.scss';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import { ProfileButton, Search } from '@flexo/atoms';

import './header-web.module.scss';

const {
  Header__Web,
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__BreadCrumb,
  Navigation__Select,
  Navigation__Select__Item,
} = styles;

function CommunitiesNavigation(props: any) {
  
  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null } = props;
  const { t } = useTranslation()
  
  const { label } = props;
  

  return (
<>
      <div className={Header__Web__Left}>
        
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>
        
        <div className={Navigation}>
          <strong >{t(`widgets.header.${label}`)}</strong>

      
        </div>


      </div>
      <div className={Header__Web__Right}>
        {
          download && <>
            <IconWrapper iconName={'chevron-encircled'} />
          </>
        }
        {
          searchBar &&
          <Search />
        }
        {
        profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(CommunitiesNavigation);
