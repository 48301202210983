
import { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface IthemeProvider {
  colors: any;
  dropdownVisible: any;
  isInBackground: boolean;
  minTimeResolution: string;
  breakpoint: string;
}

export type ThemeStore = IthemeProvider;

export enum ThemeTypes {
  SetColors = 'SET_COLORS',
  SetBackgroundState = 'SET_BACKGROUND_STATE',
  TraceDropDown = 'TRACE_DROPDOWN',
  SetMinTimeResolution = 'SET_MIN_TIME_RESOLUTION',
  SetBreakPoint = 'SET_BRAKEPOINT',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [ThemeTypes.SetMinTimeResolution]: {
    value: any;
  };
  [ThemeTypes.SetColors]: {
    value: any;
  };
  [ThemeTypes.SetBackgroundState]: {
    value: any;
  };
  [ThemeTypes.TraceDropDown]: {
    value: any;
  };
  [ThemeTypes.SetBreakPoint]: {
    value: string;
  };
};

export interface ContextValues {
  ThemeStore: ThemeStore;
  setThemeStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
