import { useRef, useState } from 'react';

import { IconWrapper } from '@flexo/general';
import { Button, Textfield } from '@flexo/atoms';
import { useTranslation } from 'react-i18next';

import styles from './account-creation.module.scss';

let _formData = {
  generalities: '',
  password: '',
  password_confirm: '',
}


export function AccountCreationWeb() {

  const email = 'default@email.it';

  const formRef = useRef<any>(null);
  const generalitiesRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);
  const passworConfirmdRef = useRef<any>(null);

  const { t } = useTranslation();
  const { title, inputs, buttonLabel } = t('pages.account_creation_web', { returnObjects: true }) as any;

  const [formData, setFormData] = useState(_formData)

  const [ checkList, setChecklist ] = useState<any>(Object.values(inputs.checkList)
      .reduce((prev: any, next: any) => ({ ...prev, [next.name]: false }), {}))

  const formRules = Object.values(inputs.checkList)
    .map((checkItem: any) => ({
      name: checkItem.name,
      rule: checkItem.rule,
    }));



  const [toggleIcons, setToggleIcons] = useState({
    password: 'hide',
    password_confirm: 'hide',
  })

  const [toggleTypes, setToggleTypes] = useState({
    password: 'password',
    password_confirm: 'password',
  })

  async function handleInputChange(event: any) {
    const { name, value } = event.target;
    _formData = { ..._formData, [name]: value };
    setFormData(_formData);

    if (name === 'password') {
      checkValidation( value);
    }

  }

  function togglePasswordVisibility(inputName: string) {

    setToggleIcons( {
      ...toggleIcons,
      [inputName]: toggleIcons[inputName] === 'hide' ? 'show' : 'hide'
    })
    
    setToggleTypes( {
      ...toggleTypes,
      [inputName]: toggleTypes[inputName] === 'password' ? 'text' : 'password'
    })
    
  }

  function checkValidation( value: string) {
      const temp_checklist = {};
        formRules.forEach((rule: any) => {
          const regex = new RegExp(rule.rule);
          temp_checklist[rule.name] = regex.test(value);
        });
        setChecklist(temp_checklist);
  }

  

  return (
    <div className={styles.AccountCreationWeb}>

      <div className={styles.AccountCreationWeb__Header}>
        <IconWrapper iconName={'logo'} className={styles.AccountCreationWeb__Header__Logo} />
        <div className={styles.AccountCreationWeb__Header__Title}>
          {title}
        </div>
        <div className={styles.AccountCreationWeb__Header__Email}>
          {email}
        </div>

      </div>

      <form id="account_creation_web_form" className={styles.AccountCreationWeb__Form} ref={ formRef}>

        {/* generalities */}

        <div className={styles.AccountCreationWeb__Form__Input}>

          <Textfield
              ref={generalitiesRef}
            label={inputs.generalities.label}
            name={inputs.generalities.name}
            type={inputs.generalities.type}
            placeholder={inputs.generalities.placeholder}
            onPaste={(e) => false}
            onCopy={(e) => false}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            defaultValue={formData[inputs.generalities.name]}
            onChange={(ev: any) => handleInputChange(ev)}
            
          />
        </div>
        {/* password */}


        <div className={styles.AccountCreationWeb__Form__Input}>

          <Textfield
              ref={passwordRef}
            label={inputs.password.label}
            name={inputs.password.name}
            type={toggleTypes[inputs.password.name]}
            placeholder={inputs.password.placeholder}
            onPaste={(e) => false}
            onCopy={(e) => false}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            defaultValue={formData[inputs.password.name]}
            onChange={(ev: any) => handleInputChange(ev)}
            icon={toggleIcons[inputs.password.name]}
            onIconClick= {() => togglePasswordVisibility(inputs.password.name)}
          />
        </div>

        {/* generalities */}

        <div className={styles.AccountCreationWeb__Form__Input}>

          {
            inputs.checkList.map((checkItem) => (
              <div
                className={styles.AccountCreationWeb__Form__Checklist__Item}
              >
                <span
                  className={styles.AccountCreationWeb__Form__Checklist__Item__Checkbox}
                >
                  {
                    checkList[ checkItem.name ] === true ? (
                      <IconWrapper iconName={'chevron-encircled_outline'} />
                    ) : (
                      <IconWrapper iconName={'ellipse'} />
                    )
                  }
                  
                </span>
                <p
                    className={styles.AccountCreationWeb__Form__Checklist__Item__Text}
                >{checkItem.label}
                </p>
              </div>
            ) )
          }
          
        </div>


        {/* password_confirm */}

        <div className={styles.AccountCreationWeb__Form__Input}>

        <Textfield
            ref={passworConfirmdRef}
            label={inputs.password_confirm.label}
            name={inputs.password_confirm.name}
            type={toggleTypes[inputs.password_confirm.name]}
            placeholder={inputs.password_confirm.placeholder}
            onPaste={(e) => false}
            onCopy={(e) => false}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            defaultValue={formData[inputs.password_confirm.name]}
            onChange={handleInputChange}

            icon={toggleIcons[inputs.password_confirm.name]}
            onIconClick= {() => togglePasswordVisibility(inputs.password_confirm.name)}
          />
        </div>
      </form>

      
      <div className={ styles.AccountCreationWeb__Footer}>
        <Button
          className={ styles.AccountCreationWeb__Footer__Button}
          label={
            buttonLabel}
          variant="primary"
          color="secondary"
          disabled={
            formData.generalities === '' ||
            formData.password === '' ||
            formData.password_confirm === '' ||
            formData.password !== formData.password_confirm
          }
        />

      </div>

    </div>
  )

}