


import { useTranslation } from "react-i18next"
import styles from './../alert.module.scss'
import { IconWrapper } from "@flexo/general";
import { AlertContext, AlertTypes } from "@flexo/providers";
import { useContext } from "react";
import parse from 'html-react-parser';

export default function BannerPasswordresetSuccess() {

  const { t } = useTranslation();
  const { setAlertStore } = useContext(AlertContext);
  const alertContent = t('banners.password-reset-success', { returnObjects: true }) as any;

  return (
    <div id="banner-password-reset-success" className={ styles.Banner__Password__Reset__Success}>
      <div className={styles.Banner__Title__Positive} >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'row',
      }}>
          <IconWrapper style={{ height: 16, width: 16, marginRight: 10, marginTop: -2 }} iconName="chevron-encircled_outline" />
          {alertContent.title}
        </div>
        <div
          onClick={() =>
            setAlertStore({
              type: AlertTypes.ResetAlert,
              payload: null as any,
            })
          }
        >
          <IconWrapper
            className={styles.Alert__Title__Close}
            iconName="cross"

          />
        </div>

      </div>
      <div className={styles.Banner__Content} >
        {parse(alertContent.description)} 
      </div>
    </div>
  )
}