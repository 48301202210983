import {
  ModulesStore,
  IModule as _IModule,
} from './modules-provider.interfaces';

const ENV = (import.meta as any).env;

export const storeInit: ModulesStore = {
  startState: [],
  modules: [],
  breakpoint: 'xl',
};
