import 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { getApp, initializeApp } from 'firebase/app';
import {
  Auth,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  onAuthStateChanged,
  signInWithCustomToken,
  signOut,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import axios from 'axios';
import httpService from './httpService';
import { Subject } from 'rxjs';

const ENV = (import.meta as any).env;

const firebaseCustomToken = ENV.VITE_APP_FIREBASE_CUSTOM_TOKEN;
const org = ENV.VITE_APP_ORG;

const firebaseConfig = {
  apiKey: ENV.VITE_APP_FIREBASE_KEY,
  authDomain: ENV.VITE_APP_AUTH_DOMAIN,
  projectId: ENV.VITE_APP_PROJECT_ID,
  storageBucket: ENV.VITE_APP_STORAGE_BUCKET,
  messagingSenderId: ENV.VITE_APP_SENDER_ID,
  appId: ENV.VITE_APP_ID,
  measurementId: ENV.VITE_APP_MEASUREMENT_ID,
  tenantId: ENV?.VITE_APP_TENANT || 'plenitude-wjxil',
};

const FirebaseApp = initializeApp(firebaseConfig);

export let auth: Auth;
export const tokenChange = new Subject<string>();

if (Capacitor.isNativePlatform()) {
  auth = initializeAuth(getApp(), {
    persistence: indexedDBLocalPersistence,
  });
} else {
  auth = getAuth(FirebaseApp);
}

auth.tenantId = firebaseConfig.tenantId;

export class FirebaseService {
  async getCustomToken(email: string, password: string) {
    const endpoint = firebaseCustomToken;
    const data = {
      user: email,
      password: password,
      org: org,
    };

    try {
      const response = await httpService.post({
        url: endpoint,
        data: data,
      });
      return (response as any).data.token;
    } catch (error: any) {
      throw new Error(error.code);
    }
  }

  async signInWithToken(token: string) {
   
    return signInWithCustomToken(auth, token);
  }

  async signInWithUserNameAndPassord(email: string, password: string) {
    // alert('signInWithUserNameAndPassord');
    return signInWithEmailAndPassword(auth, email, password);
  }

  onAuthStateChange(callback: (user: any) => void) {
    return onAuthStateChanged(auth, callback);
  }

  async getIdToken(forceRefresh = false): Promise<string | undefined> {
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken(forceRefresh);
    }
    return undefined;
  }

  getCurrentUserEmail(): string | null {
    const user = auth.currentUser;
    return user ? user.email : null;
  }

  getCurrentUserDisplayname(): string | null {
    const user = auth.currentUser;
    return user ? user.displayName : null;
  }

  async signOut(): Promise<void> {
    await signOut(auth);
  }

  setupTokenChangeListener() {
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          tokenChange.next(token);
        } catch (error) {
          // console.error('Error fetching token:', error);
        }
      } else {
        // Handle the case when the user is signed out
        tokenChange.next(undefined); //TODO - Z Correctly handle the undefined state if not already done
      }
    });
  }
  
}
