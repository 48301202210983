import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  LeftDrawerStore,
  StorageActions,
} from './left-drawer.interfaces';
import { reducer } from './left-drawer-provider.reducer';
import { storeInit } from './initialState';

type Reducer<S, A> = (state: S, action: A) => S;



export const LeftDrawerContext = createContext<ContextValues>({
  LeftDrawerStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLeftDrawerStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [LeftDrawerStore, dispatch] = useReducer<Reducer<LeftDrawerStore, StorageActions>>(
    reducer as any,
    storeInit
  );
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LeftDrawerContext.Provider value={{ LeftDrawerStore, setLeftDrawerStore: dispatch }}>
      {children}
    </LeftDrawerContext.Provider>
  );
};

export default StoreProvider;
