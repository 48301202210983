import * as luxon from 'luxon';
import { ICalendarDisplay } from './libs.interfaces';

const DateTime = luxon.DateTime;

type ApiResponse = {
  energyUnitAndDiver: any;
  pollutionUnitAndDivider: any;
  economicsUnitAndDivider: any;
  rawData: any[];
  data: any[];
  formattedData: any[];
};

// Takes a JavaScript Date object and returns a formatted string in the "yyyy-MM-dd" format.
export const formatDate = (date: Date) =>
  DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'); // e.g., "2023-01-01"

export const formatHumanDate = (date: string) =>
  DateTime.fromJSDate(new Date(date)).toFormat('dd/MM/yyyy'); // e.g., "2023-01-01"

// Takes a String Date with a starting format and returns it in another.
export const formatDateFromFormatToFormat = (
  date: Date,
  format: string,
  toFormat: string
) => {
  const dateElement = date.toString().split('/');
  const checkedInputString = `${
    dateElement[0].length > 1 ? dateElement[0] : `0${dateElement[0]}`
  }/${dateElement[1].length > 1 ? dateElement[1] : `0${dateElement[1]}`}/${
    dateElement[2]
  }`;
  const inputDate = DateTime.fromFormat(checkedInputString, format);
  return inputDate.setLocale('it').toFormat(toFormat); // e.g., "dd MM yyyy" -> "1 August 2023"
};

// Takes a JavaScript Date object and returns a formatted string in the "yyyy-MM-dd" format.
export const formatEndOfWeek = (date: Date) =>
  DateTime.fromJSDate(date).plus({ days: 1 }).toFormat('yyyy-MM-dd'); // e.g., "2023-01-01"

// Takes a JavaScript Date object and returns a formatted string representing the day of the month.
const formatDayNumber = (date: Date) =>
  DateTime.fromJSDate(date).toFormat('dd'); // e.g., "01"

// Takes a JavaScript Date object and returns a formatted string representing the month.
const formatMonthNumber = (date: Date) =>
  DateTime.fromJSDate(date).toFormat('MM'); // e.g., "01"

// Takes a JavaScript Date object and returns a formatted string representing the full month and year.
const formatMonthYear = (date: Date) =>
  DateTime.fromJSDate(date).toFormat('MMMM yyyy'); // e.g., "January 2023"

// Takes a JavaScript Date object and returns a formatted string representing the year.
const formatYear = (date: Date) => DateTime.fromJSDate(date).toFormat('yyyy'); // e.g., "2023"

// Helper functions to format date
export const getFormattedDay = (date: Date) => formatDate(date);
export const getFormattedDayNumber = (date: Date) => formatDayNumber(date);
export const getMonthNumber = (date: Date) => formatMonthNumber(date);
export const getFormattedMonthYear = (date: Date) => formatMonthYear(date);
export const getYear = (date: Date) => formatYear(date);

// Gets the end of the day for a given date, in ISO 8601 format.
export const getFormattedEndOfDay = (date: Date) =>
  DateTime.fromISO(formatDate(date))
    .endOf('day')
    .toFormat("yyyy-MM-dd'T'HH:mm:ss"); // e.g., "2023-01-01T23:59:59"

// Returns the day after the input date
export const getFormattedNextDay = (date: Date) => {
  const nextDay = DateTime.fromJSDate(date).plus({ days: 1 });
  return formatDate(nextDay.toJSDate()); // e.g., "2023-01-02"
};

// Returns the month after the input date
export const getFormattedNextMonth = (date: Date) => {
  const nextMonth = DateTime.fromJSDate(date).plus({ months: 1 });
  return formatDate(nextMonth.toJSDate()); // e.g., "2023-02-01"
};

// Returns the last day of the year for the input date
export const getLastDayOfYear = (date: Date) => {
  const year = DateTime.fromJSDate(date).year + 1;
  const lastDayOfYear = DateTime.fromObject({ year, month: 1, day: 1 });
  return formatDate(lastDayOfYear.toJSDate()); // e.g., "2023-12-31"
};

// Formats date to Italian locale
export const formatDateItalian = (date: string) => {
  const unformattedDate = DateTime.fromISO(date).setLocale('it');
  const formattedDate = unformattedDate.toFormat("d'°' LLLL yyyy"); // e.g., "1° gennaio 2023"
  return formattedDate;
};

// Calculates the percentage of value1 in the sum of value1 and value2, or in totalAmount if provided.
export const calculatePercentage = (
  value1: any,
  value2: any,
  totalAmount?: any
) => {
  const showedValue = parseFloat(value1);
  const helperValue = parseFloat(value2);
  const total = totalAmount ? totalAmount : showedValue + helperValue;
  const percentage = (showedValue / total) * 100; // e.g., 50
  return percentage;
};

// Converts an ISO 8601 date string to the format "yyyy-MM-dd'T'HH:mm:ss".
export const formatStringToDate = (input: string): string => {

  try {

    const date = DateTime.fromISO(input);
    return date.toFormat('yyyy-MM-dd'); // e.g., "2023-01-01T00:00:00"
  } catch (err: any) {
    // console.warn(err);
    return '';
  }

};

// Gets the end of the month for a given date, in ISO 8601 format.
export const getFormattedEndOfMonth = (input: Date): string => {
  const nextDate = DateTime.fromJSDate(input).plus({ months: 1 });
  const nextDateMonth = nextDate.month;
  const nextDateYear = nextDate.year;
  const firstDayOfNextMonth = DateTime.fromObject({
    year: nextDateYear,
    month: nextDateMonth,
    day: 1,
  });

  return firstDayOfNextMonth.toFormat("yyyy-MM-dd'T'HH:mm:ss"); // e.g., "2023-01-31T23:59:59"
};

export const getTimeGranularityOverview = (
  calendarDisplay: ICalendarDisplay
) => {
  let timeGranularity = '1day';
  switch (calendarDisplay) {
    case 'week': {
      timeGranularity = '1week';
      break;
    }
    case 'month': {
      timeGranularity = '1month';
      break;
    }
    case 'year': {
      timeGranularity = '1year';
      break;
    }
    default: {
      return timeGranularity;
    }
  }
  return timeGranularity;
};

export const getTimeGranularity = (
  calendarDisplay: ICalendarDisplay,
  minTimeResolution: string | null
) => {
  let timeGranularity = minTimeResolution || '1hour';

  switch (calendarDisplay) {
    case 'week': {
      timeGranularity = '1day';
      break;
    }
    case 'month': {
      timeGranularity = '1day';
      break;
    }
    case 'year': {
      timeGranularity = '1month';
      break;
    }
    default: {
      return timeGranularity;
    }
  }
  return timeGranularity;
};

export function capitalizeFirstLetter(str: string) {
  // converting first letter to uppercase
  try {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } catch (err: any) {
    // console.warn(err);
    // console.warn(`returing string [ ${str} ]`);
    return str;
  }
}
export const formatString = (
  inputString: string,
  type: 'number' | 'unit',
  widgetType?: 'stats' | 'card'
): string | number => {
  let letterIndex = 0;
  let numericalPart = '';
  let unitPart = '';

  // Find the position of the first letter (a-z) in the string
  try {
    letterIndex = inputString.search(/[a-z]/i);
  } catch (err: any) {
    // console.warn(err);
  }

  // Extract the numerical part before the letter
  try {
    numericalPart = inputString.substring(0, letterIndex);
  } catch (err: any) {
    // console.warn(err);
  }

  // Extract the unit part after the letter
  try {
    unitPart = inputString.substring(letterIndex);
  } catch (err: any) {
    // console.warn(err);
  }

  if (type === 'number') {
    // return convertAndRound(numericalPart, widgetType);
    return numericalPart;
  }
  return unitPart;
};
export function convertAndRoundPercentage(str: any): number {
  if (typeof str !== 'string') {
    return 0;
  } else {
    // Replace comma with period
    const replacedStr = str.replace(',', '.');
    // Convert to float
    const num = parseFloat(replacedStr);
    // Round and return
    return Math.round(num);
  }
}
export function convertAndRound(str: any, type?: 'card' | 'stats'): number {
  if (typeof str !== 'string') {
    return 0;
  } else {
    // Replace comma with period
    const replacedStr = str.replace(',', '.');
    // Convert to float
    const num = parseFloat(replacedStr);
    if (type === 'card') {
      return Math.round(num);
    }
    if (num >= 100) {
      return Math.round(num);
    }
    // Round and return
    else return num;
  }
}
export function extractNumber(inputString: string): string {
  try {
    const numberMatch = inputString.match(/[+-]?([0-9]*[.])?[0-9]+/);
    return numberMatch ? numberMatch[0] : '';
  } catch (err: any) {
    // console.warn(err);
    return '';
  }
}

function isNumeric(value: any): boolean {
  return typeof value === 'number' && !isNaN(value);
}

function aggregateValues(entries: any[]): any[] {
  const aggregatedEntries: { [key: string]: any } = {};

  entries.forEach((entry) => {
    if (!aggregatedEntries[entry.timestamp]) {
      aggregatedEntries[entry.timestamp] = { ...entry };
    } else {
      Object.keys(entry).forEach((key) => {
        if (isNumeric(entry[key])) {
          aggregatedEntries[entry.timestamp][key] =
            (aggregatedEntries[entry.timestamp][key] || 0) + entry[key];
        }
      });
    }
  });

  return Object.values(aggregatedEntries);
}

export function communitiesAggregateData(response: ApiResponse): ApiResponse {
  return {
    ...response,
    rawData: aggregateValues(response.rawData),
    data: aggregateValues(response.data),
    formattedData: aggregateValues(
      response.formattedData.map((item) => item.localized)
    ).map((localized, index) => {
      return {
        ...response.formattedData[index],
        localized,
      };
    }),
  };
}
