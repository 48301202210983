import { useContext, useEffect, useState } from 'react';
import { Button, Textfield } from '@flexo/atoms';
import {
  FirebaseService,
  IconWrapper,
  REGEX,
  httpService,
} from '@flexo/general';
import styles from './forgot-password.module.scss';
import {
  _getQueryParams,
  AlertContext,
  AlertTypes,
  ModulesContext,
  ModulesTypes,
} from '@flexo/providers';
import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const ENV = (import.meta as any).env;



/* eslint-disable-next-line */
export interface ForgotPasswordProps {
  logInData: any;
  setForgotPasswordFlow: any;
  bgColor?: string;
}

export function ForgotPassword({ setForgotPasswordFlow }: ForgotPasswordProps) {
  const {
    ForgotPassword__Inputs,
    ForgotPassword__Footer,
    ForgotPassword__Footer__End,
    ForgotPassword__Footer__Button,
    ForgotPassword__Footer__Return__Wrapper,
  } = styles;

  const { setModulesStore } = useContext(ModulesContext);

  const [authData, setAuthData] = useState({
    email: '',
    recovery_code: '',
  });
  const [logInClicked, setForgotPasswordClicked] = useState(false);

  const [sendEmailError, setSendEmailError] = useState(false);
  const [sendCodeRequest, setSentSendCodeRequest] = useState(false);
  const [resentSuccess, setResentSuccess] = useState(false);

  const [recoveryCodeRequestError, setRecoveryCodeRequestError] = useState(
    false
  );
  const [recoveryCodeSent, setRecoveryCodeSent] = useState(false);

  const { setAlertStore } = useContext(AlertContext);
  const [logInError, setForgotPasswordError] = useState(false);
  const [networkStatus, setNetworkStatus] = useState(false);
  const { t } = useTranslation();

  const [inputType, setInputType] = useState({
    email: 'text',
    password: 'password',
    confirmPassword: 'password',
  });

  const [page, setPage] = useState(1);

  const {
    title,
    subtitle,
    inputs,
    buttonLabel,
    return_label,
    onButtonClick,
    passwordConditions,
  } = t('pages.forgot_password.data', { returnObjects: true }) as any;

  const {
    title: recoveryTitle,
    subtitle: recoverySubtitle,
    subtitle_addon: recoverySubtitleAddon,
    subtitle_addon_success: recoverySubtitleAddonSuccess,
    inputs: recoveryInputs,
    buttonLabel: recoveryButtonLabel,
  } = t('pages.recovery_code_data.data', {
    returnObjects: true,
    email: authData.email,
  }) as any;

  const loader = '/loaderLottie.json';

  async function askForRecoveryCode( resent = false ) {
    setSentSendCodeRequest(true);

    const response = await httpService
      .post({
        url: `${ENV.VITE_APP_BASE_URL}/auth/v1_1/password-forgot`,
        data: {
          email: authData.email,
        },
      })
      .then((resp: any) => ({
        status: resp.status,
        statusText: resp?.statusText || 'Unknown status text',
        message: resp?.data?.message || 'Unknown message',
      }))
      .catch((error) => {
        // console.warn(error);
        return {
          status: error?.response?.status || 500,
          statusText: error?.response?.statusText || 'Unknown error',
        };
      });

    setSentSendCodeRequest(false);

    if (response.status >= 500) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_UNEXPECTED_ERROR',
          },
        },
      });
      // if (response.status === 403) {
      //   setAlertStore({
      //     type: AlertTypes.SetAlert,
      //     payload: {
      //       value: {
      //         type: 'ALERT_NOT_ALLOWED',
      //       },
      //     },
      //   });
      // } else {
      //   setAlertStore({
      //     type: AlertTypes.SetAlert,
      //     payload: {
      //       value: {
      //         type: 'ALERT_UNEXPECTED_ERROR',
      //       },
      //     },
      //   });
      // }

      setSendEmailError(true);

    } else {
      setSendEmailError(false);
      setPage(2);


      if (resent === true) {
        setResentSuccess( true )
      }

    }
  }

  const handleInputChange = (event: any) => {
    setSendEmailError(false);
    setForgotPasswordError(false);
    setRecoveryCodeRequestError(false);

    // _authData = { ..._authData, [event.target.name]: event.target.value };
    // setAuthData(_authData);

    setAuthData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const togglePasswordVisibility = (name: string) => {
    setInputType((prevState) => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : 'password',
    }));
  };

  async function navigateToResetPassword() {

    const checkCodeResponse = await httpService.post({
      url: `${ENV.VITE_APP_BASE_URL}/auth/v1_1/validate-reset-code`,
      data: {
        email: authData.email,
        reset_code: authData.recovery_code,
      }
    })
      .then(x => {
        return x?.data?.valid || false
      } )
      .catch((err: any) => {
        // console.warn(err);
        return false;
      })
    
    
    if (checkCodeResponse === false) {

      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_INVALID_CREDENTIALS',
          },
        },
      })

      setForgotPasswordError(true);

      return;

    } else {
      setModulesStore({
        type: ModulesTypes.ActivateResetPasswordModule,
        payload: {
          value: authData,
        },
      });
    }

    
  }

  useEffect(() => {
    const params: any = _getQueryParams();
    if ( params?.email && params?.page === '2' ) {
      setAuthData({ ...authData, email: params.email });
      setPage(2);
    }

  }, [])

  return (
    <div className={styles.ForgotPassword}>
      <div className={styles.ForgotPassword__Logo}>
        <IconWrapper iconName="logo_wide" />
      </div>

      {page === 1 && (
        <div className={styles.Login__Content}>
          <div className={styles.ForgotPassword__Header__Title}>{title}</div>

          <div className={styles.ForgotPassword__Header__Subtitle}>{subtitle}</div>

          <form
            className={ForgotPassword__Inputs}
            onSubmit={(ev: any) => ev.preventDefault()}
          >
            {inputs.map((input: any) => (
              <div key={input.name}>
                <Textfield
                  containedLabel={false}
                  name={input.name}
                  value={authData[input.name]}
                  placeholder={input.placeholder}
                  label={input.label}
                  onChange={(event) => handleInputChange(event)}
                  onPaste={(e) => false}
                  onCopy={(e) => false}
                  // type={input.type}
                  description={input.description}
                  type={inputType[input.name]}
                  icon={input.icon}
                  onIconClick={togglePasswordVisibility}
                  // onDescriptionClick={() => {
                  //   navigate('/forgotten-password');
                  // }}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                  autoComplete="off"
                  underline={sendEmailError === true ? 'visible' : 'hidden'}
                  //FIX ME --> this is commented out for testing purposes on plenitude
                />
              </div>
            ))}
          </form>
          <div className={ForgotPassword__Footer}>
            <div className={ForgotPassword__Footer__Return__Wrapper}>
              {return_label.label}&nbsp;
              <span
                onClick={() =>
                  setModulesStore({ type: ModulesTypes.ActivateLoginModule })
                }
              >
                {return_label.buttonLabel}
              </span>
            </div>
            <Button
              className={ForgotPassword__Footer__Button}
              onClick={() => askForRecoveryCode()}
              label={
                sendCodeRequest === true ? (
                  <Player
                    autoplay
                    loop
                    src={loader}
                    style={{ height: 20, transform: 'scale(0.7)' }}
                  />
                ) : (
                  buttonLabel
                )
              }
              variant="primary"
              color="secondary"
              disabled={
                !REGEX.email.test(authData.email) || sendCodeRequest === true
              }
            />
          </div>
        </div>
      )}

      {page === 2 && (
        <div className={styles.Login__Content}>
          <div className={styles.ForgotPassword__Header__Title}>{recoveryTitle}</div>

          <div className={styles.ForgotPassword__Header__Subtitle}>
            
            {parse(recoverySubtitle)}
            {
              resentSuccess === true
                ? <span ><strong>{recoverySubtitleAddonSuccess}</strong>{ ' ' }<IconWrapper iconName={'chevron-encircled_outline'} /></span>
                : <span onClick={ () => askForRecoveryCode( true )} className={`color-primary-500`}>{recoverySubtitleAddon}</span>
            }
            
          </div>

          <form className={ForgotPassword__Inputs}>
            {recoveryInputs.map((input: any) => (
              <div key={input.name}>
                <Textfield
                  containedLabel={false}
                  name={input.name}
                  value={authData[input.name]}
                  placeholder={input.placeholder}
                  label={input.label}
                  onChange={(event) => handleInputChange(event)}
                  onPaste={(e) => false}
                  onCopy={(e) => false}
                  // type={input.type}
                  description={input.description}
                  type={inputType[input.name]}
                  icon={input.icon}
                  onIconClick={togglePasswordVisibility}
                  // onDescriptionClick={() => {
                  //   navigate('/forgotten-password');
                  // }}
                  underline={ logInError === true ? 'visible' : 'hidden'}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                  autoComplete="off"
                  //FIX ME --> this is commented out for testing purposes on plenitude
                />
              </div>
            ))}
          </form>

          <div className={ForgotPassword__Footer__End}>
            <Button
              className={ForgotPassword__Footer__Button}
              onClick={() => navigateToResetPassword()}
              label={
                logInClicked && !logInError ? (
                  <Player
                    autoplay
                    loop
                    src={loader}
                    style={{ height: 20, transform: 'scale(0.7)' }}
                  />
                ) : (
                  recoveryButtonLabel
                )
              }
              variant="primary"
              color="secondary"
              disabled={!REGEX.verificationCode.test(authData.recovery_code) || logInError === true }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
