import { Calendar } from '@flexo/molecules';
import styles from './header.module.scss';
import { IconWrapper, Modal } from '@flexo/general';
import { useContext, useEffect, useState } from 'react';
import {
  AlertContext,
  ModalContext,
  ModalTypes,
  SiteViewContext,
} from '@flexo/providers';
import { useTranslation } from 'react-i18next';
import { RefresherEventDetail } from '@ionic/react';
import { useNavigate } from 'react-router-dom';

export const HeaderSkeleton: React.FC = (props: any) => {
  const {
    Header__Title__Loading,
    Header__Actions__Loading,
    Header__Actions__Button__Loading,
    Header__Calendar,
  } = styles;
  const [networkConnected, setNetworkConnected] = useState(true);
  const { siteViewStore } = useContext(SiteViewContext);
  const { alertStore } = useContext(AlertContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setModalStore } = useContext(ModalContext);

  useEffect(() => {
    // Check if the alert type and title matches the specific alert
    if (
      alertStore.alert.type === 'warning' &&
      (alertStore.alert as any).title === t('errors.no_connection.title')
    ) {
      setNetworkConnected(false);
    }
  }, [alertStore, t]);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    navigate(0);
    setTimeout(() => {
      // Any calls to load data go here
      event.detail.complete();
    }, 2000);
  }
  const onClickProfile = () => {
    setModalStore({
      type: ModalTypes.OpenModal,
      payload: {
        value: {
          type: 'PROFILE',
          visible: true,
          show: true,
          ...props,
        } as any,
      },
    });
  };
  return (
    <div className={styles.Header}>
      <div className={Header__Actions__Loading}>
        <div className={styles.Header__Title__Skeleton}>
          {/* {props.view === 'site' ? (
              <span className="heading1">{t('pages.site.title')}</span>
            ) : (
              <span className="heading1">{t('pages.community.title')}</span>
            )} */}
          <div className={styles.Header__Placeholder}></div>
        </div>

        {/* <div className={Header__Title__Loading}></div> */}
        {/* <button className={Header__Actions__Button__Loading}>
          <IconWrapper iconName="notifications" />
        </button> */}
        <button
          className={Header__Actions__Button__Loading}
          onClick={() => onClickProfile()}
        >
          <IconWrapper iconName="user_solid" />
        </button>
      </div>
      <div className={Header__Calendar}> {/* <Calendar />{' '} */}</div>
      {/* {networkConnected && props.dataInTimeSpan === true && <Shimmer />} */}
    </div>
  );
};

export default HeaderSkeleton;
