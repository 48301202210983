


import { useTranslation } from "react-i18next"
import styles from './../alert.module.scss'
import { useContext } from "react";
import { AlertContext, AlertTypes } from "@flexo/providers";
import { IconWrapper } from "@flexo/general";

export default function BannerChangeUserNameSuccess() {

  const { t } = useTranslation();
  const alertContent = t('banners.username_changes_success', { returnObjects: true }) as any;
  const { setAlertStore } = useContext(AlertContext);

  return (
    <div id="banner-credentials-changes-success"
      className={styles.Banner__Password__Reset__Success}
      
    >
      <div className={styles.Banner__Title__Positive} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}>
        <div>
          {alertContent.title}
          
        </div>
        <div
          onClick={() =>
            setAlertStore({
              type: AlertTypes.ResetAlert,
              payload: null as any,
            })
          }
        >
          <IconWrapper
            className={styles.Alert__Title__Close}
            iconName="cross"
          />
        </div>
      </div>
      
      <div className={styles.Banner__Content} >
        {alertContent.description} 
      </div>
    </div>
  )
}