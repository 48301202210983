/* eslint-disable no-case-declarations */
import { LeftDrawerStore, StorageActions } from './left-drawer.interfaces';
import { storeInit } from './initialState';

export const reducer = (state: LeftDrawerStore, action: StorageActions) => {
  switch (action.type) {
    case 'SET_DRAWER':

      return {
        ...state,
        leftDrawer: {
          show: true,
          ...action.payload.value,
        },
      };
    case 'RESET_DRAWER':
      return storeInit;
    case 'HIDE_DRAWER':
      return {
        leftDrawer: {
          ...storeInit.leftDrawer,
          show: false,
        },
      };
    default:
      return state;
  }
};
