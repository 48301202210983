import styles from './table.module.scss';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import { DataContext, ModulesContext, ModulesTypes, } from '@flexo/providers';
import { useContext, useEffect, useState } from 'react';



export function PaymentsTable(props: any) {

  const {
    Table__NO_DATA
  } = styles;

  const { t } = useTranslation();

  const { dataStore, setDataStore } = useContext<any>(DataContext);
  const { title, no_data_text } = t(`widgets.tables.paymentsTable`, { returnObjects: true }) as any;




  return (
    <>


      {
        dataStore?.data?.paymentsList ?
          <div
            className={`${styles.Table} mb-3`}
            key={Math.floor(Math.random() * 100)}
          >
            <div className={`${styles.Table__Title}`}>
              {title}
            </div>

            <TableContainer component={Paper} className={''}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* {previousColumns && previousColumns.rows.map((column: any, column_i: number) => (
                    <TableCell key={`previous_members_table_${column_i}`}>{column.title}</TableCell>
                  ))} */}
                  </TableRow>
                </TableHead>
                <TableBody>

                  {/* {(dataStore?.data?.members?.previous || []).map((row) => (
                  <TableRow
                    key={row?.name || Math.floor(Math.random() * 100)}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell key={`previous_members_table_row_${row.name}_cell_0`} component="th" scope="row" onClick={() => navigateToMember( row.memberId )}>
                      {row.name}
                    </TableCell>

                    <TableCell key={`previous_members_table_row_${row.name}_cell_1`} component="th" scope="row">
                    {
                        (row?.sites?.length && row?.sites?.length) > 0 &&
                        <div>
                            {row.sites[0].siteName}
                            <br />
                            {row.sites[0].pod}{ row.sites.length > 1 && ` + ${row.sites.length - 1}` }
                        </div>
                      }

                    </TableCell>

                    <TableCell key={`current_members_table_row_${row.communityName}_cell_4`} component="th" scope="row">
                      join date
                    </TableCell>


                  </TableRow>
                ))} */}

                </TableBody>
              </Table>
            </TableContainer>

          </div>

          : <div
            className={`${styles.Table} mb-3`}
            key={Math.floor(Math.random() * 100)}
          >
            <div className={`${styles.Table__Title} heading4`}>
              {title}
            </div>
            <div className={ `${Table__NO_DATA}` }> {no_data_text}</div>
          </div>
      }

    </>
  )



}

export default PaymentsTable;
