import { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './community-details-parameters.module.scss';

import { useTranslation } from 'react-i18next';
import { MetaDataApiContext, ModulesContext } from '@flexo/providers';




export function CommunityDetailsParameters(props: any) {

  const { t } = useTranslation();

  const { title, fields, shownFields } = props;

  const { modulesStore } = useContext(ModulesContext);
  const { metaDataApiStore } = useContext(MetaDataApiContext);

  const {
    Details__Wrapper__Compact,
    Details__Wrapper__Compact__Paper,
    Details__Wrapper__Compact__Paper__Title,
    Details__Wrapper__Compact__Paper__Line,
    Details__Wrapper__Compact__Paper__Detail,
  } = styles

  return (
    <div
      key={uuidv4()}
      className={`
        ${Details__Wrapper__Compact} 
      `}
    >
      <div className={ Details__Wrapper__Compact__Paper}>
      <div  >
          <strong className={ Details__Wrapper__Compact__Paper__Title }>{ t(title) }</strong>
      </div>
      <div>
        {
          shownFields.map(field => (
            <div className={ 'flex flex-column' } key={ `${field}_${uuidv4()}` }>
              <br />
              <span className={ Details__Wrapper__Compact__Paper__Detail }>{(metaDataApiStore?.selectedEntity as any)?.[field]}</span>
              <span className={ Details__Wrapper__Compact__Paper__Line }>{ t( fields[field] )}</span>
            </div>
          ) )
        }
      </div>
      </div>
          
      </div>
  )
}

export default CommunityDetailsParameters;
