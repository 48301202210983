import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  ThemeStore,
  StorageActions,
} from './theme-provider.interfaces';
import { reducer } from './theme-provider.reducer';

type Reducer<S, A> = (state: S, action: A) => S;

const storeInit: ThemeStore = {
  colors: {},
  dropdownVisible: false,
  isInBackground: false,
  minTimeResolution: '15minute',
  breakpoint: 'lg',
};

export const ThemeContext = createContext<ContextValues>({
  ThemeStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setThemeStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [ThemeStore, dispatch] = useReducer<
    Reducer<ThemeStore, StorageActions>
  >(reducer as any, storeInit);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ThemeContext.Provider
      value={{ ThemeStore, setThemeStore: dispatch }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default StoreProvider as any;
