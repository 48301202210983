import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { IconWrapper } from '@flexo/general';
import styles from './textfield.module.scss';
import { TextfieldProps } from '@flexo/atoms';

const ENV = (import.meta as any).env;

// Use forwardRef to properly pass ref from parent component to input
export const Textfield = forwardRef<HTMLInputElement, TextfieldProps>(
  (
    {
      placeholder,
      label,
      onChange,
      description,
      descriptionFault = null,
      icon,
      iconPosition,
      onIconClick,
      onDescriptionClick,
      variant = 'primary',
      extraDescription,
      containedLabel = false,
      additionalLabel = null,
      additionalLabelStyles = null,
      border = false,
      error = false,
      errorDescription,
      underline = null,
      webError = null,
      disabled = false,
      ...props
    }: TextfieldProps,
    ref // forward ref from parent
  ) => {
    const {
      Textfield__Label,
      Textfield__Input,
      Textfield__Description,
      Textfield__Label__Error,
      Textfield__Input__Web,
    } = styles;
    const [isActiveOrHasValue, setActiveOrHasValue] = useState(false);

    useEffect(() => {
      if (props.value) {
        setActiveOrHasValue(true);
      } else {
        setActiveOrHasValue(false);
      }
    }, [props.value]);

    return (
      <div className={styles.Textfield}>
        {label && !containedLabel && (
          <span
            className={`${
              webError ? Textfield__Label__Error : Textfield__Label
            } heading4 `}
          >
            {label}
          </span>
        )}

        {additionalLabel !== null && (
          <div className={styles.Textfield__AdditionalLabel}>
            <div className={additionalLabelStyles}>{additionalLabel}</div>
          </div>
        )}

        <div
          className={`${styles.Textfield__InputContainer} heading5 ${
            styles[variant]
          } ${border && styles.border} ${
            containedLabel && styles.containedLabel
          } ${error && styles.error}  ${
            disabled && styles.containedLabel__Disabled
          } `}
        >
          {label && containedLabel && (
            <div
              className={`${Textfield__Label} heading4 ${
                isActiveOrHasValue ? styles.activeLabel : ''
              } ${containedLabel && styles.Textfield__Label__Contained} 
               ${disabled && styles.Textfield__Label__Contained__Disabled}
              `}
            >
              {label}
            </div>
          )}
          {/* Make sure the ref is passed here */}
          <input
            key={props.name}
            ref={ref} // Forward the ref properly
            onFocus={() => setActiveOrHasValue(true)}
            onBlur={() => {
              if (!props.value) {
                setActiveOrHasValue(false);
              }
            }}
            className={`${Textfield__Input} ${
              ENV.VITE_APP_NAME === 'admin' ? `${Textfield__Input__Web}` : ''
            } ${containedLabel && styles.Textfield__Input__Contained} 
             ${disabled && styles.Textfield__Input__Contained__Disabled}
            `}
            placeholder={!containedLabel ? placeholder : ''}
            onChange={onChange}
            autoCapitalize="none"
            disabled={disabled}
            {
              ...(props?.onKeyUp
              ? {
                onKeyUp: (props as any).onKeyUp
              }
              : {})
            }
            {...props}
          />

          {icon && (
            <div
              className={styles.Textfield__Icon}
              onClick={() => onIconClick && onIconClick(props.name)}
            >
              <IconWrapper iconName={icon} />
            </div>
          )}

          {underline && (
            <div
              className={
                underline === 'visible'
                  ? styles.Textfield__Underline__Wrapper__Active
                  : styles.Textfield__Underline__Wrapper
              }
            >
              <div className={styles.Textfield__Underline__Content}>
                <div className={styles.Textfield__Underline__Hover}></div>
                <div className={styles.Textfield__Underline__Line}></div>
              </div>
            </div>
          )}

          {/* {underline && (
            <div
              className={
                underline === 'visible'
                  ? styles.Textfield__Underline__Active
                  : styles.Textfield__Underline
              }
            />
          )} */}
        </div>

        <div className={`${styles.Textfield__Description__Container} `}>
          {descriptionFault && (
            <span className={styles.Textfield__Description__Fault}>
              {descriptionFault}
            </span>
          )}
          {description && (
            <span
              className={`${Textfield__Description} paragraph  ${
                styles[`Textfield__Description__${variant}`]
              } ${
                onDescriptionClick && styles.Textfield__Description__Clickable
              } `}
              onClick={onDescriptionClick}
            >
              {description}
            </span>
          )}
          {errorDescription && (
            <span
              className={`${styles.Textfield__Error__Description} detail  ${
                styles[`Textfield__Description__${variant}`]
              }`}
              onClick={onDescriptionClick}
            >
              {errorDescription}
            </span>
          )}

          {extraDescription && (
            <span className={`detail ${styles.Textfield__ExtraDescription}`}>
              {extraDescription}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default Textfield;
