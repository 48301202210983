//save as site: {
// 1day:{}
//1hour:{}
// }
//event driven development
import React, { useState, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  UserContext,
  CalendarContext,
  KpiApiContext,
  KpiDataTypes,
  MetaDataApiContext,
  AlertContext,
  AlertTypes,
  UserTypes,
} from '@flexo/providers';

import {
  FirebaseService,
  formatDate,
  formatEndOfWeek,
  formatStringToDate,
  getFormattedEndOfMonth,
  getFormattedNextDay,
  getLastDayOfYear,
  getTimeGranularity,
  getTimeGranularityOverview,
  httpService,
} from '@flexo/general';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

let round = 0;
let isLoading = false;

export const KpiCommunityResolver = () => {
  const { t } = useTranslation();

  const { kpiApiStore, setKpiApiStore } = useContext(KpiApiContext);
  const { calendarStore } = useContext(CalendarContext);

  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);

  const { setAlertStore } = useContext(AlertContext);
  const { setUserStore } = useContext(UserContext);

  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);

  const { selectedDate, selectedWeek, currentlySelectedDateType } =
    calendarStore;

  const dates = {
    day: {
      dateFrom: formatDate(selectedDate),
      dateTo: getFormattedNextDay(selectedDate),
    },
    week: {
      dateFrom: formatDate(selectedWeek.startDate as Date),
      dateTo: formatEndOfWeek(selectedWeek.endDate as Date),
    },
    month: {
      dateFrom: formatDate(selectedDate),
      dateTo: formatStringToDate(
        getFormattedEndOfMonth(new Date(selectedDate))
      ),
    },
    year: {
      dateFrom: formatDate(selectedDate),
      dateTo: formatStringToDate(getLastDayOfYear(selectedDate)),
    },
  };

  async function init() {
    isLoading = true;
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      setUserStore({
        type: UserTypes.SetUserLogOut,
        payload: {
          value: true,
        },
      });

      return;
    }

    if (metaDataApiStore?.selectedEntityId) {
      setSelectedEntityId(metaDataApiStore.selectedEntityId);
    } else {
      isLoading = false;
      return;
    }

    const [kpiCommunityOverview, kpiComparisonResponse, kpiCommunityDetail] =
      await Promise.all([
        httpService
          .get({
            url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}`,
            params: {
              ...dates[currentlySelectedDateType],
              //add null: null to test the error
              timeGranularity: getTimeGranularityOverview(
                currentlySelectedDateType
              ),
              locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            },
            // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
            idToken,
          })
          .then((res: any) => {
            if (res.status === 200) {
              return res.data;
            } else {
              return null;
            }
          })
          .catch((err: any) => {
            // console.warn(err);

            // setAlertStore({
            //   type: AlertTypes.SetAlert,
            //   payload: {
            //     value: {
            //       type: 'ALERT_UNEXPECTED_ERROR',
            //     },
            //   },
            // });

            return null;
          }),

        httpService
          .post({
            url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/comparison/${metaDataApiStore.selectedEntityId}`,
            data: {
              ...dates[currentlySelectedDateType],
              timeGranularity: getTimeGranularityOverview(
                currentlySelectedDateType
              ),
              locale: ENV?.VITE_APP_LOCALE || 'it-CH',
              siteId: ((metaDataApiStore?.entities as any)?.site || [])?.map(
                (site: any) => site.siteId
              ) || [],
            },
            idToken,
          })
          .then((res: any) => {
            if (res.status === 200) {
              return res.data;
            } else {
              // console.warn({
              //   status: res.status,
              //   message: res.statusText,
              // });
              return null;
            }
          })
          .catch((err: any) => {
            // setAlertStore({
            //   type: AlertTypes.SetAlert,
            //   payload: {
            //     value: {
            //       type: 'ALERT_UNEXPECTED_ERROR',
            //     },
            //   },
            // });

            return null;
          }),

        // New endpoint request
        httpService
          .get({
            url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}`,
            params: {
              ...dates[currentlySelectedDateType],
              timeGranularity: getTimeGranularity(
                currentlySelectedDateType,
                null
              ),
              locale: ENV?.VITE_APP_LOCALE || 'it-IT',
            },
            idToken,
          })
          .then((res: any) => {
            if (res.status === 200) {
              return res.data;
            } else {
              // console.warn({
              //   status: res.status,
              //   message: res.statusText,
              // });
              return null;
            }
          })
          .catch((err: any) => {
            // setAlertStore({
            //   type: AlertTypes.SetAlert,
            //   payload: {
            //     value: {
            //       type: 'ALERT_UNEXPECTED_ERROR',
            //     },
            //   },
            // });

            return null;
          }),
      ]);

    if (kpiCommunityOverview && kpiCommunityDetail && kpiComparisonResponse) {
      setKpiApiStore({
        type: KpiDataTypes.SetKpiDataCommunity,
        payload: {
          value: {
            '1day': kpiCommunityOverview,
            '1hour': kpiCommunityDetail,
            comparison: kpiComparisonResponse,
          },
        },
      });
    }

    //TODO add comparison data https://gateway-dev.flexo.energy/hive/api/kpi/v1_2/siteCommunity/comparison/efb0d026-0d6c-42c3-816d-158472fbc799
    //add it in the commuity overview array

    isLoading = false;
    round++;
  }

  useEffect(() => {
    if (round === 0) {
      init();
    }
  }, [metaDataApiStore?.selectedEntityId]);

  useEffect(() => {
    if (round > 0 && isLoading === false) {
      init();
    }
  }, [calendarStore.selectedDate]);

  return <></>;
};
