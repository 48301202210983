import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  FiltersStore,
  StorageActions,
} from './filters-provider.interfaces';
import { reducer } from './filters-provider.reducer';
import { storeInit } from './initialState';

type Reducer<S, A> = (state: S, action: A) => S;


export let globalSetFiltersStore: any = null;
export let globalFiltersStore: any = null;



export const FiltersContext = createContext<ContextValues>({
  filtersStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFiltersStore: () => {},
});

const FiltersProvider: React.FC<Props> = ({ children }) => {
  const [filtersStore, dispatch] = useReducer<Reducer<FiltersStore, StorageActions>>(
    reducer as any,
    storeInit
  );

  globalSetFiltersStore = dispatch;
  globalFiltersStore = filtersStore
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FiltersContext.Provider value={{ filtersStore, setFiltersStore: dispatch }}>
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersProvider;
