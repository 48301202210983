/* eslint-disable no-case-declarations */
import { storeInit } from './user-provider';
import { UserStore, StorageActions } from './user-provider.interfaces';

export const reducer = (state: UserStore, action: StorageActions) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload.value,
      };
    
      case 'SET_LOGOUT':
        return {
          ...state,
          user: {
            ...state.user,
            logOut: action.payload.value,
          },
        };

    case 'RESET_USER_STORE':
      return storeInit;

    default:
      return state;
  }
};
