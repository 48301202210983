import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import React, { StrictMode } from 'react';


import * as ReactDOM from 'react-dom/client';
import ResizeObserver from 'resize-observer-polyfill';
import App from './app/app';
import * as Sentry from '@sentry/react';

import {
  captureConsoleIntegration,
  httpClientIntegration,
} from '@sentry/integrations';

import { TranslationsProvider } from '@flexo/translations';
import {
  AlertProvider,
  CalendarProvider,
  CommunityViewProvider,
  LoadingProvider,
  ModalProvider,
  SiteViewProvider,
  ThemeProvider,
  UserProvider,
  ModulesProvider,
  LeftDrawerProvider,
  DataProvider,
  RightDrawerProvider,
  MetaDataApiProvider,
  KpiDataProvider,
} from '@flexo/providers';

window.ResizeObserver = ResizeObserver;

const ENV = (import.meta as any).env;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
if (/true/.test(ENV.VITE_APP_SENTRY_ACTIVE)) {
  // console.log('Sentry is active');

  Sentry.init({
    dsn: `${ENV.VITE_APP_SENTRY_DNS}`,
    // Set your release version, such as "getsentry@1.0.0"
    release: `${ENV.VITE_APP_NAME}@${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
    // Set your dist version, such as "1"
    tracesSampleRate: 1.0,
    dist: `${ENV.VITE_APP_VERSION}`,
    integrations: [
      httpClientIntegration(),
      captureConsoleIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.metrics.metricsAggregatorIntegration(),
    ],
    debug: false,
    attachStacktrace: true,
    ignoreErrors: [],
    autoSessionTracking: true,
    enableTracing: true,
    enabled: true,
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// initializeTranslations({ appName: ENV.VITE_APP_NAME, locale: 'it' });

root.render(
  // <StrictMode>
  <TranslationsProvider>
    <UserProvider>
      <ModulesProvider>
        <MetaDataApiProvider>
          <CalendarProvider>
            <KpiDataProvider>
            <App />
            </KpiDataProvider>
          </CalendarProvider>
        </MetaDataApiProvider>
      </ModulesProvider>
    </UserProvider>
  </TranslationsProvider>
  //</StrictMode> 
);
