import styles from './box.module.scss';

/* eslint-disable-next-line */
export interface BoxProps {}

export function Box({ widgetName = 'undefined widget name' }) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to {widgetName}!</h1>
    </div>
  );
}

export default Box;
