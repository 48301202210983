import styles from './search.module.scss';
import { useTranslation } from 'react-i18next';
import {  ModulesContext } from '@flexo/providers';
import { useContext } from 'react';
import {
  CommunitiesAutocomplete,
  CommunityAutocomplete,
  MemberAutocomplete,
  SitesAutocomplete,
} from './search.utils';

/* eslint-disable-next-line */

function getSearchComponent(props: any) {
  switch (props?.context) {
    case 'communities':
      return <CommunitiesAutocomplete />;
      break;
    case 'community':
    case 'community-energy':
    case 'community-economics':
      return <CommunityAutocomplete />;
      break;
    case 'site-energy':
    case 'site-economics':
    case 'site':
      return <MemberAutocomplete />;
      break;
    case 'member':
      return <MemberAutocomplete />;
      break;
    case 'household':
      return <SitesAutocomplete />;
    default:
      return <></>;
      break;
  }
}

export function Search() {
  const { t } = useTranslation();
  const { modulesStore } = useContext(ModulesContext);

  const activeModuleName = modulesStore?.modules?.find(
    (module: any) => module.visible === true
  )?.name;

  return (
    <div className={styles.SearchWrapper}>
      <div className={styles.Search}>
        {getSearchComponent({
          context: activeModuleName,
        })}
      </div>
    </div>
  );
}

export default Search;
