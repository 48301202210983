import { useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Drawer } from '@mui/material';
import { IRightDrawerTypes, RightDrawerContext } from '@flexo/providers';

import { useOnClickOutside } from '@flexo/hooks';
import { UpdateCredentialsForm } from '../update-credentials-form/update-credentials-form';

import styles from './right-drawer.module.scss';
import TotalEstimatedBenefitsDrawerContent from './drawer-contents/community-total-estimated-benefits';
import SiteTotalEstimatedBenefitsDrawerContent from './drawer-contents/site-total-estimated-benefits';
import { EsriMapComponent } from '@flexo/atoms';

export const RightDrawer = () => {
  
  const { RightDrawerStore, setRightDrawerStore } = useContext(RightDrawerContext)
  const drawerRef = useRef(null);

  function isChildOf(event) {

    const parentDiv = document.getElementById('right-drawer')
    let targetElement = event.target; // L'elemento che è stato cliccato

    // Itera attraverso i genitori dell'elemento target fino a raggiungere il body
    while (targetElement !== document.body) {
        if (targetElement === parentDiv) {
            return true; // Il target è figlio del parentDiv
        }
        targetElement = targetElement.parentNode; // Vai al genitore dell'elemento target
    }

    return false; // Il target non è figlio del parentDiv
  }
  
  function checkBackdropClose(ev: any) {

    if ( RightDrawerStore.rightDrawer.show === true && ( ev?.target?.className && ev.target.className.includes( 'MuiBackdrop-root' ) === true ) && isChildOf( ev ) === true ) {
      setRightDrawerStore({
        type: IRightDrawerTypes.HideDrawer,
      })
    }
  }

  useOnClickOutside(drawerRef, (ev: any) => {
    
    if ( isChildOf(ev) === false) { return }

    if (RightDrawerStore.rightDrawer.show === true) {

      setRightDrawerStore({
        type: IRightDrawerTypes.HideDrawer,
      })
    }
  }
  );

  const getDrawerContent = () => {

    const requiredContent = RightDrawerStore?.rightDrawer?.type || '';

    let content = <></>;

    switch (requiredContent) {
      case 'CREDENTIALS_UPDATE_FORM':
        content = <UpdateCredentialsForm />;
        break;
      case 'COMMUNITY_TOTAL_ESTIMATED_BENEFITS':
        content = <TotalEstimatedBenefitsDrawerContent />;
        break;
      case 'SITE_TOTAL_ESTIMATED_BENEFITS':
        content = <SiteTotalEstimatedBenefitsDrawerContent />;
        break;
      case 'EXPANDED_MAP':
        content = <EsriMapComponent {...{
          wrapper: 'drawer',
          env: 'web'
        }} />;
        break;
      default:
        content = <></>;
        break;
      
    }

    return content

  };
  
  return (
    <div key={`RightDrawer_${uuidv4()}`} ref={drawerRef} id="right-drawer" onClick={(ev: any) => checkBackdropClose(ev)  }>

      <Drawer
        open={RightDrawerStore.rightDrawer.show}
        anchor={'right'} disablePortal={true}
        disableEnforceFocus
        disableRestoreFocus
      >
      {/* <Drawer open={RightDrawerStore.rightDrawer.show} anchor={'right'}> */}
        <div className={ RightDrawerStore?.rightDrawer?.type === 'EXPANDED_MAP' ? styles.RightDrawer__Map : styles.RightDrawer } >
          
          { getDrawerContent()}

        </div>
      </Drawer>
    </div>
  );
};

export default RightDrawer;
