import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  UserStore,
  StorageActions,
} from './user-provider.interfaces';
import { reducer } from './user-provider.reducer';
import { FirebaseService } from '@flexo/general'; // Update the import path as needed



type Reducer<S, A> = (state: S, action: A) => S;

const firebaseService = new FirebaseService();
firebaseService.setupTokenChangeListener();

export const storeInit: any = {
  user: {},
  firebaseService: firebaseService, // Add this line
  email: '',
  OOBcode: ''
};

export const UserContext = createContext<ContextValues>({
  userStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [userStore, dispatch] = useReducer<Reducer<UserStore, StorageActions>>(
    reducer as any,
    storeInit
  );
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UserContext.Provider value={{ userStore, setUserStore: dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export default StoreProvider;
