import { InformationCardProps, Shimmer } from '@flexo/atoms';
import styles from './information-card.module.scss';
import { useContext, useEffect, useState } from 'react';
import { AlertContext, SiteViewContext } from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';

export function InformationCardSkeleton(props) {
  const {
    InformationCard__Icon__Loading,
    InformationCard__Value,
    InformationCard__Label__Loading,
    InformationCard__Detail,
  } = styles;
  const { siteViewStore } = useContext(SiteViewContext);
  const { responseError } = siteViewStore;
  const { alertStore } = useContext(AlertContext);

  const { icon, value, title, detail, id, view } = props;

  const [networkConnected, setNetworkConnected] = useState(true);

  const { t } = useTranslation();

  const getCardDetailSite = () => {
    switch (id) {
      case 'savings':
        return (
          <div>
            <div style={{ marginBottom: '.5em' }}>
              <strong style={{ fontWeight: '1000' }}>
                {t('widgets.sharedEnergy.label')}
              </strong>
              <span> € -,-- </span>
            </div>
            {/* <br /> */}
            <strong style={{ fontWeight: '1000' }}>
              {` ${t('widgets.sharedEnergy.label_line_2')} `}
            </strong>
            <span>€ -,--</span>
          </div>
        );
      case 'pollution':
        return (
          <div>
            <span>
              {/* FIX ME: add the field in the widgets constants */}
              {t('widgets.pollution.label')}
              <strong style={{ fontWeight: 'bolder' }}>- alberi </strong>
              {t('widgets.pollution.label_line_2')}
            </span>
          </div>
        );
      case 'selfSufficiency':
        return (
          <div>
            <strong style={{ fontWeight: '1000' }}>- kWh </strong>
            <span>{t('widgets.selfSufficiency.label')}</span>
          </div>
        );
      case 'sharedEnergy':
        return (
          <div>
            <div style={{ marginBottom: '.5em' }}>
              <strong style={{ fontWeight: '1000' }}>
                {t('widgets.sharedEnergy.label')}
              </strong>
              <span> - kWh</span>
            </div>
            <strong style={{ fontWeight: '1000' }}>
              {`  ${t('widgets.sharedEnergy.label_line_2')} `}
            </strong>
            <span> - kWh</span>
          </div>
        );
      default:
        return '';
    }
  };

  const getCardDetailCommunity = () => {
    switch (id) {
      case 'pollution':
        return (
          <div>
            <span>
              {t('widgets.pollution.label')}
              <strong style={{ fontWeight: 'bolder' }}> - alberi </strong>
              {t('widgets.pollution.label_line_2')}
            </span>
          </div>
        );
      case 'selfSufficiency':
        return (
          <div>
            <span>{t(props.label)}</span>
          </div>
        );
      case 'savingsCard':
        return (
          <div>
            <span>{t(props.label)}</span>
          </div>
        );
    }
  };

  const getCardUnit = () => {
    switch (id) {
      case 'pollution':
        return 'kg';
      case 'selfSufficiency':
        return '%';
      case 'savings':
        return '€';
      case 'savingsCard':
        return '€';
      default:
        return '';
    }
  };
  useEffect(() => {
    // Check if the alert type and title matches the specific alert
    if (
      alertStore.alert.type === 'warning' &&
      (alertStore.alert as any).title === t('errors.no_connection.title')
    ) {
      setNetworkConnected(false);
    }
  }, [alertStore, t]);
  return (
    <div
      className={` ${
        props.gradientBorder && styles.InformationCard__GradientBorder
      }`}
      style={{ position: 'relative' }}
      key={Math.floor(Math.random() * 100)}
    >
      {' '}
      <div className={styles.InformationCard}>
        {icon && (
          <span className={InformationCard__Icon__Loading}>
            {<IconWrapper iconName={icon} />}
          </span>
        )}{' '}
        <span className={`${InformationCard__Value} featuredData`}>
          {` --  ${getCardUnit()}`}{' '}
          {networkConnected && props.dataInTimeSpan === true && <Shimmer />}
        </span>
        <span className={`${styles.InformationCard__Label} heading4`}>
          {t(title)}
          {/* <IconWrapper iconName="chevron-right" /> */}
        </span>
        <span className={`${InformationCard__Detail} detail`}>
          {view === 'site' ? getCardDetailSite() : getCardDetailCommunity()}
        </span>
      </div>
    </div>
  );
}

export default InformationCardSkeleton;
