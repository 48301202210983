import styles from './shimmer.module.scss';

/* eslint-disable-next-line */
export interface ShimmerProps {}

export function Shimmer(props: ShimmerProps) {
  return (
    <div className={styles.ShimmerWrapper}>
      <div className={styles.Shimmer}></div>
    </div>
  );
}

export default Shimmer;
