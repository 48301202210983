import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  RightDrawerStore,
  StorageActions,
} from './right-drawer.interfaces';
import { reducer } from './right-drawer-provider.reducer';
import { storeInit } from './initialState';

type Reducer<S, A> = (state: S, action: A) => S;



export const RightDrawerContext = createContext<ContextValues>({
  RightDrawerStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRightDrawerStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [RightDrawerStore, dispatch] = useReducer<Reducer<RightDrawerStore, StorageActions>>(
    reducer as any,
    storeInit
  );
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <RightDrawerContext.Provider value={{ RightDrawerStore, setRightDrawerStore: dispatch }}>
      {children}
    </RightDrawerContext.Provider>
  );
};

export default StoreProvider;
