import { AlertStore } from './alert-provider.interfaces';

export const storeInit: any = {
  alert: {
    visible: false,
    type: '',
    message: '',
    title: '',
    show: false,
    input: {
      label: '',
      type: '',
      value: '',
      placeholder: '',
      name: '',
      description: '',
      extraDescription:'',
    },
    footer: {
      buttons: [
        {
          label: '',
          type: '',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick: () => {},
        },
        {
          label: '',
          type: '',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick: () => {},
        },
      ],
    },
    customStyles: {},
    variant: 'primary'
  },
};
