import React, { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface ICommonNavigation {
  module: string;
  id?: string;
  parentID?: string;
  startDate?: string;
  endDate?: string;
  calendarDisplay?: string;
}

export interface DataProvider {
  data: any;
  navigation: ICommonNavigation | null;
}

export type DataStore = DataProvider;

export enum DataStoreTypes {
  SetCommonData = 'SET_COMMON_DATA',
  SetCommonNavigation = 'SET_COMMON_NAVIGATION',
  ResetCommon = 'RESET_COMMON',
  ResetCommonData = 'RESET_COMMON_DATA',
  ResetCommonNavigation = 'RESET_COMMON_NAVIGATION',
  ResetCommonNavigationId = 'RESET_COMMON_NAVIGATION_ID',
  setCommonNavigationId = 'SET_COMMON_NAVIGATION_ID',
  setCommonNavigationModule = 'SET_COMMON_NAVIGATION_MODULE',
  setCommonNavigationDates = 'SET_COMMON_NAVIGATION_DATES',
  setCommonNavigationCalendarDisplay = 'SET_COMMON_NAVIGATION_CALENDAR_DISPLAY',
  setCommmonEntities = 'SET_COMMON_ENTITIES',
  SetSelectedSite = 'SET_SELECTED_SITE', 
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [DataStoreTypes.SetCommonData]: {
    value: any;
  };
  [DataStoreTypes.SetCommonNavigation]: {
    value: any;
  };
  [DataStoreTypes.ResetCommon];
  [DataStoreTypes.ResetCommonData];
  [DataStoreTypes.ResetCommonNavigation];
  [DataStoreTypes.ResetCommonNavigationId]: {
    value: string,
  }
  [DataStoreTypes.setCommonNavigationId]: {
    value: any,
  }
  [DataStoreTypes.setCommonNavigationModule]: {
    value: string,
  }
  [DataStoreTypes.setCommonNavigationDates]: {
    value: {
      startDate: string,
      endDate: string,
    },
  }
  [DataStoreTypes.setCommonNavigationCalendarDisplay]: {
    value: string,
  }
  [DataStoreTypes.setCommmonEntities]: {
    value: any,
  }
  [DataStoreTypes.SetSelectedSite]: {
    value: any;
  };
};

export interface ContextValues {
  dataStore: DataStore;
  setDataStore: Dispatch<StorageActions>;
}

export interface Props {
  children: any;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
