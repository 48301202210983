
import React, { useContext, useEffect, useState, } from 'react';

import styles from './header-web.module.scss';
import { DataContext, MetaDataApiContext, ModulesContext, ModulesTypes } from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import {  BreadCrumbsDropDown, ProfileButton, Search, navigateToCommunity, navigateToMember, navigateToSite } from '@flexo/atoms';

import './header-web.module.scss';
import { Autocomplete, Select } from '@mui/material';
import { Calendar } from '@flexo/molecules';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__BreadCrumb,
  Header__Web__Center
} = styles;

function SiteOverviewNavigation(props: any) {
  
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null, breadCrumbs = null } = props;
  const { t } = useTranslation()

  const communities = (metaDataApiStore?.entities as any)?.community || [];

  function getSiteCommunity() {

    const siteCommunityID = metaDataApiStore?.selectedEntity?.communityId || (metaDataApiStore?.entities as any)?.site?.find( (site: any) => site?.siteId === metaDataApiStore?.selectedEntityId )?.communityId || 'missing_id';
    const foundCommunity = communities?.find((community: any) => community?.communityId === siteCommunityID ) || null;
    
    if ( !foundCommunity) {
      return communities?.[0];
    } else {
      return foundCommunity;
    }

  }

  function getSiteCommunityName() {
    const foundCommunity = getSiteCommunity();
    return (foundCommunity?.communityName) || communities?.[0]?.communityName || 'Community name not found';
  }

  function getSiteMembers() {
    const membersList = ([
      ...((metaDataApiStore?.sortedEntities as any)?.communities || []).reduce((prev: any, next: any) => ([...prev, ...(next?.members || [])]), []),
      ...((metaDataApiStore as any)?.unassignedMembers || [])
    ])
      ?.filter((member: any) => member?.sites?.some((site: any) => site?.siteId === metaDataApiStore?.selectedEntityId));
    
    return membersList[0];
    
  }

  function getSiteName(): any {
    let site: any = metaDataApiStore?.selectedEntity || null;

    if (!site) {
      try {
        site = ((metaDataApiStore?.entities as any)?.site )?.find((s: any) => s?.siteId === metaDataApiStore?.selectedEntityId);
      } catch (e) {
        // console.warn( 'no site found' )
      }
    }

    return site?.pod || site?.name || site?.siteName || 'Site name not found';
  }

  function goToCommunities() {
    setModulesStore({
      type: ModulesTypes.ActivateCommunitiesModule,
    })
  }

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>
        
        <div className={Navigation}>
          <label className={`${Navigation__BreadCrumb} ${styles.clickable}`} onClick={() => goToCommunities()}>{t(`widgets.header.communities`)}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <label className={ `${styles.clickable}`} onClick={() => navigateToCommunity( getSiteCommunity(), setMetaDataApiStore, setModulesStore)} >{ getSiteCommunityName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <label className={`${Navigation__BreadCrumb} ${styles.clickable}`} onClick={() => navigateToMember( getSiteMembers(), setMetaDataApiStore, setModulesStore ) }>{ getSiteMembers()?.memberFirstName } { getSiteMembers()?.memberLastName }</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          {/* <strong>{getSiteName()}</strong> */}
          {/* <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span> */}
          {/* <Select
            className='site-navigation-select'
            id="site-navigation-select"
            MenuProps={{
              className: 'site-navigation-select-menu'
            }}
            // open={true}
            defaultValue={metaDataApiStore?.entityType || null}
            
          >
            <option disabled={ metaDataApiStore?.entityType === 'site-energy' } onClick={() => {
              navigateToSite( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'energy' )
            }} value="site-energy">Energy</option>
            <option disabled={ metaDataApiStore?.entityType === 'site-economics' } onClick={() => {
              navigateToSite( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'economics' )
            }} value="site-economics">Economics</option>
          </Select> */}
          {/* <span>{ (metaDataApiStore?.selectedEntity as any)?.type || 'undefined type' }</span> */}

          
      {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          }
      
    </div>


      </div>

      <div className={Header__Web__Center}>
          <Calendar />
        </div>

      <div className={Header__Web__Right}>
        {
          download && <>
            <IconWrapper iconName={'chevron-encircled'} />
          </>
        }
        {
          searchBar &&
          <Search />
        }
        {
       profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(SiteOverviewNavigation);
