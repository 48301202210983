import { formatDate, IconWrapper } from '@flexo/general'
import styles from './right-drawer-content.module.scss'
import { useContext } from 'react'
import { CalendarContext, IRightDrawerTypes, KpiApiContext, MetaDataApiContext, RightDrawerContext } from '@flexo/providers'
import { useTranslation } from 'react-i18next'
import { timeFormat } from 'd3-time-format'

export default function TotalEstimatedBenefitsDrawerContent() {

  const { t } = useTranslation();
  const { setRightDrawerStore } = useContext(RightDrawerContext);
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const { calendarStore } = useContext(CalendarContext);
  const { kpiApiStore } = useContext(KpiApiContext);

  const communityData = kpiApiStore?.community?.['1day']?.[0]?.kpi || [];

  function getKpi(kpiName: string) {
    const kpi = communityData.find((kpi) => kpi.name === kpiName) || null;
    if (!kpi) return '';
    return `${kpi.unit} ${kpi.value}`;
  }



  function closeDrawer() {
    setRightDrawerStore({
      type: IRightDrawerTypes.ResetDrawer,
      payload: {} as any
    })
  }

  const getDate = () => {
    const formatDate = timeFormat('%d/%m/%Y');
    const formatWeek = timeFormat('%d/%m/%y');
    const formatMonth = timeFormat('%B %Y');
    const formatYear = timeFormat('%Y');
    let result = '';
    switch (calendarStore.currentlySelectedDateType) {
      case 'day':
        if (
          formatDate(new Date(calendarStore.selectedDate)) ===
          formatDate(new Date())
        )
          result = t('widgets.calendar.today');
        else result = formatDate(new Date(calendarStore.selectedDate));
        break;

      case 'week':
        result = calendarStore.selectedWeek.startDate
          ? `${formatWeek(
              new Date(calendarStore.selectedWeek.startDate)
            )} - ${formatWeek(
              new Date(calendarStore.selectedWeek.endDate as any)
            )}`
          : 'Select Week';
        break;

      case 'month':
        result = formatMonth(new Date(calendarStore.selectedDate));
        break;

      case 'year':
        result = formatYear(new Date(calendarStore.selectedDate));
        break;

      default:
        if (
          formatDate(new Date(calendarStore.selectedDate)) ===
          formatDate(new Date())
        )
          result = t('widgets.calendar.yesterday');
        else result = formatDate(new Date(calendarStore.selectedDate));
        break;
    }
    return result;
  };

  return (
    <div id="TotalEstimatedBenefitsDrawerContent" className={styles.RightDrawerContent__Wrapper}>
      <div className={styles.RightDrawerContent__Title}>
        <p className={ styles.RightDrawerContent__Paragraph}>
          { t( 'drawers.communityTotlaEstimatedBenefits.title' ) }
        </p>
        <div onClick={() => closeDrawer()}>
          <IconWrapper iconName="cross"  />
        </div>
      </div>
      <div className={styles.RightDrawerContent__Subtitle}>
        <p className={styles.RightDrawerContent__Paragraph}>
          {(metaDataApiStore?.selectedEntity as any)?.communityName} <label>{ getDate() }</label>
        </p>
      </div>
      <div className={styles.RightDrawerContent__Body}>
        <p className={styles.RightDrawerContent__Body__Value}>
          { getKpi('total_benefits')}
        </p>
        <p className={styles.RightDrawerContent__Body__Description}>
          { t( 'drawers.communityTotlaEstimatedBenefits.description' ) }
        </p>
        <p className={styles.RightDrawerContent__Body__List__Title}>
          { getKpi('revenues') } { t( 'drawers.communityTotlaEstimatedBenefits.list_title' ) }
        </p>
        <ul className={styles.RightDrawerContent__Body__List}>
          <li className={styles.RightDrawerContent__Body__List__Item}>
            <p className={ styles.RightDrawerContent__Paragraph}>{t('drawers.communityTotlaEstimatedBenefits.list_item_1')}</p>
            <p className={styles.RightDrawerContent__Paragraph}>
            { getKpi('charge_reduction') }
            </p>
          </li>
          <li className={styles.RightDrawerContent__Body__List__Item}>
            <p className={ styles.RightDrawerContent__Paragraph}>{t('drawers.communityTotlaEstimatedBenefits.list_item_2')}</p>
            <p className={styles.RightDrawerContent__Paragraph}>
            { getKpi('public_incentive') }
            </p>
          </li>
          <li className={styles.RightDrawerContent__Body__List__Item}>
            <p className={ styles.RightDrawerContent__Paragraph}>{t('drawers.communityTotlaEstimatedBenefits.list_item_3')}</p>
            <p className={styles.RightDrawerContent__Paragraph}>
            { getKpi('direct_compensation') }
            </p>
          </li>
        </ul>
      </div>
    </div>
  )

}