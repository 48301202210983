/* eslint-disable no-case-declarations */
import { setParams, setUrl } from '../utils';
import { storeInit } from './meta-data-api-provider';
import {
  metaDataApiStore,
  StorageActions,
} from './meta-data-api-provider.interfaces';

export const reducer = (state: metaDataApiStore, action: StorageActions) => {
  switch (action.type) {
    case 'SET_SELECTED_ENTITY':
      
      setUrl(`${action.payload.value.entityType}/${action.payload.value.id}`);

      return {
        ...state,
        selectedEntity: action.payload.value.selectedEntity,
        selectedEntityId: action.payload.value.id,
        entityType: action.payload.value.entityType,
      };
    case 'SET_SELECTED_ENTITY_CONTENT':

      
      
      return {
        ...state,
        selectedEntity: action.payload.value,
      };
    case 'SET_ENTITY_TYPE':

    setUrl(`${action.payload.value}`);
      
      return {
        ...state,
        entityType: action.payload.value,
      };
    case 'SET_SET_SELECTED_ENTITY_ID':

      setUrl(`${state.entityType}/${action.payload.value}`);

      return {
        ...state,
        selectedEntityId: action.payload.value,
      };
      case 'SET_SET_SELECTED_ENTITY_NAME':


      return {
        ...state,
        selectedEntityName: action.payload.value,
      };
    case 'SET_ENTITIES':
      return {
        ...state,
        entities: action.payload.value,
      };
    case 'SET_COMMUNITIES':
      return {
        ...state,
        communities: action.payload.value,
      };
    case 'SET_MEMBERS':
      return {
        ...state,
        members: action.payload.value,
      };
    case 'SET_MEMBERS_OVERVIEW':
      return {
        ...state,
        membersOverview: action.payload.value,
      };
    case 'SET_COMMUNITIES_OVERVIEW':
      return {
        ...state,
        communitiesOverview: action.payload.value,
      };
    case 'SET_SORTED_ENTITIES':
      return {
        ...state,
        sortedEntities: action.payload.value,
      };
    case 'SET_USEFUL_DATA':
      return {
        ...state,
        usefulData: action.payload.value,
      };
    case 'RESET_METADATA':
      return {
        ...storeInit
      };
    default:
      return state;
  }
};
