/* eslint-disable no-case-declarations */
import { useContext, useEffect, useState } from 'react';
import { IconWrapper } from '@flexo/general';
import { timeFormat } from 'd3-time-format';
import { CalendarCustomHeaderProps } from '@flexo/molecules';
import { DateTime } from 'luxon';
import styles from './custom-header.module.scss';
import {
  CalendarContext,
  CalendarTypes,
  CommunityViewContext,
  SiteViewContext,
} from '@flexo/providers';
import { ICalendarDisplay } from '@flexo/general';
import { useTranslation } from 'react-i18next';

import * as d3 from 'd3';

const CalendarCustomHeader = ({
  shownDate,
  setShownDate,
  changeYear,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
  customInteraction,
  minDate,
  getSelectedDate,
}: CalendarCustomHeaderProps) => {
  const {
    CalendarCustomHeader__Actions,
    CalendarCustomHeader__Action,
    CalendarCustomHeader__DateActions,
    CalendarCustomHeader__DateActions__Date,
    CalendarCustomHeader__DateActions__Button,
  } = styles;

  const { calendarStore, setCalendarStore } = useContext(CalendarContext);
  const { siteViewStore } = useContext(SiteViewContext);
  const { communityViewStore } = useContext(CommunityViewContext);
  const {
    calendarDisplay,
    selectedDate,
    showCustomInteraction,
    currentlySelectedDateType,
  } = calendarStore;
  const { t, i18n } = useTranslation();

  const actions: ICalendarDisplay[] = ['day', 'month', 'year'];
  let formatMonth = timeFormat('%B %Y');
  let formatYear = timeFormat('%Y');

  const [month, setMonth] = useState({
    name: formatMonth(shownDate as Date),
    date: shownDate as Date,
  });

  const [year, setYear] = useState({
    name: formatYear(shownDate as Date),
    date: shownDate as Date,
  });
  const [decade, setDecade] = useState({
    start: '',
    end: '',
  });

  const handleChangeHeaderValue = (type: 'increase' | 'decrease') => {
    const nextMonth = DateTime.fromJSDate(month.date).plus({ months: 1 });
    const previousMonth = DateTime.fromJSDate(month.date).minus({ months: 1 });
    const previousYear = DateTime.fromJSDate(year.date)
      .minus({ years: 1 })
      .toJSDate();
    const nextYear = DateTime.fromJSDate(year.date)
      .plus({ years: 1 })
      .toJSDate();

    switch (calendarDisplay) {
      case 'day':
      case 'week':
        const newMonth = type === 'increase' ? nextMonth : previousMonth;
        setMonth({
          ...month,
          name: formatMonth(newMonth.toJSDate()),
          date: newMonth.toJSDate(),
        });
        if (type === 'increase') increaseMonth();
        else decreaseMonth();
        break;
      case 'month':
        const newYear = type === 'increase' ? nextYear : previousYear;
        setYear({ ...year, name: formatYear(newYear), date: newYear });
        if (type === 'increase') increaseYear();
        else decreaseYear();
        break;
      case 'year':
        const newDecadeStart =
          type === 'increase'
            ? Number(decade.start) + 12
            : Number(decade.start) - 12;
        const newDecadeEnd = newDecadeStart + 11;

        for (let i = newDecadeStart - 12; i <= newDecadeEnd - 11; i++) {
          changeYear(i);
        }
        setDecade({
          ...decade,
          start: String(newDecadeStart),
          end: String(newDecadeStart + 11),
        });

        break;
      default:
        break;
    }
  };

  const getDecade = () => {
    const dateYear = (shownDate as Date).getFullYear() - 1;
    let rangeStart = dateYear;
    let count = 0;
    while (!Number.isInteger((dateYear - count) / 12)) {
      count++;
    }
    rangeStart = dateYear - count + 1;
    setDecade({
      ...decade,
      start: String(rangeStart),
      end: String(rangeStart + 11),
    });
  };

  const getFormattedDate = () => {
    // if (customInteraction) {
    //   if (showCustomInteraction.month) {
    //     return year.name;
    //   }
    //   if (showCustomInteraction.week || showCustomInteraction.day) {
    //     return month.name;
    //   }
    //   return `${decade.start} - ${decade.end}`;
    // }
    switch (calendarDisplay) {
      case 'day':
      case 'week':
        return month.name;
      case 'month':
        return year.name;
      case 'year':
        return `${decade.start} - ${decade.end}`;
      default:
        return;
    }
  };

  const getCustomInteraction = () => {
    // console.group('getCustomInteraction', customInteraction);
    // console.log('calendarDisplay', calendarDisplay);
    // console.groupEnd()

    setCalendarStore({
      type: CalendarTypes.SetCustomInteraction,
      payload: {
        value: calendarDisplay,
      },
    });

    setCalendarStore({
      type: CalendarTypes.SetShowCustomInteraction,
      payload: {
        value: {
          day: false,
          week: false,
          month: false,
        },
      },
    });

    // if (customInteraction) {
    //   setCalendarStore({
    //     type: CalendarTypes.SetShowCustomInteraction,
    //     payload: {
    //       value: {
    //         day: false,
    //         week: false,
    //         month: false,
    //       },
    //     },
    //   });
    // } else {

    //   setCalendarStore({
    //     type: CalendarTypes.SetCustomInteraction,
    //     payload: {
    //       value: 'year',
    //     },
    //   });

    // }
  };
  const getDisabled = () => {
    const currentDate = DateTime.fromJSDate(new Date());

    switch (calendarDisplay) {
      case 'day':
      case 'week':
        const currentMonth = currentDate.month;
        const currentYear = currentDate.year;
        const monthYearMatch =
          DateTime.fromJSDate(month.date).month === currentMonth &&
          DateTime.fromJSDate(month.date).year === currentYear;
        return monthYearMatch;
      case 'month':
        const yearMatch =
          DateTime.fromJSDate(year.date).year === currentDate.year;
        return yearMatch;
      case 'year':
        const yearEnd = Number(decade.end);
        const yearEndMatch = yearEnd > currentDate.year;
        return calendarDisplay === 'year' && yearEndMatch;
      default:
        return false;
    }
  };

  const changeCalendarDisplay = (action: ICalendarDisplay) => {
    setCalendarStore({
      type: CalendarTypes.SetButtonSwitch,
      payload: {
        value: false,
      },
    });
    setCalendarStore({
      type: CalendarTypes.SetPreviousCalendarDisplay,
      payload: {
        value: calendarDisplay,
      },
    });
    setCalendarStore({
      type: CalendarTypes.SetCalendarDisplay,
      payload: {
        value: action,
      },
    });
    setCalendarStore({
      type: CalendarTypes.SetShowCustomInteraction,
      payload: {
        value: {
          day: false,
          week: false,
          month: false,
        },
      },
    });
    if (customInteraction?.length && customInteraction !== undefined)
      setCalendarStore({
        type: CalendarTypes.SetCustomInteraction,
        payload: {
          value: undefined,
        },
      });
    // if (calendarDisplay !== 'week' && currentlySelectedDateType !== 'week') {
    //   setCalendarStore({
    //     type: CalendarTypes.SetSelectedWeek,
    //     payload: {
    //       value: {
    //         startDate: null,
    //         endDate: null,
    //       },
    //     },
    //   });
    // }
  };

  useEffect(() => {
    getDecade();
  }, []);

  // TODO: Better implementation for custom header translations
  useEffect(() => {
    const setTimeFormatting = async () => {
      let lang = 'en';
      try {
        lang = i18n.language.split('_')[1];
        d3.json(
          `https://cdn.jsdelivr.net/npm/d3-time-format@3/locale/${lang}-${lang.toUpperCase()}.json`
        ).then((locale) => {
          d3.timeFormatDefaultLocale(locale);
          d3.timeFormat('%c');
          formatMonth = timeFormat('%B %Y');
          formatYear = timeFormat('%Y');
        });
      } catch (err: any) {
        // console.warn('Error getting language from i18n', err);
      }
    };
    setTimeFormatting();
  }, []);

  const getPreviosDisabled = () => {
    const currentDate = DateTime.fromJSDate(minDate as Date);

    switch (calendarDisplay) {
      case 'day':
      case 'week':
        const currentMonth = currentDate.month;
        const currentYear = currentDate.year;
        const monthYearMatch =
          DateTime.fromJSDate(month.date).month === currentMonth &&
          DateTime.fromJSDate(month.date).year === currentYear;

        return monthYearMatch;
      case 'month':
        const yearMatch =
          DateTime.fromJSDate(year.date).year === currentDate.year;
        return yearMatch;
      case 'year':
        const yearEnd = Number(decade.end);
        const yearEndMatch = yearEnd > currentDate.year;
        return calendarDisplay === 'year' && yearEndMatch;
      default:
        return false;
    }
  };
  return (
    <div className={styles.CalendarCustomHeader}>
      {' '}
      <div className={`${CalendarCustomHeader__Actions} heading5`}>
        {Object.values(actions).map((action) => (
          <span
            key={action}
            className={`${CalendarCustomHeader__Action} ${
              action === calendarDisplay && styles.active
            }`}
            onClick={() => changeCalendarDisplay(action)}
          >
            {t(`widgets.calendar.actions.${action}`)}
          </span>
        ))}
      </div>
      <div className={CalendarCustomHeader__DateActions}>
        <button
          className={CalendarCustomHeader__DateActions__Button}
          onClick={() => handleChangeHeaderValue('decrease')}
          disabled={getPreviosDisabled()}
        >
          <IconWrapper iconName="chevron-left" />
        </button>
        <div
          className={`${CalendarCustomHeader__DateActions__Date} heading2`}
          onClick={getCustomInteraction}
        >
          {getFormattedDate()}
        </div>
        <button
          className={CalendarCustomHeader__DateActions__Button}
          onClick={() => handleChangeHeaderValue('increase')}
          disabled={getDisabled()}
        >
          <IconWrapper iconName="chevron-right" />
        </button>
      </div>
    </div>
  );
};

export default CalendarCustomHeader;
