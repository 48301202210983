/* eslint-disable no-case-declarations */
import { ThemeStore, StorageActions } from './theme-provider.interfaces';

export const reducer = (state: ThemeStore, action: StorageActions) => {
  switch (action.type) {
    case 'SET_COLORS':
      return {
        ...state,
        colors: action.payload.value,
      };
    case 'SET_BACKGROUND_STATE':
      return {
        ...state,
        isInBackground: action.payload.value,
      };
    case 'SET_MIN_TIME_RESOLUTION':
      return {
        ...state,
        minTimeResolution: action.payload.value,
      };
    case 'TRACE_DROPDOWN':
      return {
        ...state,
        dropdownVisible: action.payload.value,
      };
      break;
    case 'SET_BRAKEPOINT':
      return {
        ...state,
        breakpoint: action.payload.value,
      };
      break;
    default:
      return state;
  }
};
