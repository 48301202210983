import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  kpiApiStore,
  StorageActions,
} from './kpi-api-provider.interfaces';
import { reducer } from './kpi-api.reducer';

type Reducer<S, A> = (state: S, action: A) => S;

export const storeInit: kpiApiStore = {
  site: {
    '1day': [],
  },
  community: {
    '1day': [],
  },
  custom: {
    '1day': [],
  },
  member: {
    '1day': [],
  },
};

export const KpiApiContext = createContext<ContextValues>({
  kpiApiStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setKpiApiStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [kpiApiStore, dispatch] = useReducer<
    Reducer<kpiApiStore, StorageActions>
  >(reducer as any, storeInit);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <KpiApiContext.Provider value={{ kpiApiStore, setKpiApiStore: dispatch }}>
      {children}
    </KpiApiContext.Provider>
  );
};

export default StoreProvider;
