
import { useContext, useState } from 'react';
import styles from './../alert.module.scss';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import { AlertContext, AlertTypes } from '@flexo/providers';

export function AlertBannerConfirmChages() {

  const [showAlert, setShowAlert] = useState(false);

  const { t } = useTranslation();
  const { setAlertStore } = useContext(AlertContext);

  const alertContent = t('alerts.unexpected_error', { returnObjects: true }) as any;
  const bannerContent = t('banners.confirm_changes', { returnObjects: true }) as any;

  return (
    <div id="alert-banner-confirm-changes" className={styles.Alert__Banner__Confirm__Changes}>

      <div className={styles.Alert__Confirm__Changes} style={{ opacity: showAlert ? 1 : 0 }}>
        <div className={styles.Alert__Title} >
          <div>
            <IconWrapper iconName="alert" />
            {alertContent.title}
          </div>
        </div>
        <div className={styles.Alert__Content} >
          {alertContent.message}
        </div>
      </div>

      <div className={styles.Banner__Confirm__Changes}>
        <div className={styles.Banner__Title} >
          {bannerContent.title}
        </div>
        <div className={styles.Banner__Content} >
          {bannerContent.description}
        </div>
        <div className={styles.Banner__Footer}>
          <div onClick={() => setAlertStore({ type: AlertTypes.ResetAlert })}>
            LOL no!
          </div>
          <div onClick={ () => setShowAlert(!showAlert)}>
            do the dangerous thing
          </div>
          
        </div>
      </div>

    </div>
  )
}