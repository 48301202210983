import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  LoadingStore,
  StorageActions,
} from './loading-provider.interfaces';
import { reducer } from './loading-provider.reducer';
import { storeInit } from './initialState';

type Reducer<S, A> = (state: S, action: A) => S;



export const LoadingContext = createContext<ContextValues>({
  loadingStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoadingStore: () => {},
});

const LoadingProvider: React.FC<Props> = ({ children }) => {
  const [loadingStore, dispatch] = useReducer<Reducer<LoadingStore, StorageActions>>(
    reducer as any,
    storeInit
  );
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LoadingContext.Provider value={{ loadingStore, setLoadingStore: dispatch }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
