import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  ModalStore,
  StorageActions,
} from './modal-provider.interfaces';
import { reducer } from './modal-provider.reducer';
import { storeInit } from './initialState';

type Reducer<S, A> = (state: S, action: A) => S;



export const ModalContext = createContext<ContextValues>({
  modalStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModalStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [modalStore, dispatch] = useReducer<Reducer<ModalStore, StorageActions>>(
    reducer as any,
    storeInit
  );
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider value={{ modalStore, setModalStore: dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};

export default StoreProvider;
