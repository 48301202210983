import { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface UserProvider {
  user: any;
  firebaseService: any; // Add this line
  notifications?: any;
  showLogIn?: any;
  customerNumber?: any;
  billNumber?: any;
  OOBcode?: any;
  confirmPassword?: any;
  email?: any;
}

export type UserStore = UserProvider;

export enum UserTypes {
  SetUser = 'SET_USER',
  ResetUserStore = 'RESET_USER_STORE',
  SetUserLogOut = 'SET_LOGOUT',
  SetShowLogin = 'SET_SHOW_LOGIN',
  SetBillNumber = 'SET_BILL_NUMBER',
  SetCustomerNumber = 'SET_CUSTOMER_NUMBER',
  SetStore = 'SET_STORE',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [UserTypes.SetUser]: {
    value: any;
  };
  [UserTypes.SetStore]: {
    value: any;
  };
  [UserTypes.SetBillNumber]: {
    value: any;
  };
  [UserTypes.SetCustomerNumber]: {
    value: any;
  };

  [UserTypes.ResetUserStore]: {
    value: UserProvider;
  };

  [UserTypes.SetUserLogOut]: {
    value: boolean;
  };

  [UserTypes.SetShowLogin]: {
    value: boolean;
  };
};

export interface ContextValues {
  userStore: UserStore;
  setUserStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
