import React, { FC, useContext } from 'react';
import { IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { ThemeContext, CalendarContext } from '@flexo/providers';

type RefresherProps = {
  handleRefresh: any;
};

export const CustomPullToRefresh: FC<RefresherProps> = ({ handleRefresh }) => {
  const { ThemeStore } = useContext(ThemeContext);
  const { calendarStore } = useContext(CalendarContext);

  return ThemeStore.dropdownVisible === true ? (
    <></>
  ) : (
      
    // <IonRefresher slot="fixed" onIonRefresh={ ( e: any ) => handleRefresh( calendarStore, e ) as any }>
    //   <IonRefresherContent></IonRefresherContent>
    // </IonRefresher>
      
    <div style={{ color: 'transparent!important' }}>
      <PullToRefresh
        onRefresh={() => handleRefresh( calendarStore )}
        pullingContent={<></>}
        refreshingContent={
          <div style={{ paddingTop: 10, color: 'transparent!important' }}>
            <IonSpinner />
          </div>
        }
      >
        <div
          className="flex flex-row flex-wrap flex-align-center flex-justify-center"
          style={{
            width: '100%',
            paddingTop: 60,
            color: 'transparent!important',
          }}
        ></div>
      </PullToRefresh>
    </div>
  );
};

export default CustomPullToRefresh;
