import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { KpiApiContext, MetaDataApiContext, ThemeContext } from '@flexo/providers';

import { v4 as uuidv4 } from 'uuid';

import styles from './horizontal-chart.module.scss';
import HorizontalChart from './horizontal-chart';

export default function HorizontalChartWrapper(props: any) {

  const { t } = useTranslation();
  const { ThemeStore } = useContext(ThemeContext)
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const { kpiApiStore } = useContext(KpiApiContext);

  const [activeKeys, setActiveKeys] = useState<string[]>(props.graphKeys);

  const getItemsList = () => {

    const sitesList = ( (metaDataApiStore?.entities as any)?.site ) || [];

    if (props?.context === 'energy') {
      return kpiApiStore?.communityRanking?.consumptionRanking?.[0]?.site
        .map((_site: any) => ({
          ..._site,
          ...sitesList.find(( entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
        }) )
    } else if (props?.context === 'economics') {
      return kpiApiStore?.communityRanking?.economicsRanking?.[0]?.site
      .map((_site: any) => ({
        ..._site,
        ...sitesList.find(( entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
      }) )
    }

    return []
  }

  function getKpiValue( item: any ) {
    const kpi = item?.kpi?.find((datum: any) => props?.rankingKpi === datum.name);

    const value = kpi?.value || 0;
    const unit = kpi?.unit || '';
    return props?.context === 'economics' ? `${unit} ${value}` : `${value} ${unit}`;
  }


  // const handleButtonClick = (key: string) => {
  //   if (props.combinedKeys?.includes(key)) {
  //     // Se fa parte delle combinedKeys, attiva/disattiva entrambe
  //     const combinedKeyIndex = props.combinedKeys.indexOf(key);
  //     const combinedKey = props.combinedKeys[combinedKeyIndex + 1];

  //     setActiveKeys((prev) =>
  //       prev.includes(key)
  //         ? prev.filter((k) => k !== key && k !== combinedKey)
  //         : [...prev, key, combinedKey]
  //     );
  //   } else {
  //     // Se non fa parte delle combinedKeys, attiva/disattiva normalmente
  //     setActiveKeys((prev) =>
  //       prev.includes(key)
  //         ? prev.filter((k) => k !== key)
  //         : [...prev, key]
  //     );
  //   }
  // };

  const handleButtonClick = (key: string) => {

    if ( activeKeys.length === 1 && activeKeys.includes(key) ) {
      return;
    }

    if (activeKeys.length === props?.combinedKeys?.length && activeKeys?.includes(key) && props?.combinedKeys?.includes(key)) {
      return;
    }

    if (props.combinedKeys.includes(key)) {
      // Trova tutte le chiavi nel gruppo combinato
      const combinedKeyGroup = props.combinedKeys;
  
      // Verifica se tutte le chiavi del gruppo sono attive
      const areAllKeysActive = combinedKeyGroup.every(k => activeKeys.includes(k));
  
      setActiveKeys((prev) =>
        (areAllKeysActive
          ? prev.filter(k => !combinedKeyGroup.includes(k)) 
        : [...prev, ...combinedKeyGroup.filter(k => !prev.includes(k))])
        .filter( x => x )
      );
    } else {
      
      setActiveKeys((prev) =>
        ( prev.includes(key)
          ? prev.filter((k) => k !== key) 
        : [...prev, key])
        .filter( x => x )
      );
    }
  };
  
  
  

  

  return (
    <div id="HorizontalChart__Wrapper" className={styles.HorizontalChart__Wrapper}>
      <div className={styles.HorizontalChart__Title}>
        {t(props.title)}
      </div>
      <div className={styles.HorizontalChart__Buttons__Wrapper}>
        {
          props.buttons.map((button: any, index: number) => {
              return (
                <div
                  key={`HorizontalChart__${index}__button__${button}`}
                  className={`
                    ${styles.HorizontalChart__Button}
                    ${
                      props?.combinedKeys.includes(props.graphKeys[index])
                        ? styles.HorizontalChart__Button__Touch
                        : ''
                    }
                    ${
                    !activeKeys?.includes(props.graphKeys[index])
                    ? styles.HorizontalChart__Button__Disabled
                        : ''
                    }
                  `}
                  onClick={() => handleButtonClick(props.graphKeys[ index ])}
                >
                  {t(button)}
                  <div className={ styles.HorizontalChart__Button__Underline } style={{ backgroundColor: `${ ThemeStore?.colors[ props.colors[ index ].color ]}${props.colors[ index ].colorShade}` }}></div>
                </div>
              )

            
          })
        }
      </div>

      {
        
        (getItemsList() || []).map((item: any, index: number) => {
          return <div key={uuidv4()} className={ styles.HorizontalChart__Wrapper__Line }>
            <div className={styles.HorizontalChart__Wrapper__Line__Label} >
              {
                item?.pod
              }
              
              { props?.context === 'economics' && <span className="font-flexo-light">&nbsp; {item?.siteName}</span>  }
            </div>
            <div className={ styles.HorizontalChart__Wrapper__Line__Rank }>
              <div>
                <p className={ styles.HorizontalChart__Wrapper__Line__Rank__Value }>{ getKpiValue( item ) }</p>
                <p className={ styles.HorizontalChart__Wrapper__Line__Rank__Name }> { t(props?.rankingKpiName)}</p>
               
              </div>
            <HorizontalChart data={{
              kpi: item?.kpi,
                ...props,
                activeKeys
            }} />
            </div>
            
          </div>
        })
      }

    </div>
  )

}