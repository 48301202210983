/* eslint-disable no-case-declarations */
import { IconWrapper } from '@flexo/general';
import { timeFormat } from 'd3-time-format';
import { DateTime } from 'luxon';
import styles from './custom-input.module.scss';
import { useContext } from 'react';
import {
  CalendarTypes,
  CalendarContext,
  SiteViewContext,
  CommunityViewContext,
  DataContext,
} from '@flexo/providers';
import { useTranslation } from 'react-i18next';
import React from 'react';

const CustomInput = React.forwardRef(({ minDate }: any, ref) => {

  const { dataStore } = useContext( DataContext)
  const view = dataStore?.navigation?.module;

  const { CustomInput__ArrowLeft, CustomInput__ArrowRight, CustomInput__Icon } =
    styles;
  const { calendarStore, setCalendarStore } = useContext(CalendarContext);
  const { siteViewStore } = useContext(SiteViewContext);
  const { communityViewStore } = useContext(CommunityViewContext);
  const {
    calendarDisplay,
    selectedDate,
    selectedWeek,
    currentlySelectedDateType,
    prevSelectedDate,
    showCalendar,
    showCustomInteraction,
    customInteraction,
    buttonSwitch,
    previousCalendarDisplay,
  } = calendarStore;

  const { t } = useTranslation();

  const formatDate = timeFormat('%d/%m/%Y');
  const formatWeek = timeFormat('%d/%m/%y');
  const formatMonth = timeFormat('%B %Y');
  const formatYear = timeFormat('%Y');
  const getInputDate = () => {
    let result;

    if (buttonSwitch) {

      switch (previousCalendarDisplay) {
        case 'day':
          if (formatDate(new Date(selectedDate)) === formatDate(new Date()))
            result = t('widgets.calendar.today');
          else result = formatDate(new Date(selectedDate));
          break;

        case 'week':
          result = selectedWeek.startDate
            ? `${formatWeek(new Date(selectedWeek.startDate))} - ${formatWeek(
                new Date(selectedWeek.endDate as any)
              )}`
            : 'Select Week';
          break;

        case 'month':
          result = formatMonth(new Date(selectedDate));
          break;

        case 'year':
          result = formatYear(new Date(selectedDate));
          break;

        default:
          if (formatDate(new Date(selectedDate)) === formatDate(new Date()))
            result = t('widgets.calendar.today');
          else result = formatDate(new Date(selectedDate));
          break;
      }
    } else if (
      currentlySelectedDateType &&
      prevSelectedDate &&
      customInteraction
    ) {

      switch (currentlySelectedDateType) {
        case 'day':
          if (formatDate(new Date(prevSelectedDate)) === formatDate(new Date()))
            result = t('widgets.calendar.today');
          else result = formatDate(new Date(prevSelectedDate));
          break;

        case 'week':
          result = selectedWeek.startDate
            ? `${formatWeek(new Date(selectedWeek.startDate))} - ${formatWeek(
                new Date(selectedWeek.endDate as any)
              )}`
            : 'Select Week';
          break;

        case 'month':
          result = formatMonth(new Date(prevSelectedDate));
          break;

        case 'year':
          result = formatYear(new Date(prevSelectedDate));
          break;
        default:
          result = '';
      }
    } else {
      switch (currentlySelectedDateType) {
        case 'day':
          if (formatDate(new Date(selectedDate)) === formatDate(new Date()))
            result = t('widgets.calendar.today');
          else result = formatDate(new Date(selectedDate));
          break;

        case 'week':
          result = selectedWeek.startDate
            ? `${formatWeek(new Date(selectedWeek.startDate))} - ${formatWeek(
                new Date(selectedWeek.endDate as any)
              )}`
            : 'Select Week';
          break;

        case 'month':
          result = formatMonth(new Date(selectedDate));
          break;

        case 'year':
          result = formatYear(new Date(selectedDate));
          break;

        default:
          if (formatDate(new Date(selectedDate)) === formatDate(new Date()))
            result = t('widgets.calendar.yesterday');
          else result = formatDate(new Date(selectedDate));
          break;
      }
    }
    return result;
  };

  const getMaxDate = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();
    if (currentHour < 10 && currentMinutes < 30) {
      currentDate.setDate(currentDate.getDate() - 2);
    } else {
      currentDate.setDate(currentDate.getDate() - 1);
    }
    return currentDate;
  };

  const changeValue = (type: 'increase' | 'decrease') => {

    setCalendarStore({
      type: CalendarTypes.SetButtonSwitch,
      payload: {
        value: true,
      },
    });
    
    const currentDate = DateTime.fromJSDate(selectedDate);
    const newDate = (delta: any) =>
      new Date(currentDate.plus(delta).toFormat('MM/dd/yyyy'));

    
    
    switch (currentlySelectedDateType) {
      case 'day':
        setCalendarStore({
          type: CalendarTypes.SetSelectedDate,
          payload: {
            value: newDate({ days: type === 'increase' ? 1 : -1 }),
          },
        });
        break;
      case 'week':
        setCalendarStore({
          type: CalendarTypes.SetSelectedWeek,
          payload: {
            value: {
              startDate: DateTime.fromJSDate(selectedWeek.startDate as any)
                .plus({ week: type === 'increase' ? 1 : -1 })
                .toJSDate(),
              endDate: DateTime.fromJSDate(selectedWeek.endDate as any)
                .plus({ week: type === 'increase' ? 1 : -1 })
                .toJSDate(),
            },
          },
        });
        setCalendarStore({
          type: CalendarTypes.SetSelectedDate,
          payload: {
            value: DateTime.fromJSDate(selectedWeek.startDate as any)
              .plus({ week: type === 'increase' ? 1 : -1 })
              .toJSDate(),
          },
        });
        break;
      case 'month':
        setCalendarStore({
          type: CalendarTypes.SetSelectedDate,
          payload: {
            value: DateTime.fromJSDate(
              newDate({ months: type === 'increase' ? 1 : -1 })
            )
              .set({ day: 1 })
              .toJSDate(),
          },
        });

        break;
      case 'year':

        
        setCalendarStore({
          type: CalendarTypes.SetSelectedDate,
          payload: {
            value: DateTime.fromJSDate(
              newDate({ years: type === 'increase' ? 1 : -1 })
            )
              .set({ month: 1, day: 1 })
              .toJSDate(),
          },
        });

        break;
      default:
        return;
    }
  };
  const getNextButtonDisabled = () => {
    
    if (dataStore?.data?.loading) return true;
    if (showCalendar) return true;
    
    const luxonDate = DateTime.fromJSDate(selectedDate);


    if (view === 'household' && currentlySelectedDateType !== 'week' && dataStore?.data?.energyData) {
      const selectedInIso = luxonDate.toISO();
      const apiDate = DateTime.fromISO(
        dataStore?.data?.energyData[0]?.timestamp as any
      ).toISO();

      if (
        DateTime.fromISO(apiDate as any).toISODate() !==
        DateTime.fromISO(selectedInIso as any).toISODate()
      ) {
        return true;
      }
    }
    if (view === 'community' && currentlySelectedDateType !== 'week') {
      const todayMinusOneDay = DateTime.now().minus({ days: 1 }).toISODate(); // subtracting 2 days
      const selectedInIso = luxonDate.toISODate(); // Simplified to just date, no time
      const apiDate = DateTime.fromISO(
        communityViewStore.energyData[0]?.timestamp as any
      ).toISODate();

      if (
        !apiDate &&
        selectedInIso &&
        selectedInIso < (todayMinusOneDay as any)
      ) {
        return false;

      }

      if (apiDate !== selectedInIso) {
        return true
      };
    }

    const currentDate = DateTime.fromJSDate(getMaxDate());
    const formattedValue = DateTime.fromJSDate(selectedDate);
    switch (currentlySelectedDateType) {
      case 'day':
        return (
          formattedValue.startOf('day').toISO() ===
          currentDate.startOf('day').toISO()
        );
      case 'week':
        if (!selectedWeek.endDate) return true;
        const endOfWeek = DateTime.fromJSDate(selectedWeek.endDate as any);
        const diff = endOfWeek.diff(currentDate);
        return diff.as('milliseconds') >= 0;
      case 'month':
        return formattedValue.month === currentDate.month;
      case 'year':
        return formattedValue.year === currentDate.year;
      default:
        return false;
    }
  };



  const openCalendar = () => {
    setCalendarStore({
      type: CalendarTypes.SetShowCalendar,
      payload: {
        value: true,
      },
    });
  };

  const getInputDisabled = () => {
    if (view === 'household' && dataStore?.data?.loading)
      return 'styles.CustomInput__Input__Loading';
    else if (view === 'community' && communityViewStore.loading)
      return 'styles.CustomInput__Input__Loading';
    else return false;
  };

  const getPrevButtonDisabled = () => {
    
    if (showCalendar) return true;
    const luxonDate = DateTime.fromJSDate(selectedDate);
    if (view === 'household' && currentlySelectedDateType !== 'week' && dataStore?.data?.energyData ) {
      const selectedInIso = luxonDate.toISO();
      const apiDate = DateTime.fromISO(
        dataStore?.data?.energyData[0]?.timestamp as any
      ).toISO();
      if (
        DateTime.fromISO(apiDate as any).toISODate() !==
        DateTime.fromISO(selectedInIso as any).toISODate()
      )
        return true;
    }
    if (view === 'community' && currentlySelectedDateType !== 'week' ) {
      const selectedInIso = luxonDate.toISO();
      const apiDate = DateTime.fromISO(
        communityViewStore.energyData[0]?.timestamp as any
      ).toISO();

      if (!apiDate) return false;
      if (
        DateTime.fromISO(apiDate as any).toISODate() !==
        DateTime.fromISO(selectedInIso as any).toISODate()
      )
        return true;
    }
    // Output it as an ISO string

    if (calendarDisplay === 'week' && !selectedWeek.endDate) return true;
    else if (view === 'household' && dataStore?.data?.loading) return true;
    else if (view === 'community' && communityViewStore.loading) return true;
    else if (
      minDate &&
      DateTime.fromJSDate(selectedDate)
        .diff(DateTime.fromJSDate(minDate))
        .as('milliseconds') <= 0
    )
      return true;
    else return false;
  };

  return (
    <div className={styles.CustomInput}>
      <button
        className={CustomInput__ArrowLeft}
        onClick={() => changeValue('decrease')}
        disabled={getPrevButtonDisabled()}
      >
        <span className={CustomInput__Icon}>
          {' '}
          <IconWrapper iconName="chevron-left" />
        </span>
      </button>
      <span
        className={`${
          styles.CustomInput__Input
        } heading3 ${getInputDisabled()} `}
        onClick={openCalendar}
      >
        {getInputDate()}
      </span>
      <button
        className={CustomInput__ArrowRight}
        onClick={() => changeValue('increase')}
        disabled={getNextButtonDisabled()}
      >
        {' '}
        <IconWrapper iconName="chevron-right" />
      </button>
    </div>
  );
});
export default CustomInput;
