import MultiProgress from 'react-multi-progress';
import { Shimmer } from '@flexo/atoms';
import { DonutGraph } from '@flexo/atoms';
import {
  placeholderData,
  skeletonProgressValues,
} from './stats-overview.constants';
import { useTranslation } from 'react-i18next';
import styles from './stats-overview.module.scss';
import { AlertContext, SiteViewContext } from '@flexo/providers';
import { useContext, useEffect, useState } from 'react';

export function StatsOverviewSkeleton(props: any) {
  const {
    StatsOverview__PieContainer,
    StatsOverview__Stats,
    StatsOverview__Stats__Title,
    StatsOverview__Stats__Percentages,
    StatsOverview__Stats__Percentages__Labels,
    StatsOverview__Stats__Percentages__Labels__Label,
  } = styles;
  const [networkConnected, setNetworkConnected] = useState(true);

  const { t } = useTranslation();
  const { siteViewStore } = useContext(SiteViewContext);
  const { alertStore } = useContext(AlertContext);
  const { responseError, siteType } = siteViewStore;
  const {
    title,
    community,
    grid,
    selfConsumption,
    dataInTimeSpan = true,
    battery,
  } = props;

  const values = [community, selfConsumption, grid, battery].filter(
    (value) => value
  );
  // Effect to monitor the alertStore and update networkConnected state
  useEffect(() => {
    // Check if the alert type and title matches the specific alert
    if (
      alertStore.alert.type === 'warning' &&
      (alertStore.alert as any).title === t('errors.no_connection.title')
    ) {
      setNetworkConnected(false);
    }
  }, [alertStore, t]);

  // if (siteType === 'consumer' && props.type === 'production') {
  //   return null;
  // } else
  return (
    <div className={styles.StatsOverview} style={{ position: 'relative' }}>
      <div className={StatsOverview__PieContainer}>
        <DonutGraph
          width={105}
          height={105}
          graphData={placeholderData}
          colors={['#f7f7f7']}
          totalAmount="-"
          keys={['']}
          totalAmounttype="kWh"
          animate={false}
          selectedId={null}
          setSelectedId={null}
        />
      </div>{' '}
      <div className={StatsOverview__Stats}>
        <span className={`${StatsOverview__Stats__Title} heading4`}>
          {t(title)}
        </span>
        {values.map((value: any) => (
          <div className={StatsOverview__Stats__Percentages} key={value.label}>
            <div
              className={`${StatsOverview__Stats__Percentages__Labels} paragraph`}
            >
              {' '}
              <span
                className={`${StatsOverview__Stats__Percentages__Labels__Label} heading5L`}
              >
                {t(value.label)}
              </span>
              <span
                className={StatsOverview__Stats__Percentages__Labels__Label}
              >
                -%
              </span>
            </div>

            <div>
              {' '}
              <MultiProgress
                elements={skeletonProgressValues}
                backgroundColor="#F0F0F0"
              />{' '}
            </div>
          </div>
        ))}
      </div>
      {networkConnected && !responseError && dataInTimeSpan === true && (
        <Shimmer />
      )}
    </div>
  );
}

export default StatsOverviewSkeleton;
