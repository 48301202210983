import { v4 as uuidv4 } from 'uuid';

import styles from './site-members-list.module.scss';
import { useContext, useRef, useState } from 'react';
import { DataContext, MetaDataApiContext, ModulesContext, SiteViewContext, ThemeContext } from '@flexo/providers';
import { useTranslation } from 'react-i18next';
import { IconWrapper, capitalizeFirstLetter } from '@flexo/general';
import { navigateToMember, navigateToSite } from '@flexo/atoms';
import { timeFormat } from 'd3-time-format';


export function SiteMembersList(props: any) {

  const {
    Sites__List__Wrapper,
    Sites__List__Item,
    Sites__List__Wrapper__Flat,
    Tall,
    Sites__List__Item__Name,
    Sites__List__Item__Pod,
    Sites__List__Item__Detail,
    Sites__List__Item__Label,
  } = styles;
  const { t } = useTranslation();
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext)
  const { modulesStore, setModulesStore } = useContext(ModulesContext);
  const { since, client_id, billing_address, phone, email } = t('widgets.siteMembersList', { returnObjects: true }) as any;

  const formatDate = timeFormat('%d/%m/%Y');
  

  const membersList = ([
    ...((metaDataApiStore?.sortedEntities as any)?.communities || []).reduce((prev: any, next: any) => ([...prev, ...(next?.members || [])]), []),
    ...((metaDataApiStore as any)?.unassignedMembers || [])
  ])
    ?.filter((member: any) => member?.sites?.some((site: any) => site?.siteId === metaDataApiStore?.selectedEntityId));
  

  return (
    <div
      key={`member_sites_list_${uuidv4()}`}
      className={`${['sm', 'xs', 'xxs'].includes(modulesStore.breakpoint) ? Sites__List__Wrapper__Flat : Sites__List__Wrapper} ${modulesStore.breakpoint === 'sm' && Tall}`}
    >

      {
        ( membersList || [] )
          .map((member: any) => {
            return (

              
              <div key={`${member.id}_${uuidv4()}`} className={`${Sites__List__Item}`}>
                
                <p
                  onClick={ () => navigateToMember(member, setMetaDataApiStore, setModulesStore) }
                  className={`heading4 ${Sites__List__Item__Name}`}
                >
                  {capitalizeFirstLetter( `${ member?.memberFirstName} ${ member?.memberLastName}` )}
                  <IconWrapper iconName='chevron-right' />
                </p>
                <p className={Sites__List__Item__Label}>{since} { ' '}
                  {
                    (metaDataApiStore?.selectedEntity as any)?.joinDate
                      ? formatDate(new Date((metaDataApiStore?.selectedEntity as any)?.joinDate))
                      : (metaDataApiStore?.selectedEntity as any)?.startDate
                        ? formatDate(new Date((metaDataApiStore?.selectedEntity as any)?.startDate))
                        : '-'
                  }
                </p>

                <p className={Sites__List__Item__Detail}>{ member?.memberExternalId || '-' }</p>
                <p className={Sites__List__Item__Label}>{client_id}</p>
                
                <p className={Sites__List__Item__Detail} >
                  {
                    (!(member)?.address || !(member)?.city)
                    ? '-'
                    : `${(member)?.address}, ${ (member)?.city }`
                  }  
                </p>
                <p className={Sites__List__Item__Label} >{ billing_address}</p>
                
                <p className={ Sites__List__Item__Detail } >{ member?.phone || '-' }</p>
                <p className={Sites__List__Item__Label} >{ phone }</p>
                
                <p className={ Sites__List__Item__Detail } >{ member?.email || '-' }</p>
                <p className={Sites__List__Item__Label} >{ email }</p>
              </div>
            )
          } )
      }

    </div>
  )
  

}

export default SiteMembersList;
