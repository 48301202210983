import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  SiteViewStore,
  StorageActions,
} from './site-view-provider.interfaces';
import { reducer } from './site-view.reducer';

type Reducer<S, A> = (state: S, action: A) => S;

export const storeInit: SiteViewStore = {
  scrollDisabled: false,
  user: {},
  notifications: [],
  sites: [],
  energyData: [],
  energyDataOverview: [],
  selectedSite: {},
  loading: true,
  divider: 0,
  overviewUnit: 'kWh',
  detailUnit: 'kWh',
  //pollutionUnit: 'kg',
  overviewEconomicUnit: '€',
  detailEconomicUnit: '€',
  maxPollution: {},
  maxConsumption: {},
  maxProduction: {},
  economics: {},
  responseError: false,
  siteType: '',
  dataInTimeSpan: true,
  overviewDataPercentage: [],
  overviewDataRaw: [],
  overviewDataLocalized: [],
  overviewData: [],
  energyEfficiencyMetrics: {},
  customKpi: []
};

export const SiteViewContext = createContext<ContextValues>({
  siteViewStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSiteViewStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [siteViewStore, dispatch] = useReducer<
    Reducer<SiteViewStore, StorageActions>
  >(reducer as any, storeInit);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SiteViewContext.Provider
      value={{ siteViewStore, setSiteViewStore: dispatch }}
    >
      {children}
    </SiteViewContext.Provider>
  );
};

export default StoreProvider;
