import React, { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface IRightDrawer {
  show?: boolean;
  type: string;
  data?: any;
}

export interface RightDrawerProvider {
  rightDrawer: IRightDrawer;
}

export type RightDrawerStore = RightDrawerProvider;

export enum IRightDrawerTypes {
  SetDrawer = 'SET_DRAWER',
  ResetDrawer = 'RESET_DRAWER',
  HideDrawer = 'HIDE_DRAWER',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [IRightDrawerTypes.SetDrawer]: {
    value: IRightDrawer;
  };
  [IRightDrawerTypes.ResetDrawer]: {
    value: IRightDrawer;
  };
  [IRightDrawerTypes.HideDrawer];
};

export interface ContextValues {
  RightDrawerStore: RightDrawerStore;
  setRightDrawerStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
