import React, { createContext, useReducer } from 'react';
import { reducer } from './calendar-provider.reducer';
import {
  CalendarStore,
  ContextValues,
  Props,
  StorageActions,
} from './calendar-provider.interfaces';
import { DateTime } from 'luxon';

type Reducer<S, A> = (state: S, action: A) => S;

const currentDate = DateTime.now();
const currentHour = currentDate.hour;
const currentMinutes = currentDate.minute;

let prevSelectedDate = currentDate.minus({ days: 1 }).toJSDate();
let selectedDate = currentDate.minus({ days: 1 }).toJSDate();

if ( currentHour < 10 && currentMinutes < 30 ) {
  prevSelectedDate = currentDate.minus({ days: 2 }).toJSDate();
  selectedDate = currentDate.minus({ days: 2 }).toJSDate();
} 

export const storeInit: CalendarStore = {
  calendarDisplay: 'year',
  selectedDate: new Date('2024-01-01'),
  selectedWeek: {
    startDate: null,
    endDate: null,
  },
  showCalendar: false,
  customInteraction: undefined,
  previousCalendarDisplay: undefined,
  currentlySelectedDateType: 'year',
  showCustomInteraction: {
    day: false,
    week: false,
    month: false,
  },
  prevSelectedDate: prevSelectedDate,
  buttonSwitch: false,
};

export const CalendarContext = createContext<ContextValues>({
  calendarStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCalendarStore: () => {},
});

const CalendarProvider: React.FC<Props> = ({ children }) => {
  const [calendarStore, dispatch] = useReducer<
    Reducer<CalendarStore, StorageActions>
  >(reducer as any, storeInit);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CalendarContext.Provider
      value={{ calendarStore, setCalendarStore: dispatch }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarProvider;
