/* eslint-disable no-case-declarations */
import { FiltersStore, StorageActions } from './filters-provider.interfaces';
import { storeInit } from './initialState';

export const reducer = (state: FiltersStore, action: StorageActions) => {
  switch (action.type) {
    case 'RESET_ALL_FILTERS':
      return storeInit;
      break;
    case 'SET_FILTER':
      return {
        ...state,
        ...action.payload.value,
      };
      break;
    case 'SET_FILTERED_COMMUNITIES':
      return {
        ...state,
        filteredCommunities: action.payload.value,
      };

    case 'SET_FILTERED_SITES':
      return {
        ...state,
        filteredSites: action.payload.value,
      };
      break;
    case 'SET_FILTERED_MEMBERS':
      return {
        ...state,
        filteredMembers: action.payload.value,
      };
      break;
    case 'RESET_FILTER':
      // alert('reset filter');

      const oldState = { ...state };
      delete oldState[action.payload.key];

      return {
        ...oldState,
      };
      break;

    default:
      return state;
  }
};
