import React, { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface FiltersProvider {
  filteredCommunities?: any[];
  filteredMembers?: any[];
  filteredSites?: any[];
}

export type FiltersStore = FiltersProvider;

export enum FiltersStoreTypes {
  ResetAllFilters = 'RESET_ALL_FILTERS',
  SetFilter = 'SET_FILTER',
  SetFilteredCommunities = 'SET_FILTERED_COMMUNITIES',
  SetFilteredSites = 'SET_FILTERED_SITES',
  SetFilteredMembers = 'SET_FILTERED_MEMBERS',
  ResetFilter = 'RESET_FILTER',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [FiltersStoreTypes.ResetAllFilters];
  [FiltersStoreTypes.SetFilter]: {
    value: any;
  };
  [FiltersStoreTypes.SetFilteredCommunities]: {
    value: any;
  };
  [FiltersStoreTypes.SetFilteredMembers]: {
    value: any;
  };
  [FiltersStoreTypes.SetFilteredSites]: {
    value: any;
  };
  [FiltersStoreTypes.ResetFilter]: {
    key: string;
  };
};

export interface ContextValues {
  filtersStore: FiltersStore;
  setFiltersStore: Dispatch<StorageActions>;
}

export interface Props {
  children: any;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
