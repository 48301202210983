
import EventEmitter from "events";

export const breakpoints: any = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const breakpointKeys = Object.keys(breakpoints);

export const getScreenWidth = () => {
  const width = window.innerWidth;
  let lastSize;

  for (const size in breakpoints) {
    if (breakpoints[size] <= width) {
      lastSize = size;
    }
  }
  return lastSize;
};

export const initGetScreenWidth = (sizeCallback: any) => {
  window.addEventListener('resize', () => {
    sizeCallback(getScreenWidth());
  });
  sizeCallback(getScreenWidth());
};

// Function to get grid configuration based on the element's grid option and breakpoint
export const getGridConfig = (
  elementGridOption: any = null,
  breakpoint: string
) => {
  if (!elementGridOption) return '';

  //composeing the grid classes based on the grid options provided for the widget
  let composedClass = '';

  const screenWidth = !breakpoint ? getScreenWidth() : breakpoint || 'xs';
  if (elementGridOption.width) {
    composedClass += `grid-${getPreviousWidth(screenWidth, elementGridOption?.width) || 12
      }`;
  } else {
    composedClass += `grid-12`
  }
  if (elementGridOption.order) {
    composedClass += ` order-${
      getPreviousWidth(screenWidth, elementGridOption?.order) || ''
    }`;
  }
  if (elementGridOption.visible) {
    composedClass += ` d-${
      getPreviousWidth( screenWidth, elementGridOption?.visible ) === true ? 'visible' : 'none' || 'visible'
    }`;
  }
  if (elementGridOption.className) {
    composedClass += ` ${elementGridOption?.className || ''}`;
  }
  return composedClass;
};

// If there is no assigned value for the width, we search for the one before it (ex: if md is not assigned, we search for sm)
const getPreviousWidth = (
  currentBreakpoint: any,
  widgetConfig: any = null
): any => {
  if (!widgetConfig) {
    return null;
  }
  if (widgetConfig?.[currentBreakpoint] !== null) {
    return widgetConfig[currentBreakpoint];
  }
  const currentBreakpointIndex = breakpointKeys.indexOf(currentBreakpoint);
  const previousBreakpoint =
    breakpointKeys?.[currentBreakpointIndex - 1] || breakpointKeys[0];
  return (
    ( widgetConfig?.[previousBreakpoint] !== null) ||
    getPreviousWidth(previousBreakpoint, widgetConfig)
  );
};

export const getLastNineDigits = (input: string): string => {
  if (input.length < 9) {
    return '';
  }
  return input.slice(-9);
};

export const REGEX = {
  // email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  email: /^(?![-_])[^\s@]+@(?![-_])[^\s@]+\.[^\s@]+$/,
  verificationCode: /^[A-Z0-9]{3}-[A-Z0-9]{3}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]).{8,}$/,
}

function parseErrorString(errorString) {
  const regex = /^(.+?)\s*\[\s*([A-Z_]+)_(\d{3})\s*\]$/;
  const match = errorString.match(regex);

  if (match) {
      return {
          message: match[1].trim(),
          context: match[2],
          subCode: match[3]
      };
  } else {
      return null;
  }
}


export const errorParser = (error: any) => {

  const parsedError = {
    internalErrorCode: 'ERR__000',
    internalErrorSubCode: '000',
    internalErrorMessage: 'An error occurred',
    internalErrorContext: '[]',
  };

  try {

    const errorData = error?.response?.data || {};
    const errorStringContent = parseErrorString(errorData?.message);

    parsedError['internalErrorCode'] = errorData?.code || 'ERR_000';
    parsedError['internalErrorSubCode'] = errorStringContent?.['subCode'] || '000';
    parsedError['internalErrorMessage'] = errorStringContent?.['message'] || 'An error occurred';
    parsedError['internalErrorContext'] = `[${errorStringContent?.['context'] || ''}]`

  } catch (e: any) {
    // console.error('Error parsing error', e);
  }

  return parsedError;

}

export type EmittedEvent = {
  type: 'LOG_OUT' | null;
  payload?: any;
}

export const eventEmitter = new EventEmitter();

export function emitEvent(props: EmittedEvent) {
  const { type = null, payload = null } = props;

  if (type === null) {
    // console.warn('Event type is required');
    return;
  }

  eventEmitter.emit(type, payload);
}