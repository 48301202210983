
import React, { useContext, useEffect, useState, } from 'react';

import styles from './header-web.module.scss';
import { DataContext, MetaDataApiContext, ModulesContext } from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import {  ProfileButton, Search, navigateToCommunities, navigateToCommunity } from '@flexo/atoms';

import './header-web.module.scss';
import { Autocomplete, Select } from '@mui/material';
import { Calendar } from '@flexo/molecules';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__BreadCrumb,
  Header__Web__Center,
} = styles;

function CommunityDataVisNavigation(props: any) {
  
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null, breadCrumbs = null } = props;
  const { t } = useTranslation()

  const communities = (metaDataApiStore?.entities as any)?.communities || [];

  function getCommunity() {
    const COMMUNITY_ID = metaDataApiStore?.selectedEntityId || 'missing_id';

    const foundCommunity = communities?.find((community: any) => (community?.communityId || community?.flexoCommunityId) === COMMUNITY_ID) || communities?.[0] || null;
    
    if ( !foundCommunity) {
      return communities?.[0];
    } else {
      return foundCommunity;
    }

  }

  function getCommunityName() {
    const foundCommunity = getCommunity();
    return (foundCommunity?.communityName) || communities?.[0]?.communityName || 'Community name not found';
  }

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>

       
        
        <div className={Navigation}>
        <label className={ `${styles.clickable}`} onClick={() => navigateToCommunities(setModulesStore)}>{t(`widgets.header.communities`)}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <label className={ `${styles.clickable}`} onClick={() => navigateToCommunity( getCommunity(), setMetaDataApiStore, setModulesStore)} >{ getCommunityName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <Select
            className='site-navigation-select'
            id="site-navigation-select"
            MenuProps={{
              className: 'site-navigation-select-menu'
            }}
            defaultValue={metaDataApiStore?.entityType || null}
            IconComponent={() => <IconWrapper className={'site-navigation-select-icon'} iconName='chevron-down' />}
          >
            <option
              disabled={ metaDataApiStore?.entityType === 'community-energy' }
              onClick={() => {
              navigateToCommunity( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'energy' )
            }} value="community-energy">Energy</option>
            <option
              disabled={ metaDataApiStore?.entityType === 'community-economics' }
              onClick={() => {
              navigateToCommunity( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'economics' )
            }} value="community-economics">Economics</option>
          </Select>

          
      {/* {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          } */}
      
    </div>


      </div>

      <div className={Header__Web__Center}>
          <Calendar />
        </div>

      <div className={Header__Web__Right}>
        {
          searchBar &&
          <Search />
        }
        {
        profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(CommunityDataVisNavigation);
