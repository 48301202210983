import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  metaDataApiStore,
  StorageActions,
} from './meta-data-api-provider.interfaces';
import { reducer } from './meta-data-api.reducer';

type Reducer<S, A> = (state: S, action: A) => S;

export const storeInit: metaDataApiStore = {
  selectedEntity: null,
  selectedEntityId: null,
  entityType: null,
  // selectedEntity: {
  //   id: '',
  //   entitytype: 'site',
  //   entity: {
  //     status: '',
  //     siteExternalId: '',
  //     pod: '',
  //     siteAddress: '',
  //     flexoCommunityId: '',
  //     joinDate: '',
  //     leaveDate: '',
  //     siteLatitude: 0,
  //     siteLongitude: 0,
  //     siteName: '',
  //     siteType: [
  //       {
  //         status: '',
  //         flexoSiteType: 'producer',
  //         startDate: '',
  //         endDate: '',
  //       },
  //     ],
  //   },
  // },
  entities: [],
  sortedEntities: null,
  communities: [],
  members: [],
  selectedEntityName: null,
};

export const MetaDataApiContext = createContext<ContextValues>({
  metaDataApiStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMetaDataApiStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [metaDataApiStore, dispatch] = useReducer<
    Reducer<metaDataApiStore, StorageActions>
  >(reducer as any, storeInit);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MetaDataApiContext.Provider
      value={{ metaDataApiStore, setMetaDataApiStore: dispatch }}
    >
      {children}
    </MetaDataApiContext.Provider>
  );
};

export default StoreProvider;
