/* eslint-disable no-case-declarations */
import { storeInit } from './calendar-provider';
import { CalendarStore, StorageActions } from './calendar-provider.interfaces';

export const reducer = (state: CalendarStore, action: StorageActions) => {
  switch (action.type) {
    case 'SET_CALENDAR_DISPLAY':

      return { ...state, calendarDisplay: action.payload.value };
    case 'SET_SELECTED_DATE':

      return {
        ...state,
        selectedDate: action.payload.value,
      };
    case 'SET_SELECTED_WEEK':

      return {
        ...state,
        selectedWeek: {
          startDate: action.payload.value.startDate,
          endDate: action.payload.value.endDate,
        },
      };
    case 'SET_SHOW_CALENDAR':
      return {
        ...state,
        showCalendar: action.payload.value,
      };
    case 'SET_CUSTOM_INTERACTION':
      return {
        ...state,
        customInteraction: action.payload.value,
      };
    case 'SET_PREVIOUS_CALENDAR_DISPLAY':
      return {
        ...state,
        previousCalendarDisplay: action.payload.value,
      };
    case 'SET_CURRENTLY_SELECTED_DATE_TYPE':
      return {
        ...state,
        currentlySelectedDateType: action.payload.value,
      };
    case 'SET_SHOW_CUSTOM_INTERACTION':
      return {
        ...state,
        showCustomInteraction: action.payload.value,
      };
    case 'SET_PREV_SELECTED_DATE':
      return {
        ...state,
        prevSelectedDate: action.payload.value,
      };
    case 'SET_BUTTON_SWITCH':
      return {
        ...state,
        buttonSwitch: action.payload.value,
      };
    case 'RESET_CALENDAR_STORE':
      return storeInit;

    default:
      return state;
  }
};
