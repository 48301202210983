import React, { useContext, useEffect, useRef, useState } from 'react';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import { useTranslation } from 'react-i18next';
import { IRightDrawerTypes, MetaDataApiContext, ModulesContext, RightDrawerContext } from '@flexo/providers';

import styles from './map.module.scss';
import { IconWrapper } from '@flexo/general';
import { navigateToSite } from '../meta-resolvers/site-resolver';
import { timeFormat } from 'd3-time-format';

export function EsriMapComponent(props) {

  const { wrapper = null } = props
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { setRightDrawerStore } = useContext(RightDrawerContext);
  const formatDate = timeFormat('%d/%m/%Y');
  const { t } = useTranslation();
  const mapDiv = useRef<HTMLDivElement>(null);
  const [shownSite, setShownSite] = useState<any>(null);

  let mapHeight = wrapper === 'drawer' ? '100vh' : 216;
  let radius = 16;


  if ( !metaDataApiStore?.entities ) return <> </>;

  let { sites } = (metaDataApiStore?.entities as any) || null;
  let singleSite: any = null;
  const context = props?.context || metaDataApiStore?.entityType || '';
  
  if (context?.includes('site') && (metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId)) {


    singleSite = metaDataApiStore?.selectedEntity || (metaDataApiStore as any)?.entities?.site?.find((site: any) => site.siteId === metaDataApiStore?.selectedEntityId) || null
    sites = [singleSite];

  }

  if ( context?.includes( 'community' ) && metaDataApiStore?.selectedEntity ) {
    sites = [
      ...(metaDataApiStore as any)?.entities?.site?.filter((site: any) => site.communityId === metaDataApiStore?.selectedEntityId ) || [],
    ];
  }

  if ( context?.includes( 'member' ) && metaDataApiStore?.selectedEntity ) {
    sites = (metaDataApiStore?.entities as any)?.site?.filter((site: any) => site.memberId === (metaDataApiStore?.selectedEntity as any)?.memberId);
    
  }

  if (['site-energy', 'site-economics', 'community-energy', 'community-economics']?.includes(context)) {
    
    mapHeight = 400;
    radius = 0;

    if (
      singleSite?.siteType?.[0]?.siteType === 'consumer' ||
      singleSite?.type === 'consumer' ||
      singleSite?.siteType?.[0]?.siteType === 'producer' ||
      singleSite?.type === 'producer'
    ) {
      radius = 16;
      mapHeight = 216;
    }

  }

  if (wrapper === 'drawer') {
    radius = 0;
    mapHeight = '100vh';
  }

  

  
  function goToSite(site) {

    if ( wrapper === 'drawer' ) {
      setRightDrawerStore({
        type: IRightDrawerTypes.ResetDrawer,
        payload: {} as any
      })
    }

    setShownSite(null);

    navigateToSite(site, setMetaDataApiStore, setModulesStore, 'site')
  }

  function expandMap() {
    setRightDrawerStore({
      type: IRightDrawerTypes.SetDrawer,
      payload: {
        value: {
          ...props,
          type: 'EXPANDED_MAP',
        } as any,
      },
    })
  }

  function closeDrawer() {
    setRightDrawerStore({
      type: IRightDrawerTypes.ResetDrawer,
      payload: {} as any
    })
  }

  function showSite(siteData, event) {

    let site: any = null;

    if (shownSite && shownSite?.sideId === site?.siteId) {
      setShownSite(null);
      return;
    } 


    site = {
      ...siteData,
      members: ([
        ...((metaDataApiStore?.sortedEntities as any)?.communities || []).reduce((prev: any, next: any) => ([...prev, ...(next?.members || [])]), []),
        ...((metaDataApiStore as any)?.unassignedMembers || [])
      ])
        ?.filter((member: any) => member?.sites?.some((site: any) => (site?.siteId === metaDataApiStore?.selectedEntityId || site?.memberId === metaDataApiStore?.selectedEntityId) ))
    }

    setShownSite( site )

  }

  // eslint-disable-next-line
  useEffect(() => {

    if (!sites) return;

    if (mapDiv.current) {
      const webmap = new ArcGISMap({
        basemap: 'streets-navigation-vector',
      });

      const graphicsLayer = new GraphicsLayer();
      webmap.add(graphicsLayer);

      const simpleMarkerSymbol = {
        type: 'simple-marker',
        color: [0, 158, 98],
        size: 15,
        outline: {
          color: [255, 255, 255], // White
          width: 2,
        },
      };

      (sites || []).forEach((site) => {

        if (!site?.siteLatitude || !site?.siteLongitude) {
          const coords = (metaDataApiStore?.entities as any)?.site?.find((_site: any) => _site.siteId === site.siteId);
          site = {
            ...site,
            ...coords,
          }
        }

        const point = {
          type: 'point',
          longitude: site.siteLongitude,
          latitude: site.siteLatitude,
        };

        const pointGraphic = new Graphic({
          geometry: point,
          symbol: simpleMarkerSymbol,
          attributes: site,
        } as any);

        graphicsLayer.add(pointGraphic);
      });

      const view = new MapView({
        container: mapDiv.current,
        map: webmap,
        center: [0, 0],
        zoom: 13,
      });

      

      view.when(() => {
        if (webmap.layers.includes(graphicsLayer)) {
          view.goTo(graphicsLayer.graphics.toArray(), { animate: false });
        } else {
          // console.error('Graphics layer is not part of the map');
        }
      }).catch((error) => {
        // console.error('Error initializing MapView:', error);
      });

      view.on('click', (event) => {
        view.hitTest(event).then((response) => {
          const results = response.results;
          if (results.length > 0) {
            const graphic: any = results.find((result: any) => result.graphic.layer === graphicsLayer);
            if (graphic) {
              const siteData = graphic.graphic.attributes; // Access the site data

              view.center = graphic.graphic.geometry;

              showSite(siteData, event)
              // You can now use the siteData to show additional information or trigger other actions
              // For example, you can open a modal, navigate to another page, or update a state
            } else { 
              setShownSite(null)
            }
          }
        });
      });

      view.on( 'mouse-wheel', () => setShownSite(null) )

      return () => {
        if (view) {
          view.destroy();
        }
      };
    }
  }, [sites, t]);

  return (
    <div className={styles.Map__Wrapper} style={{ borderRadius: radius }}>
      <div ref={mapDiv} id="mapViewNode" style={{ width: '100%', height: mapHeight }} />

      {
        wrapper !== 'drawer' && 
          <div className={styles?.Map__Button__Expand} onClick={ () => expandMap()}>
            <IconWrapper iconName="expand" />
          </div>
      }
      {
        (wrapper !== 'drawer' && shownSite) &&
          <div className={styles?.Map__Shown__Site} style={{ top: 'calc( 50% - 24px )', left: 'calc(50% - 65px)' }}>
            <p
            className={styles?.Map__Shown__Site__Pod}
            onClick={() => goToSite(shownSite)}
            onMouseLeave={() => setShownSite(null)}
          >
              {shownSite?.pod}
            </p>
            <p className={styles?.Map__Shown__Site__Name}>{ shownSite?.siteName }</p>
          </div>
      }
      {
        
        <div className={styles?.Map__Shown__Site__Expanded} style={{
          opacity: wrapper === 'drawer' && shownSite ? 1 : 0,
          height: wrapper === 'drawer' && shownSite ? 'auto' : 0,
        }}>
            <p
            className={styles?.Map__Shown__Site__Expanded__Pod}
            onClick={() => goToSite(shownSite)}
          >
              <span>{shownSite?.pod}</span>
              { shownSite?.siteName }
            </p>
            <p className={styles?.Map__Shown__Site__Expanded__Member}>
              <span>{ shownSite?.members?.[0]?.memberFirstName } { shownSite?.members?.[0]?.memberLastName }</span>
              { shownSite?.members?.[0]?.memberExternalId || '-' } 
            </p>
            <p className={styles?.Map__Shown__Site__Expanded__Address}>
              <span>{ shownSite?.members?.[0]?.address }</span>
              { shownSite?.members?.[0]?.city }, { shownSite?.members?.[0]?.postcode }
            </p>
            <p className={styles?.Map__Shown__Site__Expanded__Type}>
              { t(`widgets.map.${ shownSite?.siteType?.[0]?.siteType || 'default'}`) }
            </p>
            <p className={styles?.Map__Shown__Site__Expanded__Owned}>
              <span>{ t('widgets.map.owned')}:</span> &nbsp; { (shownSite?.joinDate || shownSite?.startDate) ? formatDate(new Date((shownSite?.joinDate || shownSite?.startDate))) : 'Unknown join date' } - 
            </p>
          </div>
      }
      {
        wrapper === 'drawer' && 
        <div className={styles?.Map__Button__Close} onClick={ () => closeDrawer()}>
          <IconWrapper iconName="cross" />
        </div>
      }
    </div>
  );
}
