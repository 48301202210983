/* eslint-disable no-case-declarations */
import { storeInit } from './kpi-api-provider';
import { kpiApiStore, StorageActions } from './kpi-api-provider.interfaces';

export const reducer = (state: kpiApiStore, action: StorageActions) => {
  
  switch (action.type) {
    case 'SET_KPI_DATA_COMMUNITY':
      return {
        ...state,
        community: action.payload.value,
      };
    case 'SET_KPI_DATA_COMMUNITY_RANKING':
      return {
        ...state,
        communityRanking: {
          ...state?.communityRanking || {},
          ...action.payload.value,
        },
      };
    case 'SET_KPI_DATA_SITE':
      return {
        ...state,
        site: action.payload.value,
      };

    case 'RESET_KPI_API_STORE':
      return storeInit;
    default:
      return state;
  }
};
