import { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './member-details-parameters.module.scss';

import { useTranslation } from 'react-i18next';
import { MetaDataApiContext } from '@flexo/providers'
import { capitalizeFirstLetter } from '@flexo/general';




export function MemberDetailsParameters(props: any) {

  const { t } = useTranslation();

  const { title, fields, shownFields, sitesNumber } = props;

  const { metaDataApiStore } = useContext(MetaDataApiContext)
  
  const {
    Details__Wrapper__Compact,
    Details__Paper,
    Details__Member__Name,
    Details__Member__Content,
    Details__Member__Content__Name,
    Details__Member__Name__Title,
  } = styles


  function getMemberName(member: any) {
    return `${member?.memberFirstName} ${member?.memberLastName}` || null
  }

  function getMemberSites() {
    return (metaDataApiStore?.entities as any)?.site?.filter((site: any) => site.memberId === (metaDataApiStore?.selectedEntity as any)?.memberId)?.length || 0
  }

  return (
    <div
      key={uuidv4()}
      className={`
      ${Details__Wrapper__Compact}
      `}
    >
      <div className={`${ Details__Paper}`}>
        <p className={ Details__Member__Name__Title}>{ t(title)  }</p>
        <p className={ Details__Member__Name}>{ capitalizeFirstLetter( getMemberName(metaDataApiStore?.selectedEntity as any) as string )  }</p>
        <div>       

          <div className={'flex flex-column'} >
              <span className={Details__Member__Content}>
                { (metaDataApiStore?.selectedEntity as any)?.memberExternalId }
              </span>
              <span className={Details__Member__Content__Name}>
             <span className={Details__Member__Content__Name}>{ t( fields['memberId'] )}</span>
              </span>
          </div>
          

          <div className={'flex flex-column'} >
            <span className={Details__Member__Content}>
              
              {
                (
                  (!(metaDataApiStore?.selectedEntity as any)?.address || !(metaDataApiStore?.selectedEntity as any)?.city)
                    ? '-'
                    : `${(metaDataApiStore?.selectedEntity as any)?.address}, ${ (metaDataApiStore?.selectedEntity as any)?.city }`
                ) 
              }
              </span>
              <span className={Details__Member__Content__Name}>
              <span className={Details__Member__Content__Name}>{ t( fields['address'] )}</span>
              </span>
          </div>
          

          <div className={'flex flex-column'} >
              <span className={Details__Member__Content}>
              { (metaDataApiStore?.selectedEntity as any)?.phone || '-' }
              </span>
              <span className={Details__Member__Content__Name}>
              <span className={Details__Member__Content__Name}>{ t( fields['phone'] )}</span>
              </span>
          </div>
          

          <div className={'flex flex-column'} >
              <span className={Details__Member__Content}>
              { (metaDataApiStore?.selectedEntity as any)?.email || '-' }
              </span>
              <span className={Details__Member__Content__Name}>
              <span className={Details__Member__Content__Name}>{ t( fields['email'] )}</span>
              </span>
          </div>
          
          


          <div className={'flex flex-column'} >
              <span className={Details__Member__Content}>
                { getMemberSites() }
              </span>
              <span className={Details__Member__Content__Name} style={{ marginBottom: 0 }}>
              { t(sitesNumber) }
              </span>
            </div>
      </div>
      </div>
          
      </div>
  )
}

export default MemberDetailsParameters;
