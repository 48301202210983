import { IconWrapper } from "@flexo/general";
import { useTranslation } from "react-i18next";

import styles from './../alert.module.scss';
import { AlertContext, AlertTypes } from "@flexo/providers";
import { useContext } from "react";


export function AlertUnexpectedError() {

  const { t } = useTranslation();
  const alertContent = t('alerts.unexpected_error', { returnObjects: true }) as any;
  const { setAlertStore } = useContext( AlertContext)

  return (
    <div id="alert-unexpected-error" className={ styles.Alert__Unexpected__Error}>

      <div className={styles.Alert__Title} >
      <div>
            <IconWrapper iconName="alert" />
            {alertContent.title}
        </div>
        <div
          onClick={() =>
            setAlertStore({
              type: AlertTypes.ResetAlert,
              payload: null as any,
            })
          }
        >
          <IconWrapper
            className={styles.Alert__Title__Close}
            iconName="cross"

          />
        </div>
      </div>
      <div className={styles.Alert__Content} >
        {alertContent.message} { }
      </div>

    </div>
  )
}