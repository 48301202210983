import { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type ICalendarDisplay = 'day' | 'week' | 'month' | 'year';


export interface ICalendarProvider {
  calendarDisplay: ICalendarDisplay;
  selectedDate: Date;
  selectedWeek: {
    startDate: Date | null;
    endDate: Date | null;
  };
  showCalendar: boolean;
  customInteraction: ICalendarDisplay | undefined;
  previousCalendarDisplay: ICalendarDisplay | undefined;
  currentlySelectedDateType: ICalendarDisplay;
  showCustomInteraction: {
    day: boolean;
    week: boolean;
    month: boolean;
  };
  prevSelectedDate: Date | null;
  buttonSwitch: boolean;
}

export type CalendarStore = ICalendarProvider;

export enum CalendarTypes {
  SetSelectedDate = 'SET_SELECTED_DATE',
  SetSelectedWeek = 'SET_SELECTED_WEEK',
  SetShowCalendar = 'SET_SHOW_CALENDAR',
  SetCustomInteraction = 'SET_CUSTOM_INTERACTION',
  SetPreviousCalendarDisplay = 'SET_PREVIOUS_CALENDAR_DISPLAY',
  SetCurrentlySelectedDateType = 'SET_CURRENTLY_SELECTED_DATE_TYPE',
  SetShowCustomInteraction = 'SET_SHOW_CUSTOM_INTERACTION',
  SetPrevSelectedDate = 'SET_PREV_SELECTED_DATE',
  SetCalendarDisplay = 'SET_CALENDAR_DISPLAY',
  SetButtonSwitch = 'SET_BUTTON_SWITCH',
  ResetCalendarStore = 'RESET_CALENDAR_STORE',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [CalendarTypes.SetSelectedDate]: { value: Date };
  [CalendarTypes.SetSelectedWeek]: {
    value: { startDate: Date | null; endDate: Date | null };
  };
  [CalendarTypes.SetShowCalendar]: { value: boolean };
  [CalendarTypes.SetCustomInteraction]: { value: ICalendarDisplay | undefined };
  [CalendarTypes.SetPreviousCalendarDisplay]: { value: ICalendarDisplay };
  [CalendarTypes.SetCurrentlySelectedDateType]: { value: string };
  [CalendarTypes.SetShowCustomInteraction]: {
    value: {
      day: boolean;
      week: boolean;
      month: boolean;
    };
  };
  [CalendarTypes.SetPrevSelectedDate]: { value: Date | null };
  [CalendarTypes.SetCalendarDisplay]: { value: ICalendarDisplay };
  [CalendarTypes.SetButtonSwitch]: { value: boolean };
  [CalendarTypes.ResetCalendarStore]: { value: ICalendarProvider };
};

export interface ContextValues {
  calendarStore: CalendarStore;
  setCalendarStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
