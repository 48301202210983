import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  DataStore,
  StorageActions,
} from './data-provider.interfaces';
import { reducer } from './data-provider.reducer';
import { storeInit } from './initialState';

type Reducer<S, A> = (state: S, action: A) => S;

export let globalSetDataStore: any;
export let globalDataStore: any;

export const DataContext = createContext<ContextValues>({
  dataStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDataStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [dataStore, dispatch] = useReducer<Reducer<DataStore, StorageActions>>(
    reducer as any,
    storeInit
  );

  globalSetDataStore = dispatch;
  globalDataStore = dataStore;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DataContext.Provider value={{ dataStore, setDataStore: dispatch }}>
      {children}
    </DataContext.Provider>
  );
};

export default StoreProvider;
