import {
  CommunitiesOverviewResolver,
  CommunitiesResolver,
  CommunityResolver,
  EntitiesResolver,
  KpiSiteResolver,
  MemberResolver,
  MembersResolver,
  SiteMetaResolver,
  KpiCommunityResolver,
  CommunityRankingResolver,
  SiteOverviewResolver,
} from '@flexo/atoms';

export const getResolver = (resolverName: string) => {
  switch (resolverName) {
    case 'MembersResolver':
      return MembersResolver;
    case 'CommunitiesResolver':
      return CommunitiesResolver;
    case 'CommunitiesOverviewResolver':
      return CommunitiesOverviewResolver;
    case 'MemberResolver':
      return MemberResolver;
    case 'CommunityResolver':
      return CommunityResolver;
    case 'EntitiesResolver':
      return EntitiesResolver;
    case 'KpiSiteResolver':
      return KpiSiteResolver;
    case 'KpiCommunityResolver':
      return KpiCommunityResolver;
    case 'CommunityRankingResolver':
      return CommunityRankingResolver;
    case 'SiteOverviewResolver':
      return SiteOverviewResolver;
    default:
      return null;
  }
};

export const getMetaResolver = (resolverName: string) => {
  switch (resolverName) {
    case 'SiteMetaResolver':
      return SiteMetaResolver;
    // case 'CommunityMetaResolver':
    //   return CommunityMetaResolver;
    default:
      return 'noData';
  }
};
