import { IconWrapper } from '@flexo/general';
import styles from './page-header.module.scss';

/* eslint-disable-next-line */
export interface PageHeaderProps {
  title: string;
  subtitle: string;
  back?: string;
  backAction?(): void;
}

export function PageHeader({
  title,
  subtitle,
  back,
  backAction,
}: PageHeaderProps) {
  return (
    <div className={styles.PageHeader}>
      <div className={styles.PageHeader__Back}>
        {back && backAction && (
          <button
            className={`${styles.PageHeader__Back__Button} heading3`}
            onClick={() => backAction()}
          >
            <IconWrapper iconName="chevron-left" />
            {back}
          </button>
        )}
      </div>
      <span className={`${styles.PageHeader__Title} heading2`}>{title}</span>{' '}
      <span className={`${styles.PageHeader__Subtitle} heading5`}>
        {subtitle}
      </span>
    </div>
  );
}

export default PageHeader;
