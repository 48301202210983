import React, { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type IModalContentTypes = 'BASIC' | 'SWIPING' | 'PROFILE' | 'CARD';

export interface IModal {
  open?: boolean;
  content?: IModalContentTypes;
}

export interface ModalProvider {
  modal: IModal;
}

export type ModalStore = ModalProvider;

export enum ModalTypes {
  OpenModal = 'OPEN_MODAL',
  CloseModal = 'CLOSE_MODAL',
  
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [ModalTypes.OpenModal]: {
    value: string;
  };
  [ModalTypes.CloseModal];
};

export interface ContextValues {
  modalStore: ModalStore;
  setModalStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
