import React, { useState, useContext, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import {
  UserContext,
  ModulesTypes,
  ModulesContext,
  MetaDataApiContext,
  AlertContext,
  AlertTypes,
  UserTypes,
  MetaDataApiTypes,
  CalendarContext, KpiApiContext, KpiDataTypes
} from '@flexo/providers';


import {
  FirebaseService,
  formatDate,
  formatEndOfWeek,
  formatStringToDate,
  getFormattedEndOfMonth,
  getFormattedNextDay,
  getLastDayOfYear,
  getTimeGranularity,
  getTimeGranularityOverview,
  httpService,
} from '@flexo/general';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

let round = 0;
let isLoading = false;

export interface initProps {
  setModulesStore: any;
  metaDataApiStore: any;
  setMetaDataApiStore?: any;
  setAlertStore?: any;
  setUserStore?: any;
}

export const CommunityRankingResolver = () => {
  const { t } = useTranslation();

  const { setUserStore } = useContext(UserContext);
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const { setAlertStore } = useContext(AlertContext);
  const { setKpiApiStore } = useContext(KpiApiContext);
  const { calendarStore } = useContext(CalendarContext);

  const { selectedDate, selectedWeek, currentlySelectedDateType } =
    calendarStore;

  const dates = {
    day: {
      dateFrom: formatDate(selectedDate),
      dateTo: getFormattedNextDay(selectedDate),
    },
    week: {
      dateFrom: formatDate(selectedWeek.startDate as Date),
      dateTo: formatEndOfWeek(selectedWeek.endDate as Date),
    },
    month: {
      dateFrom: formatDate(selectedDate),
      dateTo: formatStringToDate(
        getFormattedEndOfMonth(new Date(selectedDate))
      ),
    },
    year: {
      dateFrom: formatDate(selectedDate),
      dateTo: formatStringToDate(getLastDayOfYear(selectedDate)),
    },
  };

  async function init() {

    isLoading = true

    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      
      setUserStore({
        type: UserTypes.SetUserLogOut,
        payload: {
          value: true,
        }
      })

      return;
    }

    const [consumptionRanking, economicsRanking] = await Promise.all([
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/ranking/${metaDataApiStore.selectedEntityId}`,
          data: {
            ...dates[currentlySelectedDateType],
            timeGranularity: getTimeGranularityOverview(
              currentlySelectedDateType
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            kpiList: [
              'self_consumption_direct',
              'self_consumption_battery',
              'from_shared',
              'from_grid',
            ],
            rankingKpi: 'consumption',
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/ranking/${metaDataApiStore.selectedEntityId}`,
          data: {
            ...dates[currentlySelectedDateType],
            timeGranularity: getTimeGranularityOverview(
              currentlySelectedDateType
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            rankingKpi: 'revenues',
            kpiList: ['revenues']
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),
      
    ]);

    if (consumptionRanking && economicsRanking) {
      setKpiApiStore({
        type: KpiDataTypes.SetKpiDataCommunityRanking,
        payload: {
          value: {
            consumptionRanking,
            economicsRanking,
          },
        },
      });
    }

    round++;
    isLoading = false

  }

  useEffect(() => {
    if (round === 0) {
      init();
    }
  }, [metaDataApiStore?.selectedEntityId]);

  useEffect(() => {
    if (round > 0 && isLoading === false) {
      init();
    }
  }, [calendarStore.selectedDate]);

  return <></>;
};
