import React, { createContext, useReducer } from 'react';
import {
  CommunityViewStore,
  ContextValues,
  Props,
  StorageActions,
} from './community-view-provider.interfaces';
import { reducer } from './community-view.reducer';

type Reducer<S, A> = (state: S, action: A) => S;

export const storeInit: CommunityViewStore = {
  notifications: [],
  sites: [],
  communities: [],
  energyData: [],
  energyDataOverview: [],
  selectedSite: {},
  selectedCommunity: {},
  loading: true,
  maxConsumption: {},
  maxProduction: {},
  maxPollution: {},
  maxSufficiency: {},
  divider: 0,
  overviewUnit: 'kWh',
  detailUnit: 'kWh',
  energyDataComparision: [],
  dataInTimeSpan: true,
  economics: {}
};

export const CommunityViewContext = createContext<ContextValues>({
  communityViewStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCommunityViewStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [communityViewStore, dispatch] = useReducer<
    Reducer<CommunityViewStore, StorageActions>
  >(reducer as any, storeInit);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CommunityViewContext.Provider
      value={{ communityViewStore, setCommunityViewStore: dispatch }}
    >
      {children}
    </CommunityViewContext.Provider>
  );
};

export default StoreProvider;
