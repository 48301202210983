import { DonutGraph, Shimmer } from '@flexo/atoms';
import styles from './community-stats.module.scss';

import { useContext, useEffect, useState } from 'react';
import {
  AlertContext,
  CommunityViewContext,
  ThemeContext,
} from '@flexo/providers';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable-next-line */

export interface CommunityStatsSkeletonData {
  totalAmount: string;
  totalAmounttype: string;
  colors: string[];
  keys: string[];
  title: string;
  percentage: number;
}
export interface CommunityStatsSkeletonProps {
  from_network: CommunityStatsSkeletonData;
  to_network: CommunityStatsSkeletonData;
  shared: CommunityStatsSkeletonData;
}
export const placeholderSkeletonData = [{ label: '', value: 100 }];

export function CommunityStatsSkeleton(props: any) {
  const [networkConnected, setNetworkConnected] = useState(true);

  const { CommunityStats__Stat } = styles;
  const { ThemeStore } = useContext(ThemeContext);
  const { communityViewStore } = useContext(CommunityViewContext);
  const { alertStore } = useContext(AlertContext);
  const { t } = useTranslation();
  const formatData = () => {
    const output: any[] = [];

    for (const key in props?.props || props) {
      if (key !== 'dataInTimeSpan' && key !== 'gradientBorder') {
        const item = {
          title: key,
          values: [{ label: '', value: 100 }],
        };
        output.push(item);
      }
    }

    return output;
  };

  useEffect(() => {
    // Check if the alert type and title matches the specific alert
    if (
      alertStore.alert.type === 'warning' &&
      (alertStore.alert as any).title === t('errors.no_connection.title')
    ) {
      setNetworkConnected(false);
    }
  }, [alertStore, t]);

  const formatGraphData = (item) => {
    return Object.values(item.values);
  };

  const getTotalAmount = () => {
    return '-';
  };

  const getColors = (type) => {
    return ['#F0F0F0'];
  };

  return (
    <div
      className={`${styles.CommunityStats} ${
        props.gradientBorder && styles.CommunityStats__GradientBorder
      }`}
      style={{ position: 'relative' }}
    >
      <div className={styles.CommunityStats}>
        {formatData().map((item, index) => {
          return (
            <div className={CommunityStats__Stat} key={`${uuidv4()}`}>
              <DonutGraph
                width={105}
                height={105}
                graphData={formatGraphData(item)}
                colors={getColors(item.title)}
                totalAmount={getTotalAmount() as any}
                key={`${uuidv4()}`}
                totalAmounttype={'kWh'}
                animate={false}
                selectedId={null}
                setSelectedId={null}
              />
              <div className={styles.CommunityStats__Stat__Information}>
                <div
                  className={`heading5 ${styles.CommunityStats__Stat__Title}`}
                >
                  {t(`widgets.communityStats.${item.title}`)}
                </div>
                <div
                  className={`detail ${styles.CommunityStats__Stat__Description}`}
                >
                  {t(`widgets.communityStats.me`, {
                    percentage: '-',
                  })}
                </div>
              </div>
            </div>
          );
        })}

        {networkConnected &&
          communityViewStore.loading &&
          props.dataInTimeSpan === true && <Shimmer />}
      </div>{' '}
    </div>
  );
}

// export default CommunityStatsSkeleton;
