import React, { useContext, useState } from 'react';
import styles from './left-drawer.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { Drawer } from '@mui/material';
import { LeftDrawerContext } from '@flexo/providers';



export const LeftDrawer = () => {
  
  const { LeftDrawerStore } = useContext(LeftDrawerContext)

  return (
    <div key={ `LeftDrawer_${uuidv4()}` }>
      <Drawer open={LeftDrawerStore.leftDrawer.show}>
      test content for drawer
      </Drawer>
    </div>
  );
};

export default LeftDrawer;
