import { v4 as uuidv4 } from 'uuid';

import styles from './member-sites-list.module.scss';
import { useContext, useRef, useState } from 'react';
import { DataContext, MetaDataApiContext, ModulesContext, SiteViewContext, ThemeContext } from '@flexo/providers';
import { useTranslation } from 'react-i18next';
import { IconWrapper, capitalizeFirstLetter } from '@flexo/general';
import { navigateToSite } from '@flexo/atoms';


export function MemberSitesList(props: any) {
  const {
    Sites__List__Wrapper,
    Sites__List__Item,
    Sites__List__Item__Inactive,
    Sites__List__Wrapper__Flat,
    Tall,
    Sites__List__Item__Name,
    Sites__List__Item__Pod,
    Sites__List__Item__Detail,
    Sites__List__Item__Label,
    Sites__List__Expand__Button__Wrapper,
    Sites__List__Expand__Button,
    Sites__List__Wrapper__Expanded,
  } = styles;
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext)
  const { modulesStore, setModulesStore } = useContext(ModulesContext);

  const { t } = useTranslation()

  const [expanded, setExpanded] = useState(false)

  return (
    <div
      key={`member_sites_list_${uuidv4()}`}
      className={`
        ${['sm', 'xs', 'xxs'].includes(modulesStore.breakpoint)
          ? Sites__List__Wrapper__Flat
          : Sites__List__Wrapper} 
          ${modulesStore.breakpoint === 'sm' && Tall}
          ${expanded && Sites__List__Wrapper__Expanded}
        `}
    >

      {

          ((metaDataApiStore?.selectedEntity as any)?.sites || [])
          .map((site: any, _index: number) => {
            return (
              !expanded && _index >= 4
                ? <></>
                : <div key={`${site.id}_${uuidv4()}`} className={`${Sites__List__Item} ${ new Date(site?.endDate) < new Date() && Sites__List__Item__Inactive }`}>
                  {/* { JSON.stringify( site )} */}
                  <p
                    onClick={() => navigateToSite(site, setMetaDataApiStore, setModulesStore, 'site')}
                    className={`heading4 ${Sites__List__Item__Name}`}
                  >
                    {site.siteExternalId}
                    <IconWrapper iconName='chevron-right' />
                  </p>

                  <p className={Sites__List__Item__Pod}>{capitalizeFirstLetter(site.siteName)}</p>

                  <p className={Sites__List__Item__Detail}>{site.address}, {site.city}</p>
                  <p className={Sites__List__Item__Label}>{t('widgets.memberSitesDetails.address')}</p>

                  <p className={Sites__List__Item__Detail}>{site.startDate} - {new Date(site?.endDate) < new Date() ? site?.endDate : ''}</p>
                  <p className={Sites__List__Item__Label}>{t('widgets.memberSitesDetails.owned')}</p>

                  <p className={Sites__List__Item__Detail} >{capitalizeFirstLetter(site.type)}</p>
                  <p className={Sites__List__Item__Label} >{t('widgets.memberSitesDetails.type')}</p>
                </div>
            )
          })
      }

      {
        ((metaDataApiStore?.selectedEntity as any)?.sites || []).length > 4 &&
        <div className={Sites__List__Expand__Button__Wrapper}>
          <div className={Sites__List__Expand__Button} onClick={() => setExpanded(!expanded)}>
            {
            expanded ? t('widgets.memberSitesDetails.collapse') : t('widgets.memberSitesDetails.expand', { sitesNumber: ((metaDataApiStore?.selectedEntity as any)?.sites || [])?.length || 0 })  
            }
          </div>
        </div>
      }

    </div>
  )


}

export default MemberSitesList;
