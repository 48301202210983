import { useContext, useEffect } from 'react';

import {
  UserContext,
  ModulesTypes,
  ModulesContext,
  MetaDataApiContext,
  AlertTypes,
  AlertContext,
  UserTypes,
} from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';
import { MetaDataApiTypes, getQueryParams } from '@flexo/providers';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

let round = 0;

export interface SiteOverviewInitProps {
  setMetaDataApiStore: any;
  metaDataApiStore: any;
  setModulesStore: any;
  setAlertStore: any;
  setUserStore: any;
}

export async function SiteOverviewInit(props: SiteOverviewInitProps) {
  const {
    setMetaDataApiStore = null,
    metaDataApiStore = null,
    setModulesStore = null,
    setAlertStore = null,
    setUserStore = null,
  } = props;

  const idToken = await firebaseService.getIdToken();

  if (!idToken) {
    setAlertStore({
      type: AlertTypes.SetAlert,
      payload: {
        value: {
          type: 'ALERT_SESSION_EXPIRED',
        },
      },
    });

    setUserStore({
      type: UserTypes.SetUserLogOut,
      payload: {
        value: true,
      },
    });

    return;
  }

  if ( !metaDataApiStore?.selectedEntity && Object.keys(metaDataApiStore?.entities)?.length > 0 ) {
    
    const [_url, _root, _module = null, id = null] = getQueryParams();

    
    const site = (metaDataApiStore?.entities?.site)?.find(site => site.siteId === id);

    if (!site) { return }

    setMetaDataApiStore({
      type: MetaDataApiTypes.SetSelectedEntity,
      payload: {
        value: {
          selectedEntity: site,
          id: site?.siteId,
          entityType: 'site',
        },
      },
    });
  }

  
}

export const SiteOverviewResolver = () => {
  const { setUserStore } = useContext(UserContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { setMetaDataApiStore, metaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setAlertStore } = useContext(AlertContext);

  useEffect(() => {
    // if (round === 0) {
      SiteOverviewInit({
        setMetaDataApiStore,
        metaDataApiStore,
        setModulesStore,
        setAlertStore,
        setUserStore,
      });
      round++;
    // }
  }, [ metaDataApiStore?.entities]);

  return <></>;
};
