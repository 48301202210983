/* eslint-disable no-case-declarations */
import { AlertStore, StorageActions } from './alert-provider.interfaces';
import { storeInit } from './initialState';

export const reducer = (state: AlertStore, action: StorageActions) => {
  switch (action.type) {
    case 'SET_ALERT':

      
      return {
        ...state,
        alert: {
          ...action.payload.value,
          visible: true,
          show: true,
        },
      };
    case 'RESET_ALERT':
      return storeInit;
    case 'HIDE_ALERT':
      return {
        alert: {
          ...storeInit.alert,
          visible: true,
          show: false,
        },
      };
    case 'SET_INPUT':
      return {
        ...state,
        alert: {
          ...state.alert,
          input: {
            ...(state.alert as any).input,
            value: action.payload.value,
          },
        },
      };
    default:
      return state;
  }
};
