
import React, { useContext, useEffect, useState, } from 'react';

import styles from './header-web.module.scss';
import { DataContext, MetaDataApiContext, ModulesContext } from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import { BreadCrumbsDropDown, ProfileButton, Search, navigateToCommunities, navigateToCommunity } from '@flexo/atoms';

import './header-web.module.scss';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__BreadCrumb,
} = styles;

function MemberNavigation(props: any) {
  
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null, breadCrumbs = null } = props;
  const { t } = useTranslation()

  const communities = (metaDataApiStore?.entities as any)?.community || [];

  function getMemberCommunity() {
    const memberCommunityId = (metaDataApiStore?.selectedEntity as any)?.communityId || 'missing_id';
    const foundCommunity = communities.find((community: any) => (community?.communityId === memberCommunityId ));

    if ( !foundCommunity) {
      return communities?.[0]?.communityId;
    } else {
      return foundCommunity;
    }

  }

  function getMemberCommunityName() {
    const foundCommunity = getMemberCommunity();
    return (foundCommunity?.communityName) || communities?.[0]?.communityName || 'Community name not found';
  }

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>
        
        <div className={Navigation}>
          <label className={ `${styles.clickable}`} onClick={() => navigateToCommunities( setModulesStore)}>{t(`widgets.header.communities`)}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right'/></span>
          <label className={ `${styles.clickable}`} onClick={() => navigateToCommunity( getMemberCommunity(),setMetaDataApiStore, setModulesStore)} >{ getMemberCommunityName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right'/></span>

          
      {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          }
      
    </div>


      </div>
      <div className={Header__Web__Right}>
        {
          download && <>
            <IconWrapper iconName={'chevron-encircled'} />
          </>
        }
        {
          searchBar &&
          <Search />
        }
        {
        profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(MemberNavigation);
