import React, { createContext, useReducer } from 'react';
import {
  ContextValues,
  Props,
  ModulesStore,
  StorageActions,
} from './modules-provider.interfaces';
import { reducer } from './modules-provider.reducer';
import { storeInit } from './initialState';

type Reducer<S, A> = (state: S, action: A) => S;

export let globalSetModulesStore: any;
export let globalModulesStore: any;

export const ModulesContext = createContext<ContextValues>({
  modulesStore: storeInit,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModulesStore: () => {},
});

const StoreProvider: React.FC<Props> = ({ children }) => {
  const [modulesStore, dispatch] = useReducer<Reducer<ModulesStore, StorageActions>>(
    reducer as any,
    storeInit
  );

  globalSetModulesStore = dispatch;
  globalModulesStore = modulesStore;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModulesContext.Provider value={{ modulesStore, setModulesStore: dispatch }}>
      {children}
    </ModulesContext.Provider>
  );
};

export default StoreProvider;
