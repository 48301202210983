import { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  auth,
  FirebaseService,
  httpService,
  IconWrapper,
  REGEX,
} from '@flexo/general';
import { Textfield, Button } from '@flexo/atoms';
import { useTranslation } from 'react-i18next';

import styles from './update-credentials-form.module.scss';
import {
  AlertContext,
  AlertTypes,
  IRightDrawerTypes,
  RightDrawerContext,
  UserContext,
  UserTypes,
} from '@flexo/providers';

const firebaseService = new FirebaseService();

const ENV = (import.meta as any).env;

const debounce = (fn, delay = 100) => {

  const handler = setTimeout(() => {
    fn();
  }, delay);

  return () => {
    clearTimeout(handler);
  };
};

let ranOnce = false;
let _formTouched = false;
let _formError = false;
let _formRequestSent = false;

let _previousFormData = {
  displayName: '',
  email: '',
  password: '',
  password_confirm: '',
};

let _formData = {
  displayName: '',
  email: '',
  password: '',
  password_confirm: '',
};

export function UpdateCredentialsForm() {
  const formRef = useRef<any>(null);

  const { t } = useTranslation();
  const { title, subtitle, inputs, buttonLabel } = t(
    'drawers.update_credentials',
    { returnObjects: true }
  ) as any;

  const { setAlertStore } = useContext(AlertContext);
  const { setRightDrawerStore } = useContext(RightDrawerContext);

  const [formData, setFormData] = useState(_formData);

  const [formTouched, setFormTouched] = useState(_formTouched);
  const [ didDismiss, setOnDidDismiss ] = useState(false);
  const [ samePassword, setSamePassword ] = useState(true);
  const [formError, setFormError] = useState(_formError);
  const [formRequestSent, setFormRequestSent] = useState(_formRequestSent);

  const { userStore, setUserStore } = useContext(UserContext);

  const [checkList, setChecklist] = useState<any>(
    Object.values(inputs.checkList).reduce(
      (prev: any, next: any) => ({ ...prev, [next.name]: false }),
      {}
    )
  );

  const formRules = Object.values(inputs.checkList).map((checkItem: any) => ({
    name: checkItem.name,
    rule: checkItem.rule,
  }));

  const [toggleIcons, setToggleIcons] = useState({
    password: 'hide',
    password_confirm: 'hide',
  });

  const [toggleTypes, setToggleTypes] = useState({
    password: 'password',
    password_confirm: 'password',
  });

  async function handleInputChange(event: any) {
    if (formTouched === false) {
      _formTouched = true;
      setFormTouched(_formTouched);
    }

    if (didDismiss === true) {
      setOnDidDismiss(false);
    }

    const { name, value } = event.target;

    if (name === 'password_confirm' && samePassword === false) {
      setSamePassword(true);
    }

    _formData = { ..._formData, [name]: value };
    setFormData(_formData);

    if (name === 'password') {
      checkValidation(value);
    }
  }

  function togglePasswordVisibility(inputName: string) {
    setToggleIcons({
      ...toggleIcons,
      [inputName]: toggleIcons[inputName] === 'hide' ? 'show' : 'hide',
    });

    setToggleTypes({
      ...toggleTypes,
      [inputName]: toggleTypes[inputName] === 'password' ? 'text' : 'password',
    });
  }

  function checkValidation(value: string) {
    const temp_checklist = {};
    formRules.forEach((rule: any) => {
      console.log( 'rule', rule);
      const regex = new RegExp(rule.rule);
      console.log( 'regex', regex);
      temp_checklist[rule.name] = regex.test(value);
    });
    setChecklist(temp_checklist);
  }

  function checkIfSubmitIsDisabled() {
    if (JSON.stringify(_previousFormData) === JSON.stringify(formData)) {
      return true;
    }

    if (didDismiss === true) {
      return true;
    }

    if (formTouched === false) {
      return true;
    }

    if (formData.displayName === '') {
      return true;
    }

    if (REGEX.email.test(formData.email) === false) {
      return true;
    }

    if (formData.password !== formData.password_confirm) {
      return true;
    }

    if (formData.password !== '') {
      if (REGEX.password.test(formData.password) === false) {
        return true;
      }
    }
  }

  async function submitForm() {
    _formRequestSent = true;
    setFormRequestSent(_formRequestSent);

    const tokenID = await firebaseService.getIdToken().catch((err: any) => {
      // console.warn(err);
      return null;
    });

    if (!tokenID) {
      setAlertStore({
        type: AlertTypes.ResetAlert,
      });

      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      setUserStore({
        type: UserTypes.SetUserLogOut,
        payload: {
          value: true,
        },
      });

      return;
    }

    const response = await httpService
      .put({
        idToken: tokenID,
        url: `${ENV.VITE_APP_BASE_URL}/auth/v1_1/update-profile`,
        // url: `${ENV.VITE_APP_BASE_URL}/auth/v1/update-profile?tenant_id=${ENV.VITE_APP_TENANT}`,
        data: {
          ...(formData?.displayName !== ''
            ? {
                display_name: formData.displayName,
              }
            : {}),
          ...(formData?.email !== ''
            ? {
                email: formData.email,
              }
            : {}),
          ...(formData?.password !== ''
            ? {
                new_password: formData.password,
              }
            : {}),
        },
      })
      .then((resp: any) => ({
        status: resp.status,
        statusText: resp?.statusText || 'Unknown status text',
        message: resp?.data?.message || 'Unknown message',
      }))
      .catch((error) => {
        // console.warn(error);
        return {
          status: error?.response?.status || 500,
          statusText: error?.response?.statusText || 'Unknown error',
        };
      });

    _formRequestSent = false;
    setFormRequestSent(_formRequestSent);

    if (response.status > 200) {
      _formError = true;
      setFormError(_formError);

      return response.status;
    } else {
      _formError = false;
      setFormError(_formError);

      setAlertStore({
        type: AlertTypes.ResetAlert,
      });

      await new Promise((resolve) => setTimeout(resolve, 500));
      
      const userEmail = await firebaseService.getCurrentUserEmail();

      if ( formData?.email === userEmail && formData?.password === '') {

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'BANNER_CHANGE_USERNAME_SUCCESS'
            }
          },
        });

        const newUserData = {
          ...userStore?.user,
        }

        newUserData.auth.currentUser.displayName = formData.displayName;

        setUserStore({
          type: UserTypes.SetUser,
          payload: {
            value: newUserData
          }
        })

        

        return;

      } else {

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'BANNER_CONFIRM_CREDENTIALS_CHANGES_SUCCESS',
            },
          },
        });
  
        await new Promise((resolve) => setTimeout(resolve, 3000));
  
        setUserStore({
          type: UserTypes.SetUserLogOut,
          payload: {
            value: true,
          },
        });

        // TODO FEDE: implement login banner

        // await new Promise((resolve) => setTimeout(resolve, 1000));
  
        // setAlertStore({
        //   type: AlertTypes.SetAlert,
        //   payload: {
        //     value: {
        //       type: 'BANNER_PASSWORD_RESET_SUCCESS'
        //     }
        //   },
        // });

      }

      

      return;
    }
  }

  function askForSubmissionConfirmation() {
    setAlertStore({
      type: AlertTypes.SetAlert,
      payload: {
        value: {
          type: 'BANNER_CONFIRM_CREDENTIALS_CHANGES',
          callback: () => submitForm(),
          dismissCallback: () => setOnDidDismiss(true),
        },
      },
    });
  }

  function checkConfirmationPassword(e: any) {

    if (e.key === 'Backspace') {
      setSamePassword(true);
    } else {
      debounce(() => {
        if (formData.password !== formData.password_confirm) {
          setSamePassword(false);
        } else {
          setSamePassword(true);
        }
      }, 500)
    }
  }
    

  function close() {
    setRightDrawerStore({
      type: IRightDrawerTypes.ResetDrawer,
      payload: {} as any
    })

    _formData = _previousFormData

  }

  useEffect(() => {
    if (ranOnce) return;
    
    _formData = {
      ..._formData,
      email: userStore?.user?.auth?.currentUser?.email,
      displayName:
        userStore?.user?.auth?.currentUser?.displayName ||
        userStore?.user?.auth?.currentUser?.uid,
    };

    _previousFormData = _formData;

    setFormData(_formData);

    ranOnce = true;
  }, []);

  return (
    <div className={styles.UpdateCredentialsForm}>

      <div className={styles.UpdateCredentialsForm__Header}>
        <div className={styles.UpdateCredentialsForm__Header__Title}>
          {title}
          <div className={styles.UpdateCredentialsForm__Header__Title__Close} onClick={() => close()}>
            <IconWrapper iconName={'cross'} />
          </div>
        </div>
        <div className={styles.UpdateCredentialsForm__Header__Subtitle}>
          {subtitle}
        </div>
      </div>

      <form
        id="account_creation_web_form"
        className={styles.UpdateCredentialsForm__Form}
        ref={formRef}
      >
        {/* generalities */}

        <div className={styles.UpdateCredentialsForm__Form__Input}>
          <Textfield
            label={inputs.generalities.label}
            name={inputs.generalities.name}
            type={inputs.generalities.type}
            placeholder={inputs.generalities.placeholder}
            onPaste={(e) => false}
            onCopy={(e) => false}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            autoComplete="off"
            defaultValue={formData[inputs.generalities.name]}
            onChange={(ev: any) => handleInputChange(ev)}
          />
        </div>

        {/* email */}

        <div className={`${styles.UpdateCredentialsForm__Form__Input} ${styles.UpdateCredentialsForm__Form__Input__Disabled}`}>
          <Textfield
            label={inputs.email.label}
            name={inputs.email.name}
            type={inputs.email.type}
            placeholder={inputs.email.placeholder}
            disabled={true}
            onPaste={(e) => false}
            onCopy={(e) => false}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            autoComplete="off"
            defaultValue={formData[inputs.email.name]}
            // onChange={(ev: any) => handleInputChange(ev)}
            // additionalLabel={inputs.email.upperDescritpion}
            additionalLabelStyles={
              styles.UpdateCredentialsForm__Form__Input__UpperLabel
            }
          />
        </div>
        {/* password */}

        <div className={styles.UpdateCredentialsForm__Form__Input}>
          <Textfield
            label={inputs.password.label}
            name={inputs.password.name}
            type={toggleTypes[inputs.password.name]}
            placeholder={inputs.password.placeholder}
            onPaste={(e) => false}
            onCopy={(e) => false}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            autoComplete="off"
            defaultValue={formData[inputs.password.name]}
            onChange={(ev: any) => handleInputChange(ev)}
            icon={toggleIcons[inputs.password.name]}
            onIconClick={() => togglePasswordVisibility(inputs.password.name)}
          />
        </div>

        {/* generalities */}

        <div className={styles.UpdateCredentialsForm__Form__Input}>
          <div className={styles.UpdateCredentialsForm__Form__Checklist__Title}>
            {inputs.checkList_title}
          </div>

          {inputs.checkList.map((checkItem) => (
            <div
              key={uuidv4()}
              className={styles.UpdateCredentialsForm__Form__Checklist__Item}
            >
              <span
                className={
                  styles.UpdateCredentialsForm__Form__Checklist__Item__Checkbox
                }
              >
                {checkList[checkItem.name] === true ? (
                  <IconWrapper iconName={'chevron-encircled_outline'} />
                ) : (
                  <IconWrapper iconName={'ellipse'} />
                )}
              </span>
              <p
                className={
                  styles.UpdateCredentialsForm__Form__Checklist__Item__Text
                }
              >
                {checkItem.label}
              </p>
            </div>
          ))}
        </div>

        {/* password_confirm */}

        <div className={styles.UpdateCredentialsForm__Form__Input}>
          <Textfield
            label={inputs.password_confirm.label}
            name={inputs.password_confirm.name}
            type={toggleTypes[inputs.password_confirm.name]}
            placeholder={inputs.password_confirm.placeholder}
            onPaste={(e) => false}
            onCopy={(e) => false}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            autoComplete="off"
            defaultValue={formData[inputs.password_confirm.name]}
            onChange={handleInputChange}
            icon={toggleIcons[inputs.password_confirm.name]}
            onIconClick={() =>
              togglePasswordVisibility(inputs.password_confirm.name)
            }
            // onKeyUp={e => console.log(e)}
            onKeyUp={ ( e ) => checkConfirmationPassword(e)}
            underline={samePassword === false ? 'visible' : 'hidden'}
            webError={!samePassword}
            descriptionFault={!samePassword ? inputs.password_confirm.descriptionFault : ''}
          />
        </div>
      </form>

      <div className={styles.UpdateCredentialsForm__Footer}>
        <Button
          className={styles.UpdateCredentialsForm__Footer__Button}
          label={buttonLabel}
          onClick={() => askForSubmissionConfirmation()}
          disabled={checkIfSubmitIsDisabled()}
        />
      </div>
    </div>
  );
}
