import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import styles from './button.module.scss';
import { IButton } from '@flexo/atoms';

export const Button: React.FC<IButton> = ({
  className,
  variant = 'primary',
  size = 'small',
  color = 'primary',
  label,
  icon,
  disabled,
  ...props
}) => {
  const { Button__IconContainer, Button__Icon } = styles;

  return (
    <button
      type="button"
      className={` heading4 ${styles.Button} ${styles[`Button--${variant}`]} ${
        styles[`Button--${size}`]
      } ${styles[`Button--${variant}--${color}`]} ${className} ${
        size === 'micro' ? 'detail' : 'heading4'
      }}`}
      {...props}
      disabled={disabled}
    >
      <div className={Button__IconContainer}>
        {icon && <span className={Button__Icon}>{icon}</span>}
        <span className={styles[`Button__Label--${size} heading4`]}>
          {label}
        </span>
      </div>
    </button>
  );
};

export default Button;
