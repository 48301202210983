import styles from './graph-header.module.scss';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { CalendarContext, ThemeContext } from '@flexo/providers';

/* eslint-disable-next-line */
export interface GraphHeaderProps {
  title: string | null;
  buttons: string[] | null;
  visibleButtons: any;
  colorScale: any;
  toggleData?(arg1: any, arg2: any): void;
  selectedBar?: any;
  unit: string;
}

export function 
GraphHeader({
  title,
  buttons,
  visibleButtons,
  colorScale,
  toggleData,
  selectedBar,
  unit,
}: GraphHeaderProps) {
  const { calendarStore } = useContext(CalendarContext);

  const { currentlySelectedDateType } = calendarStore;
  const { ThemeStore } = useContext(ThemeContext);

  const { t } = useTranslation();

  const dt = DateTime.fromISO(selectedBar?.date);

  const firstTwoButtons = buttons?.slice(0, 2);
  const remainingButtons = buttons?.slice(2);

  const formatDate = (date: string, calendarDisplay: string) => {
    let formattedDate = DateTime.fromISO(date).toFormat('HH');
    switch (calendarDisplay) {
      case 'day':
      case '':
        formattedDate = DateTime.fromISO(date).toFormat('HH:mm');
        break;
      case 'week':
      case 'month':
        formattedDate = DateTime.fromISO(date).toFormat('d/M');
        break;
      // formattedDate = moment(new Date(date)).format("MM");
      // break;
      case 'year':
        formattedDate = DateTime.fromISO(date)
          .setLocale('es')
          .toFormat('MMM yyyy');
        break;
      case 'decade':
        formattedDate = DateTime.fromISO(date).toFormat('MM-YYYY');
        break;
    }

    return (formattedDate.startsWith('0') && formattedDate.length) === 2
      ? formattedDate[1]
      : formattedDate;
  };

  const getStyling = (index) => {
    if (visibleButtons[index] === false) {
      return {
        color: ThemeStore.colors.greyscale,
        borderColor: ThemeStore.colors.greyscale + 50,
      };
    } else {
      return {
        // borderColor: (colorScale.range() as any)[index],
      };
    }
  };

  const getTitleDetail = () => {
    if (!selectedBar) return;
    else if (
      formatDate(selectedBar?.date, 'day') ===
      'Invalid DateTime'
    )
      return;
    else return formatDate(selectedBar?.date, 'day');
  };

  return (
    <div className={styles.GraphHeader}>
      {title && (
        <div className={styles.GraphHeader__TitleContainer}>
          <div className={`${styles.GraphHeader__Title} heading4`}>
            {t(title)}{' '}
          </div>
          <div className="heading4L">{getTitleDetail()}</div>
        </div>
      )}
      {buttons && (
        <div
          style={{
            display: 'flex',
            flexDirection: selectedBar ? 'column' : 'row',
            height: '3em',
          }}
        >
          <div className={styles.GraphHeader__Buttons}>
            {!selectedBar &&
              buttons?.map((button: string, button_i: number) => (
                <button
                  // fill={!_set?.visible ? 'outline' : undefined}
                  key={`button_${button_i}`}
                  color={'transparent'}
                  className={`detail button opacity-${
                    visibleButtons[button_i] ? '100' : '50'
                  } ${styles.GraphHeader__Button}`}
                  style={getStyling(button_i)}
                  onClick={
                    toggleData
                      ? () => toggleData(button, button_i)
                      : () => null //console.log('no toggleData function')
                  }
                >
                  <span>
                    {`${t(button)} `}{' '}
                    <strong
                      className={`${styles.BarChartStyles__BoldButton} detailBold`}
                      style={{ fontSize: '12px' }}
                    >
                      {selectedBar && visibleButtons[button_i] !== false
                        ? '' +
                          (selectedBar[button] == null ||
                          selectedBar[button] === undefined
                            ? ''
                            : '| ' + selectedBar[button] + ` ${unit}`)
                        : ''}
                    </strong>
                  </span>
                </button>
              ))}
          </div>
          <div className={styles.GraphHeader__Buttons}>
            {selectedBar &&
              firstTwoButtons?.map((button: string, button_i: number) => (
                <button
                  // fill={!_set?.visible ? 'outline' : undefined}
                  key={`button_${button_i}`}
                  color={'transparent'}
                  className={`detail button opacity-${
                    visibleButtons[button_i] ? '100' : '50'
                  } ${styles.GraphHeader__Button}`}
                  style={getStyling(button_i)}
                  onClick={
                    toggleData
                      ? () => toggleData(button, button_i)
                      : () => null //'no toggleData function')
                  }
                >
                  <span>
                    {`${t(button)} `}{' '}
                    <strong
                      className={`${styles.BarChartStyles__BoldButton} detailBold`}
                      style={{ fontSize: '12px' }}
                    >
                      {selectedBar && visibleButtons[button_i] !== false
                        ? '' +
                          (selectedBar[button] === null ||
                          selectedBar[button] === undefined
                            ? ''
                            : '| ' + selectedBar[button] + ` ${unit}`)
                        : ''}
                    </strong>
                  </span>
                </button>
              ))}
          </div>

          {selectedBar &&
            remainingButtons &&
            remainingButtons?.map((button: string, button_i: number) => (
              <div
                className={styles.GraphHeader__Buttons}
                style={{ marginTop: '.25em' }}
              >
                <button
                  // fill={!_set?.visible ? 'outline' : undefined}
                  key={`button_${button_i + 2}`}
                  color={'transparent'}
                  className={`detail button opacity-${
                    visibleButtons[button_i + 2] ? '100' : '50'
                  } ${styles.GraphHeader__Button}`}
                  style={getStyling(button_i + 2)}
                  onClick={
                    toggleData
                      ? () => toggleData(button, button_i + 2)
                      : () => null //console.log('no toggleData function')
                  }
                >
                  <span>
                    {`${t(button)} `}{' '}
                    <strong
                      className={`${styles.BarChartStyles__BoldButton} detailBold`}
                      style={{ fontSize: '12px' }}
                    >
                      {selectedBar && visibleButtons[button_i + 2] !== false
                        ? '' +
                          (selectedBar[button] === null ||
                          selectedBar[button] === undefined
                            ? ''
                            : '| ' + selectedBar[button] + ` ${unit}`)
                        : ''}
                    </strong>
                  </span>
                </button>
              </div>
            ))}
        </div>
      )}{' '}
    </div>
  );
}

export default GraphHeader;
