/* eslint-disable no-case-declarations */
import { ModalStore, StorageActions } from './modal-provider.interfaces';
import { storeInit } from './initialState';

export const reducer = (state: ModalStore, action: StorageActions) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        modal: {
          open: true,
          content: action.payload.value
        }
      }
    break;
    case 'CLOSE_MODAL':
      return {
        modal: {
          open: false
        },
      }
    break;
      
    default:
      return state;
  }
};
